import React from 'react';
import { string, number, object, array } from 'prop-types';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import Grow from '@material-ui/core/Grow';
import { cleanCurrencyNumber } from '../../helpers/localeHelpers';
import '../../styles/components/order/order-item.scss';

const OrderItem = ({
  name,
  startDate,
  endDate,
  shippingAddress,
  unitPrice,
  quantity,
  productDiscount,
  itemLineDiscount,
  supplierAttributes = [],
  currency,
}) => {
  const intl = useIntl();

  const formatAddress = ({ latitude, longitude, isValidated, ...elems }) =>
    Object.values(elems)
      .filter(elem => elem)
      .join(', ');

  const discounts = [
    productDiscount > 0 ? (quantity || 1) * productDiscount : 0,
    itemLineDiscount > 0 ? itemLineDiscount : 0,
  ];
  const discount = discounts.reduce((a, b) => a + b, 0);

  const { value: supplierAttributeLocation } = supplierAttributes.find(({ name }) => name === 'location') || {};

  return (
    <div className="order-item-container">
      <div className="order-data-container">
        <h4 className="order-item-name">{name}</h4>
        {startDate && (
          <div className="order-data-item">
            <h4 className="key">
              <FormattedMessage id="orderItem.starts" />:
            </h4>
            <h4 className="value">
              <FormattedDate value={startDate} />
            </h4>
          </div>
        )}
        {endDate && (
          <div className="order-data-item">
            <h4 className="key">
              <FormattedMessage id="orderItem.ends" />:
            </h4>
            <h4 className="value">
              <FormattedDate value={endDate} />
            </h4>
          </div>
        )}
        {
          // Only display common location if no supplierAttributes with name 'location' are sent
        }
        {(shippingAddress || supplierAttributeLocation) && (
          <div className="order-data-item">
            <h4 className="key">
              <FormattedMessage id="orderItem.location" />:
            </h4>
            <h4 className="value">{supplierAttributeLocation || formatAddress(shippingAddress)}</h4>
          </div>
        )}
        {supplierAttributes.map(({ name, value = '', displayName }) => {
          if (!!displayName) {
            return (
              <div key={name} className="order-data-item">
                <h4 className="key">{displayName}:</h4>
                <h4 className="value">{value}</h4>
              </div>
            );
          }
          return null;
        })}
      </div>
      <div className="order-item-pricing-container">
        <h4 className="order-price">
          {quantity > 1 && (
            <>
              {intl.formatNumber(quantity)}
              {' × '}
            </>
          )}
          {`${cleanCurrencyNumber(unitPrice, currency, intl.formatNumberToParts)} ${currency}`}
        </h4>
        {discount > 0 && (
          <Tooltip
            title={intl.formatMessage({ id: 'orderSummary.discount' })}
            placement="left"
            arrow
            TransitionComponent={Grow}
            TransitionProps={{ timeout: 300 }}
          >
            <h4 className="order-price">
              {`(${cleanCurrencyNumber(discount, currency, intl.formatNumberToParts)}) ${currency}`}
            </h4>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default OrderItem;

OrderItem.propTypes = {
  name: string.isRequired,
  startDate: string,
  endDate: string,
  shippingAddress: object,
  unitPrice: number.isRequired,
  quantity: number,
  productDiscount: number,
  itemLineDiscount: number,
  supplierAttributes: array,
  currency: string,
};
