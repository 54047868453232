export default {
  'billing.address': '地址',
  'billing.addressConfirmation': '地址确认',
  'billing.addressConfirmationDescription':
    '根据输入的原始地址找到了经过验证的地址。选择"确认"以接受更新的地址，或选择"取消"自行作出修改。',
  'billing.billing': '账单',
  'billing.billingInformation': '账单信息',
  'billing.cancelAddressConfirmation': '取消',
  'billing.caState': '州',
  'billing.city': '城市',
  'billing.confirmAddress': '确认',
  'billing.confirmEmail': '确认电子邮件地址',
  'billing.country': '国家',
  'billing.edit': '修改',
  'billing.email': '电子邮件',
  'billing.errorObtainingCountries': '获取可用的国家列表时出错',
  'billing.errorValidatingAddress': '帐单地址无效',
  'billing.firstName': '名',
  'billing.knownState': '州',
  'billing.lastName': '姓',
  'billing.loadingCountries': '正在加载国家...',
  'billing.memo': '备注/订单号',
  'billing.organizationName': '组织名称',
  'billing.originalAddress': '原始地址',
  'billing.postalCode': '邮政编码',
  'billing.save': '保存',
  'billing.state': '州/省/地区',
  'billing.taxId': '税号',
  'billing.useBillingInformation': '使用账单信息',
  'billing.usState': '州',
  'billing.validatedAddress': '已验证的地址',
  'billing.VATTaxID': '税号',
  'cardBrand.amex': 'American Express',
  'cardBrand.cartes_bancaires': 'Cartes Bancaires',
  'cardBrand.diners': "Diner's Club",
  'cardBrand.discover': 'Discover',
  'cardBrand.jcb': 'JCB',
  'cardBrand.mastercard': 'Mastercard',
  'cardBrand.unionpay': 'UnionPay',
  'cardBrand.visa': 'Visa',
  'field.invalidEmail': '请输入一个有效的电子邮件地址',
  'field.requiredField': '此栏必填',
  'field.unmatchingEmails': '电子邮件地址不匹配',
  'global.AWS_Cobranding': 'Xvoucher 是该 Amazon Web Services 培训计划的授权经销商。',
  'global.backToAWS': '返回到 AWS',
  'global.backToCart': '返回',
  'global.change': '更改',
  'global.contactSupport': '联络客服',
  'global.defaultSelectOption': '选择一个选项',
  'global.expiredSession': '会话已过期',
  'global.expiringSession': '您的会话即将过期',
  'global.missingPaymentTransaction': '找不到付款交易',
  'global.more': '更多',
  'global.allRightsReserved': '保留所有权利',
  'global.pageNotFound': '找不到您要查找的页面',
  'global.poweredBy': '基于',
  'global.noRemainingAttempts' : '多次尝试付款失败。 您将被重定向回来。',
  'global.rightsReserved': '保留所有权利',
  'global.support': '支持',
  'global.terms': '条款',
  'global.unauthorizedAccess': '您无权访问此页面',
  'invoice.email': '电子邮件',
  'invoice.errorOnObtainingInvoice': '显示发票时出错',
  'invoice.errorSendingEmail': '发送发票时出错',
  'invoice.invoiceSent': '发票已发送',
  'invoice.mailPlaceholder': 'e.g. "john@doe.com; jane@doe.com',
  'invoice.noOrder': '没有可显示的订单',
  'invoice.print': '打印',
  'invoice.send': '发送',
  'invoice.sessionExpired': '会话已过期',
  'invoice.wrongMailFormat': '最多可以使用 10 个用分号分隔的有效电邮地址，例如 “john@doe.com; jane@doe.com”',
  'order.errorGettingOrder': '尝试获取订单时发生错误',
  'order.errorUpdatingOrder': '尝试更新订单时发生错误',
  'order.invalidToken': '交易无效',
  'order.locationNotApproved': '抱歉，您所在的地区无法购买该课程',
  'order.moreDetailsAvailable': '详细信息',
  'order.noOrder': '没有可供显示的订单明细',
  'order.noToken': '未找到交易信息',
  'order.subtotal': '小计',
  'order.title': '订单明细',
  'orderItem.ends': '结束',
  'orderItem.itemLineDiscount': '项目行折扣',
  'orderItem.location': '地点',
  'orderItem.productDiscount': '产品折扣',
  'orderItem.starts': '开始',
  'orderSummary.cancel': '取消',
  'orderSummary.cardTypeSelectionLabel': '选择信用卡类型',
  'orderSummary.chargedAmountLabel': '您的卡将会被收取费用',
  'orderSummary.conversionReason': '我为什么需要使用美元支付',
  'orderSummary.currencyChangeExplanation': '用{0}货币支付，请使用VISA或万事达（MasterCard）卡',
  'orderSummary.discount': '折扣',
  'orderSummary.estimatedTax': '税',
  'orderSummary.fee': '费用',
  'orderSummary.noOrder': '没有可供显示的订单摘要',
  'orderSummary.orderDiscount': '订单折扣',
  'orderSummary.pay': '支付',
  'orderSummary.productDiscount': '产品折扣',
  'orderSummary.productSubTotal': '产品小计',
  'orderSummary.productTotal': '产品总计',
  'orderSummary.title': '订单摘要',
  'orderSummary.total': '总计',
  'orderSummary.totalBeforeTax': '税前总计',
  'payment.cancelledPayment': '付款已取消',
  'payment.confirmingTransaction': '正在确认交易...',
  'payment.convergeFailure': '尝试启动付款时出现问题',
  'payment.errorObtainingRedirectURL': '尝试获取重定向 URL 时发生错误',
  'payment.errorProcessingTransaction': '处理交易时出错',
  'payment.fraudSuspectionError':
    '为了您的安全，所尝试的付款不可提交，而且详细信息已转发给我们的安全团队。 如果您认为不该收到此消息，请联系<a href="{0}">Xvoucher 支持部门</a>。',
  'payment.incompatibleCardForCurrency': '若使用其他货币（非美元）支付，则必须使用VISA或万事达（MasterCard）卡',
  'payment.initFailure': '尝试启动付款时出现问题',
  'payment.postalCodeRequired': '此支付需要输入邮政编码',
  'payment.transactionApproved': '信用卡交易已获批准',
  'payment.transactionCancelled': '信用卡交易被取消',
  'payment.transactionDeclined': '交易被拒绝',
  'payment.transactionDeclinedCardDetails': '您的信用卡的详细信息不正确',
  'payment.transactionError': '信用卡交易发生错误',
  'payment.transactionProcessing': '信用卡交易正在处理中',
  'payment.transactionRequiresAction': '信用卡交易要求采取行动',
  'payment.transactionRequiresCapture': '信用卡交易要求捕获',
  'payment.transactionRequiresConfirmation': '信用卡交易要求确认',
  'payment.transactionRequiresPaymentMethod': '信用卡交易要求付款方式',
  'payment.unsupportedCardOfferCurrencyConversion': '{0}卡不可用于{1}付款。请使用不同品牌的信用卡，或者用{2}支付。',
  'pendo.initializationError': '尝试初始化 Pendo 时发生错误：{0}',
  'pendo.updateError': '尝试更新 Pendo 时发生错误：{0}',
  'test.insertToken': '在此处插入测试令牌',
  'country.US': '美国',
  'country.CA': '加拿大',
  'country.AF': '阿富汗',
  'country.AL': '阿尔巴尼亚',
  'country.DZ': '阿爾及利亞',
  'country.AO': '安哥拉',
  'country.AR': '阿根廷',
  'country.AT': '奥地利',
  'country.AU': '澳大利亚',
  'country.BE': '比利时',
  'country.BH': '巴林',
  'country.BR': '巴西',
  'country.BG': '保加利亚',
  'country.CM': '喀麦隆',
  'country.CV': '佛得角',
  'country.CL': '智利',
  'country.CN': '中国',
  'country.CO': '哥伦比亚',
  'country.CZ': '捷克语',
  'country.DK': '丹麦',
  'country.EG': '埃及',
  'country.ET': '埃塞俄比亚',
  'country.EE': '爱沙尼亚',
  'country.FI': '芬兰',
  'country.FR': '法国',
  'country.GA': '加蓬',
  'country.GM': '冈比亚',
  'country.GH': '加纳',
  'country.DE': '德国',
  'country.GR': '希腊',
  'country.HK': '香港',
  'country.HU': '匈牙利',
  'country.IN': '印度',
  'country.ID': '印度尼西亚',
  'country.IQ': '伊拉克',
  'country.IE': '爱尔兰',
  'country.IL': '以色列',
  'country.IT': '意大利',
  'country.JP': '日本',
  'country.JO': '约旦',
  'country.KE': '肯尼亚',
  'country.KP': '韩国（民主人民共和国）',
  'country.KW': '科威特',
  'country.LV': '拉脱维亚',
  'country.LB': '黎巴嫩',
  'country.LR': '莱索托',
  'country.LT': '立陶宛',
  'country.LU': '卢森堡',
  'country.MK': '北马其顿',
  'country.MG': '马达加斯加',
  'country.MY': '马来西亚',
  'country.MA': '摩洛哥',
  'country.MX': '墨西哥',
  'country.NL': '荷兰',
  'country.NG': '奈及利亚',
  'country.NO': '挪威',
  'country.OM': '阿曼',
  'country.PK': '巴基斯坦',
  'country.PE': '秘鲁',
  'country.PH': '菲律宾',
  'country.PL': '波兰',
  'country.PT': '葡萄牙',
  'country.PR': '波多黎各',
  'country.QA': '卡塔尔',
  'country.RO': '罗马尼亚',
  'country.RW': '卢旺达',
  'country.SA': '沙特阿拉伯',
  'country.SN': '塞内加尔',
  'country.RS': '塞尔维亚',
  'country.SL': '塞拉利昂',
  'country.SG': '新加坡',
  'country.SI': '斯洛文尼亚',
  'country.ZA': '南非',
  'country.ES': '西班牙',
  'country.SE': '瑞典',
  'country.TH': '泰国',
  'country.TN': '突尼斯',
  'country.TR': '火鸡',
  'country.UG': '乌干达',
  'country.UA': '乌克兰',
  'country.AE': '阿拉伯联合酋长国',
  'country.GB': '英国',
  'country.VE': '委内瑞拉',
  'country.YE': '也门',
  'country.ZM': '赞比亚',
  'country.AM': '亚美尼亚',
  'country.CR': '哥斯达黎加',
  'country.DO': '多明尼加共和国',
  'country.LK': '斯里兰卡',
  'country.CH': '瑞士',
  'country.JM': '牙买加',
  'country.KZ': '哈萨克斯坦',
  'country.NZ': '新西兰',
  'country.NP': '尼泊尔',
  'country.ZW': '津巴布韦',
  'country.EC': '厄瓜多尔',
  'country.GT': '危地马拉',
  'country.BD': '孟加拉国',
  'country.UY': '乌拉圭',
  'country.MN': '蒙古',
  'country.KH': '柬埔寨',
  'country.ME': '黑山共和国',
  'country.BW': '博茨瓦纳',
  'country.KG': '吉尔吉斯斯坦',
  'country.TJ': '塔吉克斯坦',
  'country.UZ': '乌兹别克斯坦',
  'country.AZ': '阿塞拜疆',
  'country.TM': '土库曼斯坦',
  'country.TT': '特立尼达和多巴哥',
  'country.GN': '几内亚',
  'country.AS': '美属萨摩亚',
  'country.AD': '安道尔',
  'country.AQ': '南极洲',
  'country.AG': '安提瓜和巴布达',
  'country.AW': '阿鲁巴岛',
  'country.BS': '巴哈马',
  'country.BB': '巴巴多斯',
  'country.BM': '百慕大',
  'country.BY': '白俄罗斯',
  'country.BZ': '伯利兹',
  'country.BJ': '贝尼姆',
  'country.BT': '不丹',
  'country.BO': '玻利维亚',
  'country.BA': '波斯尼亚和黑塞哥维那',
  'country.BV': '布维岛',
  'country.GU': '关岛',
  'country.GY': '圭亚那',
  'country.HT': '海地',
  'country.HM': '希尔德岛和麦当劳群岛',
  'country.HN': '洪都拉斯',
  'country.IS': '冰岛',
  'country.KI': '基里巴斯',
  'country.KR': '南韩',
  'country.LA': '老挝',
  'country.WS': '萨摩亚',
  'country.SM': '圣马力诺',
  'country.ST': '圣多美和普林西比',
  'country.SC': '塞舌尔',
  'country.SK': '斯洛伐克',
  'country.SB': '所罗门群岛',
  'country.SO': '索马里',
  'country.GS': '南乔治亚岛和南桑威奇群岛',
  'country.SH': '圣海伦娜',
  'country.PM': '圣皮埃尔和密克隆群岛',
  'country.SR': '苏里南',
  'country.SJ': '斯瓦尔巴群岛和扬·马延',
  'country.IO': '英属印度洋领地',
  'country.BN': '文莱达鲁萨兰国',
  'country.BF': '布基纳法索',
  'country.BI': '布隆迪',
  'country.KY': '开曼群岛',
  'country.CF': '中非共和国',
  'country.TD': '乍得',
  'country.CX': '圣诞岛',
  'country.CC': '科科斯群岛',
  'country.KM': '科摩罗',
  'country.CG': '刚果',
  'country.CK': '库克群岛',
  'country.CI': '科特迪瓦',
  'country.HR': '克罗地亚',
  'country.CY': '塞浦路斯',
  'country.DJ': '吉布地',
  'country.LS': '莱索托',
  'country.LY': '利比亚',
  'country.LI': '列支敦士登',
  'country.MO': '澳门',
  'country.MW': '马拉维',
  'country.MV': '马尔代夫',
  'country.ML': '马里',
  'country.MT': '马耳他',
  'country.MH': '马绍尔群岛',
  'country.MQ': '马提尼克岛',
  'country.MR': '毛里塔尼亚',
  'country.MU': '毛里求斯',
  'country.YT': '马约特岛',
  'country.FM': '密克罗尼西亚',
  'country.MD': '摩尔多瓦',
  'country.MC': '摩纳哥',
  'country.MS': '蒙特塞拉特',
  'country.MZ': '莫桑比克',
  'country.MM': '缅甸',
  'country.NA': '纳米比亚',
  'country.NR': '瑙鲁',
  'country.SZ': '埃斯瓦蒂尼',
  'country.TW': '台湾',
  'country.TZ': '坦桑尼亚联合共和国',
  'country.TG': '多哥',
  'country.TK': '托克劳',
  'country.TO': '汤加',
  'country.TC': '特克斯和凯科斯群岛',
  'country.TV': '图瓦卢',
  'country.DM': '多米尼加',
  'country.TL': '东帝汶',
  'country.SV': '萨尔瓦多',
  'country.GQ': '赤道几内亚',
  'country.ER': '厄立特里亚',
  'country.FK': '福克兰群岛',
  'country.FO': '法罗群岛',
  'country.FJ': '斐济',
  'country.GF': '法属圭亚那',
  'country.PF': '法属波利尼西亚',
  'country.TF': '法属南部领地',
  'country.GE': '佐治亚州',
  'country.GD': '格林纳达',
  'country.GL': '格陵兰',
  'country.GP': '瓜德罗普岛',
  'country.NC': '新喀里多尼亚',
  'country.NI': '尼加拉瓜',
  'country.NE': '尼日尔',
  'country.NU': '纽埃',
  'country.NF': '诺福克岛',
  'country.MP': '北马里亚纳群岛',
  'country.PW': '帕劳',
  'country.PA': '巴拿马',
  'country.PG': '巴布亚新几内亚',
  'country.PY': '巴拉圭',
  'country.PN': '皮特凯恩',
  'country.RE': '团圆',
  'country.RU': '俄罗斯联邦',
  'country.KN': '圣基茨和尼维斯',
  'country.LC': '圣卢西亚',
  'country.VC': '圣文森特和格林纳丁斯',
  'country.UM': '美国本土外小岛屿',
  'country.VU': '瓦努阿图',
  'country.VA': '教廷',
  'country.VN': '越南',
  'country.VG': '维尔京群岛（英国）',
  'country.VI': '维尔京群岛（美国）',
  'country.WF': '瓦利斯和富图纳群岛',
  'country.EH': '西撒哈拉',
  'country.GW': '几内亚比绍',
  'country.GI': '直布罗陀',
  'country.SS': '南苏丹',
  'country.CW': '库拉索',
  'country.AX': '奥兰群岛',
  'country.AI': '安圭拉岛',
  'country.CD': '刚果民主共和国',
  'country.CU': '古巴',
  'country.GG': '根西岛',
  'country.IR': '伊朗',
  'country.IM': '马恩岛',
  'country.JE': '泽西岛',
  'country.PS': '巴勒斯坦',
  'country.MF': '圣马丁（法语部分)',
  'country.SX': '圣马丁（荷兰语部分）',
  'country.SD': '苏丹',
  'country.SY': '阿拉伯叙利亚共和国',
  'country.BQ': '博奈尔岛，圣尤斯特歇斯和萨巴',
  'country.BL': '圣巴托洛缪岛',
  'state.AL': '阿拉巴马州',
  'state.AK': '阿拉斯加州',
  'state.AZ': '亚利桑那',
  'state.AR': '阿肯色州',
  'state.CA': '加利福尼亚州',
  'state.CO': '科罗拉多州',
  'state.CT': '康乃狄克州',
  'state.DE': '特拉华州',
  'state.FL': '佛罗里达',
  'state.GA': '佐治亚州',
  'state.HI': '夏威夷',
  'state.ID': '爱达荷州',
  'state.IL': '伊利诺伊州',
  'state.IN': '印第安那州',
  'state.IA': '爱荷华州',
  'state.KS': '堪萨斯州',
  'state.KY': '肯塔基州',
  'state.LA': '路易斯安那州',
  'state.ME': '缅因州',
  'state.MD': '马里兰州',
  'state.MA': '马萨诸塞州',
  'state.MI': '密西根州',
  'state.MN': '明尼苏达州',
  'state.MS': '密西西比州',
  'state.MO': '密苏里州',
  'state.MT': '蒙大拿',
  'state.NE': '内布拉斯加州',
  'state.NV': '内华达州',
  'state.NH': '新罕布什尔',
  'state.NJ': '新泽西州',
  'state.NM': '新墨西哥',
  'state.NY': '纽约',
  'state.NC': '北卡罗来纳',
  'state.ND': '北达科他州',
  'state.OH': '俄亥俄',
  'state.OK': '俄克拉荷马州',
  'state.OR': '俄勒冈州',
  'state.PA': '宾夕法尼亚州',
  'state.RI': '罗德岛',
  'state.SC': '南卡罗来纳',
  'state.SD': '南达科他州',
  'state.TN': '田纳西州',
  'state.TX': '德州',
  'state.UT': '犹他州',
  'state.VT': '佛蒙特',
  'state.VA': '维吉尼亚州',
  'state.WA': '华盛顿州',
  'state.WV': '西弗吉尼亚',
  'state.WI': '威斯康星州',
  'state.WY': '怀俄明州',
  'state.DC': '哥伦比亚特区',
  'state.AS': '美属萨摩亚',
  'state.FM': '密克罗尼西亚联邦',
  'state.GU': '关岛',
  'state.MH': '马绍尔群岛',
  'state.MP': '北马里亚纳群岛',
  'state.PW': 'u琉',
  'state.PR': '波多黎各',
  'state.VI': '维尔京群岛',
  'state.AE': '欧洲，中东和加拿大武装部队',
  'state.AP': '太平洋武装部队',
  'state.AA': '美洲武装部队（加拿大除外）',
  'province.AB': '艾伯塔省',
  'province.BC': '不列颠哥伦比亚省',
  'province.MB': '曼尼托巴省',
  'province.NB': '新不伦瑞克省',
  'province.NL': '纽芬兰与拉布拉多',
  'province.NT': '西北地区',
  'province.NS': '新斯科舍省',
  'province.NU': '努纳武特',
  'province.ON': '安大略省',
  'province.PE': '爱德华王子岛',
  'province.QC': '魁北克',
  'province.SK': '萨斯喀彻温省',
  'province.YT': '育空地区',
  'state.AbuDhabi': 'Abu Dhabi',
  'state.Ajman': 'Ajman',
  'state.Dubai': 'Dubai',
  'state.Fujairah': 'Fujairah',
  'state.RasAlKhaimah': 'Ras Al Khaimah',
  'state.Sharjah': 'Sharjah',
  'state.UmmAlQuwain': 'Umm Al Quwain',
  'billing.aeState': '酋长国',
  'instate.AN': '安达曼和尼科巴群岛',
  'instate.AP': '安德拉邦',
  'instate.AR': '阿鲁纳恰尔邦',
  'instate.AS': '阿萨姆邦',
  'instate.BR': '比哈尔邦',
  'instate.CH': '昌迪加尔',
  'instate.CT': '恰蒂斯加尔邦',
  'instate.DN': '达德拉和纳加哈维利',
  'instate.DD': '达曼和丢',
  'instate.DL': '德里',
  'instate.GA': '果阿',
  'instate.GJ': '古吉拉特邦',
  'instate.HR': '哈里亚纳邦',
  'instate.HP': '喜马偕尔邦',
  'instate.JK': '查谟和克什米尔',
  'instate.JH': '贾坎德邦',
  'instate.KA': '卡纳塔克邦',
  'instate.KL': '喀拉拉邦',
  'instate.LD': '拉克沙群岛',
  'instate.MP': '中央邦',
  'instate.MH': '马哈拉施特拉邦',
  'instate.MN': '曼尼普尔邦',
  'instate.ML': '梅加拉亚邦',
  'instate.MZ': '米佐拉姆',
  'instate.NL': '那加兰邦',
  'instate.OR': '奥里萨邦',
  'instate.PY': '本地治里',
  'instate.PB': '旁遮普语',
  'instate.RJ': '拉贾斯坦邦',
  'instate.SK': '锡金',
  'instate.TN': '泰米尔纳德邦',
  'instate.TG': '泰伦伽纳语',
  'instate.TR': '特里普拉',
  'instate.UP': '北方邦',
  'instate.UT': '北阿坎德邦',
  'instate.WB': '西孟加拉邦',
  'billing.inState': '州',
};
