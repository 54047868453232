import React from 'react';
import { connect } from 'react-redux';
import { string } from 'prop-types';
import { useIntl } from 'react-intl';
import FormattedHTMLMessage from '../components/common/FormattedHTMLMessage';
import ModalWrapper from '../components/common/ModalWrapper';
import AnimationLine from '../components/AnimationLine';

import '../styles/pages/main-page.scss';

const NotFoundPage = ({ error }) => {
  const intl = useIntl();

  return (
    <div className="xv-pay-container">
      <div className="animation-line">
        <AnimationLine />
      </div>
      <div className="main-page-modal-container">
        <ModalWrapper>
          <h2>
            <FormattedHTMLMessage message={error || intl.formatMessage({ id: 'global.pageNotFound' })} />
          </h2>
        </ModalWrapper>
      </div>
    </div>
  );
};

NotFoundPage.propTypes = {
  error: string,
};

const mapState = ({ order }) => ({
  error: order.globalError,
});

export default connect(mapState)(NotFoundPage);
