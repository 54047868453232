export default {
  'billing.address': '주소',
  'billing.addressConfirmation': '주소 확인',
  'billing.addressConfirmationDescription':
    '처음 입력되었던 주소에 근거하여 검증된 주소가 검색되었습니다. 업데이트된 주소에 동의하려면 확인을, 직접 수정하려면 취소를 선택하시기 바랍니다.',
  'billing.billing': '청구',
  'billing.billingInformation': '청구서 정보',
  'billing.cancelAddressConfirmation': '취소',
  'billing.caState': '주',
  'billing.city': '도시',
  'billing.confirmAddress': '확인',
  'billing.confirmEmail': '이메일 확인',
  'billing.country': '국가',
  'billing.edit': '편집',
  'billing.email': '이메일',
  'billing.errorObtainingCountries': '사용 가능한 국가 명단을 얻어 오는 중 오류가 있었습니다',
  'billing.errorValidatingAddress': '잘못된 청구서 수신 주소',
  'billing.firstName': '이름',
  'billing.knownState': '주',
  'billing.lastName': '성',
  'billing.loadingCountries': '국가를 불러 오는 중입니다...',
  'billing.memo': '메모/PO 번호',
  'billing.organizationName': '조직 이름',
  'billing.originalAddress': '처음 입력되었던 주소',
  'billing.postalCode': '우편 번호',
  'billing.save': '저장',
  'billing.state': '주 또는 지방 (State/Province/Region)',
  'billing.taxId': '세금 ID',
  'billing.useBillingInformation': '청구서 정보 사용하기',
  'billing.usState': '주',
  'billing.validatedAddress': '검증된 주소',
  'billing.VATTaxID': '세금 ID',
  'cardBrand.amex': 'American Express',
  'cardBrand.cartes_bancaires': 'Cartes Bancaires',
  'cardBrand.diners': "Diner's Club",
  'cardBrand.discover': 'Discover',
  'cardBrand.jcb': 'JCB',
  'cardBrand.mastercard': 'Mastercard',
  'cardBrand.unionpay': 'UnionPay',
  'cardBrand.visa': 'Visa',
  'field.invalidEmail': '유효한 이메일 주소를 입력하세요',
  'field.requiredField': '필수 입력란입니다',
  'field.unmatchingEmails': '이메일 주소가 일치하지 않습니다',
  'global.AWS_Cobranding': 'Xvoucher는 이 Amazon Web Services 교육 프로그램의 공인 재판매인입니다.',
  'global.backToAWS': 'AWS로 돌아가기',
  'global.backToCart': '뒤로',
  'global.change': '변경',
  'global.contactSupport': '고객 지원 부서에 문의',
  'global.defaultSelectOption': '항목 선택',
  'global.expiredSession': '세션이 만료되었습니다',
  'global.expiringSession': '사용 중인 세션이 곧 만료됩니다',
  'global.missingPaymentTransaction': '지불 거래가 검색되지 않음',
  'global.more': '더 보기',
  'global.allRightsReserved': '판권 소유',
  'global.pageNotFound': '찾는 페이지가 검색되지 않았습니다',
  'global.poweredBy': '제공',
  'global.noRemainingAttempts' : '결제 시도가 여러 번 실패했습니다. 다시 리디렉션됩니다.',
  'global.rightsReserved': '판권 소유',
  'global.support': '지원',
  'global.terms': '조건',
  'global.unauthorizedAccess': '본 페이지 접속 권한이 없습니다',
  'invoice.email': '이메일',
  'invoice.errorOnObtainingInvoice': '청구서 표시 중 오류가 있었습니다',
  'invoice.errorSendingEmail': '청구서 전송 중 오류가 있었습니다',
  'invoice.invoiceSent': '청구서가 발송됨',
  'invoice.mailPlaceholder': 'e.g. "john@doe.com; jane@doe.com',
  'invoice.noOrder': '표시될 주문이 없음',
  'invoice.print': '인쇄',
  'invoice.send': '전송',
  'invoice.sessionExpired': '세션이 만료되었습니다',
  'invoice.wrongMailFormat':
    '메일 주소는 세미콜론으로 열 개까지 분리하여 사용할 수 있습니다. 예: "john@doe.com; jane@doe.com"',
  'order.errorGettingOrder': '주문을 가져 오는 중 오류가 발생했습니다',
  'order.errorUpdatingOrder': '주문 업데이트 중 오류가 발생했습니다',
  'order.invalidToken': '거래가 유효하지 않습니다',
  'order.locationNotApproved': '죄송합니다.이 수업은 귀하의 지역에서 구매할 수 없습니다.',
  'order.moreDetailsAvailable': '추가 세부 사항',
  'order.noOrder': '표시할 주문 세부 사항이 없습니다',
  'order.noToken': '검색된 거래 정보가 없습니다',
  'order.subtotal': '소계',
  'order.title': '주문 세부 정보',
  'orderItem.ends': '종료',
  'orderItem.itemLineDiscount': '품복별 할인',
  'orderItem.location': '위치',
  'orderItem.productDiscount': '제품 할인',
  'orderItem.starts': '시작',
  'orderSummary.cancel': '취소',
  'orderSummary.cardTypeSelectionLabel': '신용 카드 유형 선택',
  'orderSummary.chargedAmountLabel': '카드가 청구됩니다',
  'orderSummary.conversionReason': 'USD로 결제해야하는 이유',
  'orderSummary.currencyChangeExplanation': '{0}로 결제하려면 Visa 또는 MasterCard를 선택하세요.',
  'orderSummary.discount': '할인',
  'orderSummary.estimatedTax': '세금',
  'orderSummary.fee': '수수료',
  'orderSummary.noOrder': '표시할 주문 요약 내용이 없습니다',
  'orderSummary.orderDiscount': '주문 할인',
  'orderSummary.pay': '지불',
  'orderSummary.productDiscount': '제품 할인',
  'orderSummary.productSubTotal': '제품 소계',
  'orderSummary.productTotal': '제품 합계',
  'orderSummary.title': '주문 요약',
  'orderSummary.total': '합계',
  'orderSummary.totalBeforeTax': '세전 합계',
  'payment.cancelledPayment': '지불이 취소되었습니다',
  'payment.confirmingTransaction': '거래 확인 중...',
  'payment.convergeFailure': '지불을 초기화하는 과정 중 문제가 있었습니다',
  'payment.errorObtainingRedirectURL': '자동으로 이동할 변경된 URL을 얻어 오는 중 오류가 발생했습니다',
  'payment.errorProcessingTransaction': '거래 처리 중 오류가 있었습니다',
  'payment.fraudSuspectionError':
    '고객님의 보안을 위해 시도하신 지불 건은 제출 불가하며 상세 사항은 저희 보안 부서로 전송되었습니다. 오류로 인해 본 메시지가 수신되었다고 생각되시면 <a href="{0}">Xvoucher support</a>로 연락 주시기 바랍니다.',
  'payment.incompatibleCardForCurrency': 'USD 이외의 통화로 결제하려면 Visa 또는 MasterCard를 사용해야합니다.',
  'payment.initFailure': '지불을 초기화하는 과정 중 문제가 있었습니다',
  'payment.postalCodeRequired': '이 거래에는 우편 번호가 필요합니다.',
  'payment.transactionApproved': '결제가 승인되었습니다',
  'payment.transactionCancelled': '지불이 취소되었습니다',
  'payment.transactionDeclined': '거래가 거부되었습니다',
  'payment.transactionDeclinedCardDetails': '카드 정보가 잘못되었습니다',
  'payment.transactionError': '거래를 수행하는 동안 오류가 발생했습니다',
  'payment.transactionProcessing': '신용 카드 거래 처리 중',
  'payment.transactionRequiresAction': '신용 카드 거래에 조치가 필요합니다',
  'payment.transactionRequiresCapture': '신용 카드 거래에 캡처가 필요합니다',
  'payment.transactionRequiresConfirmation': '신용 카드 거래는 확인이 필요합니다',
  'payment.transactionRequiresPaymentMethod': '신용 카드 거래에는 결제 수단이 필요합니다',
  'payment.unsupportedCardOfferCurrencyConversion':
    '{0} 카드는 {1}의 결제를 지원하지 않습니다. 다른 카드 브랜드를 사용하거나 {2}으로 결제하세요.',
  'pendo.initializationError': 'Pendo 초기화 시도 중 오류 발생: {0}',
  'pendo.updateError': 'Pendo 업데이트 시도 중 오류 발생: {0}',
  'test.insertToken': '여기에 테스트 토큰을 넣으세요',
  'country.US': '미국',
  'country.CA': '캐나다',
  'country.AF': '아프가니스탄',
  'country.AL': '알바니아',
  'country.DZ': '알제리',
  'country.AO': '앙골라',
  'country.AR': '아르헨티나',
  'country.AT': '오스트리아',
  'country.AU': '호주',
  'country.BE': '벨기에',
  'country.BH': '바레인',
  'country.BR': '브라질',
  'country.BG': '불가리아',
  'country.CM': '카메룬',
  'country.CV': '카보 베르데',
  'country.CL': '칠레',
  'country.CN': '중국',
  'country.CO': '콜롬비아',
  'country.CZ': '체코',
  'country.DK': '덴마크',
  'country.EG': '이집트',
  'country.ET': '에티오피아',
  'country.EE': '에스토니아',
  'country.FI': '핀란드',
  'country.FR': '프랑스',
  'country.GA': '가봉',
  'country.GM': '감비아',
  'country.GH': '가나',
  'country.DE': '독일',
  'country.GR': '그리스',
  'country.HK': '홍콩',
  'country.HU': '헝가리',
  'country.IN': '인도',
  'country.ID': '인도네시아',
  'country.IQ': '이라크',
  'country.IE': '아일랜드',
  'country.IL': '이스라엘',
  'country.IT': '이탈리아',
  'country.JP': '일본',
  'country.JO': '요르단',
  'country.KE': '케냐',
  'country.KP': '한국 (민주주의 공화국)',
  'country.KW': '쿠웨이트',
  'country.LV': '라트비아',
  'country.LB': '레바논',
  'country.LR': '라이베리아',
  'country.LT': '리투아니아',
  'country.LU': '룩셈부르크',
  'country.MK': '북 마케도니아',
  'country.MG': '마다가스카르',
  'country.MY': '말레이시아',
  'country.MA': '모로코',
  'country.MX': '멕시코',
  'country.NL': '네덜란드',
  'country.NG': '나이지리아',
  'country.NO': '노르웨이',
  'country.OM': '오만',
  'country.PK': '파키스탄',
  'country.PE': '페루',
  'country.PH': '필리핀 제도',
  'country.PL': '폴란드',
  'country.PT': '포르투갈',
  'country.PR': '푸에르토 리코',
  'country.QA': '카타르',
  'country.RO': '루마니아',
  'country.RW': '르완다',
  'country.SA': '사우디 아라비아',
  'country.SN': '세네갈',
  'country.RS': '세르비아',
  'country.SL': '시에라 리온',
  'country.SG': '싱가포르',
  'country.SI': '슬로베니아',
  'country.ZA': '남아프리카',
  'country.ES': '스페인',
  'country.SE': '스웨덴',
  'country.TH': '태국',
  'country.TN': '튀니지',
  'country.TR': '터키',
  'country.UG': '우간다',
  'country.UA': '우크라이나',
  'country.AE': '아랍 에미리트',
  'country.GB': '영국',
  'country.VE': '베네수엘라',
  'country.YE': '예멘',
  'country.ZM': '잠비아',
  'country.AM': '아르메니아',
  'country.CR': '코스타리카',
  'country.DO': '도미니카 공화국',
  'country.LK': '스리랑카',
  'country.CH': '스위스',
  'country.JM': '자메이카',
  'country.KZ': '카자흐스탄',
  'country.NZ': '뉴질랜드',
  'country.NP': '네팔',
  'country.ZW': '짐바브웨',
  'country.EC': '에콰도르',
  'country.GT': '과테말라',
  'country.BD': '방글라데시',
  'country.UY': '우루과이',
  'country.MN': '몽골리아',
  'country.KH': '캄보디아',
  'country.ME': '몬테네그로',
  'country.BW': '보츠와나',
  'country.KG': '키르기스스탄',
  'country.TJ': '타지키스탄',
  'country.UZ': '우즈베키스탄',
  'country.AZ': '아제르바이잔',
  'country.TM': '투르크 메니스탄',
  'country.TT': '트리니다드 토바고',
  'country.GN': '기니',
  'country.AS': '아메리칸 사모아',
  'country.AD': '안도라',
  'country.AQ': '남극',
  'country.AG': '앤티가 바부 다',
  'country.AW': '아루바',
  'country.BS': '바하마',
  'country.BB': '바베이도스',
  'country.BM': '버뮤다',
  'country.BY': '벨라루스',
  'country.BZ': '벨리즈',
  'country.BJ': '베님',
  'country.BT': '부탄',
  'country.BO': '볼리비아',
  'country.BA': '보스니아 헤르 체고 니아',
  'country.BV': '부베 섬',
  'country.GU': '괌',
  'country.GY': '가이아나',
  'country.HT': '아이티',
  'country.HM': '허드 섬 및 맥도날드 제도',
  'country.HN': '온두라스',
  'country.IS': '아이슬란드',
  'country.KI': '키리바시',
  'country.KR': '대한민국',
  'country.LA': '라오스',
  'country.WS': '사모아',
  'country.SM': '산 마리노',
  'country.ST': '상투 메 프린시 페',
  'country.SC': '세이셸',
  'country.SK': '슬로바키아',
  'country.SB': '솔로몬 제도',
  'country.SO': '소말리아',
  'country.GS': '사우스 조지아 및 사우스 샌드위치 제도',
  'country.SH': '세인트 헬레나',
  'country.PM': '생 피에르 미 클롱',
  'country.SR': '수리남',
  'country.SJ': '스발 바르 얀 마옌',
  'country.IO': '영국령 인도양 지역',
  'country.BN': '브루나이 다루 살람',
  'country.BF': '부키 나 파소',
  'country.BI': '부룬디',
  'country.KY': '케이맨 제도',
  'country.CF': '중앙 아프리카 공화국',
  'country.TD': '차드',
  'country.CX': '크리스마스 섬',
  'country.CC': '코코스 제도',
  'country.KM': '코모로',
  'country.CG': '콩고',
  'country.CK': '쿡 제도',
  'country.CI': '코트 디부 아르',
  'country.HR': '크로아티아',
  'country.CY': '키프로스',
  'country.DJ': '지부티',
  'country.LS': '레소토',
  'country.LY': '리비아',
  'country.LI': '리히텐슈타인',
  'country.MO': '마카오',
  'country.MW': '말라위',
  'country.MV': '몰디브',
  'country.ML': '말리',
  'country.MT': '몰타',
  'country.MH': '마샬 군도',
  'country.MQ': '마르티니크',
  'country.MR': '모리타니',
  'country.MU': '모리셔스',
  'country.YT': '마요트',
  'country.FM': '미크로네시아',
  'country.MD': '몰도바',
  'country.MC': '모나코',
  'country.MS': '몬세 라트',
  'country.MZ': '모잠비크',
  'country.MM': '미얀마',
  'country.NA': '나미비아',
  'country.NR': '나우루',
  'country.SZ': '스와질란드',
  'country.TW': '대만',
  'country.TZ': '탄자니아',
  'country.TG': '토고',
  'country.TK': '토켈 라우',
  'country.TO': '통가',
  'country.TC': '터크 스케이 커스 제도',
  'country.TV': '투발루',
  'country.DM': '도미니카',
  'country.TL': '동 티모르',
  'country.SV': '엘살바도르',
  'country.GQ': '적도 기니',
  'country.ER': '에리트레아',
  'country.FK': '포클랜드 제도',
  'country.FO': '페로 제도',
  'country.FJ': '피지 제도',
  'country.GF': '프랑스 령 기아나',
  'country.PF': '프랑스 령 폴리네시아의',
  'country.TF': '프랑스 령 남부 지역',
  'country.GE': '그루지야',
  'country.GD': '그레나다',
  'country.GL': '그린란드',
  'country.GP': '과들루프',
  'country.NC': '뉴 칼레도니아',
  'country.NI': '니카라과',
  'country.NE': '니제르',
  'country.NU': '니우에',
  'country.NF': '노 포크 섬',
  'country.MP': '북 마리아나 제도',
  'country.PW': '팔라우',
  'country.PA': '파나마',
  'country.PG': '파푸아 뉴기니',
  'country.PY': '파라과이',
  'country.PN': '핏 케언',
  'country.RE': '재결합',
  'country.RU': '러시아 연방',
  'country.KN': '세인트 키츠 네비스',
  'country.LC': '세인트 루시아',
  'country.VC': '세인트 빈센트 그레나딘',
  'country.UM': '미국령 군소 제도',
  'country.VU': '바누아투',
  'country.VA': '교황청',
  'country.VN': '베트남',
  'country.VG': '버진 아일랜드 (영국)',
  'country.VI': '버진 아일랜드 (미국)',
  'country.WF': '월리스 푸 투나',
  'country.EH': '서부 사하라',
  'country.GW': '기니 비사우',
  'country.GI': '지브롤터',
  'country.SS': '남 수단',
  'country.CW': '퀴 라소',
  'country.AX': '올란드 제도',
  'country.AI': '앵귈라',
  'country.CD': '콩고 민주 공화국',
  'country.CU': '쿠바',
  'country.GG': '건지',
  'country.IR': '이란',
  'country.IM': '아일 오브 맨',
  'country.JE': '저지',
  'country.PS': '팔레스타인',
  'country.MF': '세인트 마틴 (프랑스 령)',
  'country.SX': '신트 마르 턴 (네덜란드 령)',
  'country.SD': '수단',
  'country.SY': '시리아 아랍 공화국',
  'country.BQ': '보네르, 신트 유스 타티 우스 및 사바',
  'country.BL': '생 바르 텔레 미',
  'state.AL': '앨라배마',
  'state.AK': '알래스카',
  'state.AZ': '애리조나',
  'state.AR': '아칸소',
  'state.CA': '캘리포니아',
  'state.CO': '콜로라도',
  'state.CT': '코네티컷',
  'state.DE': '델라웨어',
  'state.FL': '플로리다',
  'state.GA': '그루지야',
  'state.HI': '하와이',
  'state.ID': '아이다 호',
  'state.IL': '일리노이',
  'state.IN': '인디애나',
  'state.IA': '아이오와',
  'state.KS': '캔자스',
  'state.KY': '켄터키',
  'state.LA': '루이지애나',
  'state.ME': '메인',
  'state.MD': '메릴랜드',
  'state.MA': '매사추세츠 주',
  'state.MI': '미시간',
  'state.MN': '미네소타',
  'state.MS': '미시시피',
  'state.MO': '미주리',
  'state.MT': '몬태나',
  'state.NE': '네브라스카',
  'state.NV': '네바다',
  'state.NH': '뉴햄프셔',
  'state.NJ': '뉴저지',
  'state.NM': '뉴 멕시코',
  'state.NY': '뉴욕',
  'state.NC': '노스 캐롤라이나',
  'state.ND': '노스 다코타',
  'state.OH': '오하이오',
  'state.OK': '오클라호마',
  'state.OR': '오리건',
  'state.PA': '펜실베이니아',
  'state.RI': '로드 아일랜드',
  'state.SC': '사우스 캐롤라이나',
  'state.SD': '사우스 다코타',
  'state.TN': '테네시',
  'state.TX': '텍사스',
  'state.UT': '유타',
  'state.VT': '버몬트',
  'state.VA': '여자 이름',
  'state.WA': '워싱턴',
  'state.WV': '웨스트 버지니아',
  'state.WI': '위스콘신',
  'state.WY': '와이오밍',
  'state.DC': '컬럼비아 특별구',
  'state.AS': '아메리칸 사모아',
  'state.FM': '미크로네시아 연방',
  'state.GU': '괌',
  'state.MH': '마샬 군도',
  'state.MP': '북 마리아나 제도',
  'state.PW': '팔라우',
  'state.PR': '푸에르토 리코',
  'state.VI': '버진 아일랜드',
  'state.AE': '군대 유럽, 중동 및 캐나다',
  'state.AP': '국군 태평양',
  'state.AA': '국군 아메리카 (캐나다 제외)',
  'province.AB': '앨버타',
  'province.BC': '브리티시 컬럼비아',
  'province.MB': '매니토바',
  'province.NB': '뉴 브런 즈윅',
  'province.NL': '뉴 펀들 랜드 래브라도',
  'province.NT': '노스 웨스트 준주',
  'province.NS': '노바 스코샤',
  'province.NU': '누나 부트',
  'province.ON': '온타리오',
  'province.PE': '프린스 에드워드 아일랜드',
  'province.QC': '퀘벡',
  'province.SK': '서스 캐처 원',
  'province.YT': '유콘',
  'state.AbuDhabi': 'Abu Dhabi',
  'state.Ajman': 'Ajman',
  'state.Dubai': 'Dubai',
  'state.Fujairah': 'Fujairah',
  'state.RasAlKhaimah': 'Ras Al Khaimah',
  'state.Sharjah': 'Sharjah',
  'state.UmmAlQuwain': 'Umm Al Quwain',
  'billing.aeState': '에미레이트',
  'instate.AN': '안다만 니코바르 제도',
  'instate.AP': '안드라프라데시',
  'instate.AR': '아루나찰프라데시',
  'instate.AS': '아쌈',
  'instate.BR': '비하르',
  'instate.CH': '찬디가르',
  'instate.CT': '차티스가르',
  'instate.DN': '다드라와 나가르 하벨리',
  'instate.DD': '다만과 디우',
  'instate.DL': '델리',
  'instate.GA': '고아',
  'instate.GJ': '구자라트',
  'instate.HR': '하리아나',
  'instate.HP': '히마찰프라데시',
  'instate.JK': '잠무카슈미르',
  'instate.JH': '자르칸드',
  'instate.KA': '카르나타카',
  'instate.KL': '케 랄라',
  'instate.LD': '락샤드위프',
  'instate.MP': '마디 야 프라데시',
  'instate.MH': '마하라슈트라',
  'instate.MN': '마니푸르',
  'instate.ML': '메갈라야',
  'instate.MZ': '미조람',
  'instate.NL': '나가랜드',
  'instate.OR': '오디샤',
  'instate.PY': '푸두체리',
  'instate.PB': '펀잡',
  'instate.RJ': '라자스탄',
  'instate.SK': '시킴',
  'instate.TN': '타밀 나두',
  'instate.TG': '텔랑가나어',
  'instate.TR': '트리푸라',
  'instate.UP': '우타르프라데시',
  'instate.UT': '우타라칸드',
  'instate.WB': '서 뱅갈',
  'billing.inState': '주',
};
