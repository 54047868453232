import React from 'react';
import { bool, string, oneOfType, node, element } from 'prop-types';
import Loading from './Loading';

const LoadingWrapper = ({ loading, children, className = '', type, color }) =>
  (loading ? <Loading className={className} type={type} color={color} /> : children);

LoadingWrapper.propTypes = {
  loading: bool,
  className: string,
  type: string,
  color: string,
  children: oneOfType([node, element]).isRequired,
};

export default LoadingWrapper;
