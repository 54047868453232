export default [
  { code: 'Andaman and Nicobar Islands', translationKey: 'instate.AN' },
  { code: 'Andhra Pradesh', translationKey: 'instate.AP' },
  { code: 'Arunachal Pradesh', translationKey: 'instate.AR' },
  { code: 'Assam', translationKey: 'instate.AS' },
  { code: 'Bihar', translationKey: 'instate.BR' },
  { code: 'Chandigarh', translationKey: 'instate.CH' },
  { code: 'Chhattisgarh', translationKey: 'instate.CT' },
  { code: 'Dadra and Nagar Haveli', translationKey: 'instate.DN' },
  { code: 'Daman and Diu', translationKey: 'instate.DD' },
  { code: 'Delhi', translationKey: 'instate.DL' },
  { code: 'Goa', translationKey: 'instate.GA' },
  { code: 'Gujarat', translationKey: 'instate.GJ' },
  { code: 'Haryana', translationKey: 'instate.HR' },
  { code: 'Himachal Pradesh', translationKey: 'instate.HP' },
  { code: 'Jammu and Kashmir', translationKey: 'instate.JK' },
  { code: 'Jharkhand', translationKey: 'instate.JH' },
  { code: 'Karnataka', translationKey: 'instate.KA' },
  { code: 'Kerala', translationKey: 'instate.KL' },
  { code: 'Lakshadweep', translationKey: 'instate.LD' },
  { code: 'Madhya Pradesh', translationKey: 'instate.MP' },
  { code: 'Maharashtra', translationKey: 'instate.MH' },
  { code: 'Manipur', translationKey: 'instate.MN' },
  { code: 'Meghalaya', translationKey: 'instate.ML' },
  { code: 'Mizoram', translationKey: 'instate.MZ' },
  { code: 'Nagaland', translationKey: 'instate.NL' },
  { code: 'Odisha', translationKey: 'instate.OR' },
  { code: 'Puducherry', translationKey: 'instate.PY' },
  { code: 'Punjab', translationKey: 'instate.PB' },
  { code: 'Rajasthan', translationKey: 'instate.RJ' },
  { code: 'Sikkim', translationKey: 'instate.SK' },
  { code: 'Tamil Nadu', translationKey: 'instate.TN' },
  { code: 'Telangana', translationKey: 'instate.TG' },
  { code: 'Tripura', translationKey: 'instate.TR' },
  { code: 'Uttar Pradesh', translationKey: 'instate.UP' },
  { code: 'Uttarakhand', translationKey: 'instate.UT' },
  { code: 'West Bengal', translationKey: 'instate.WB' },
];
