import { toast } from 'react-toastify';
import { PENDO_DEFAULT_VISITOR_ID } from '../../constants/constants';

/* eslint-disable */
const runPendo = function (apiKey) {
  (function (p, e, n, d, o) {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
      })(v[w]);
    y = e.createElement(n);
    y.async = !0;
    y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');
};
/* eslint-enable */

export const initializePendo = (intl, supplierTag) => async () => {
  try {
    let pendoRetries = 0;
    runPendo(process.env.REACT_APP_PENDO_API_KEY);
    const pendoInterval = setInterval(() => {
      pendoRetries++;
      if (pendoRetries > process.env.REACT_APP_PENDO_RETRIES) {
        clearInterval(pendoInterval);
      }

      const { pendo } = window;
      if (pendo) {
        pendo.initialize({
          visitor: {
            id: PENDO_DEFAULT_VISITOR_ID,
            supplier_tag: supplierTag,
          },
          account: {
            id: process.env.REACT_APP_PENDO_ACCOUNT_ID,
            environment: process.env.REACT_APP_PENDO_ACCOUNT_NAME,
          },
        });
        clearInterval(pendoInterval);
      }
    }, 300);
  } catch (error) {
    toast(
      intl.formatMessage(
        { id: 'pendo.initializationError' },
        {
          0: error.message,
        }
      ),
      { type: toast.TYPE.ERROR }
    );
  }
};

/* eslint-disable indent */
export const updateVisitorInformation = (intl, supplierTag, purchaser) => async () => {
  try {
    const { firstName, lastName, email } = purchaser;
    let pendoRetries = 0;
    const pendoInterval = setInterval(() => {
      pendoRetries++;
      if (pendoRetries > process.env.REACT_APP_PENDO_RETRIES) {
        clearInterval(pendoInterval);
      }

      const { pendo } = window;
      if (pendo) {
        pendo.updateOptions({
          visitor: {
            id: email,
            email,
            firstName,
            lastName,
            supplier_tag: supplierTag,
          },
          account: {
            id: process.env.REACT_APP_PENDO_ACCOUNT_ID,
            environment: process.env.REACT_APP_PENDO_ACCOUNT_NAME,
          },
        });
        clearInterval(pendoInterval);
      }
    }, 300);
  } catch (error) {
    toast(
      intl.formatMessage(
        { id: 'pendo.updateError' },
        {
          0: error.message,
        }
      ),
      { type: toast.TYPE.ERROR }
    );
  }
};
/* eslint-enable indent */
