import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import configureStore from './redux/configureStore';

const { store, persistor } = configureStore();

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
  require('@formatjs/intl-pluralrules/dist/locale-data/es');
  require('@formatjs/intl-pluralrules/dist/locale-data/ja');
  require('@formatjs/intl-pluralrules/dist/locale-data/de');
  require('@formatjs/intl-pluralrules/dist/locale-data/pt');
  require('@formatjs/intl-pluralrules/dist/locale-data/ko');
  require('@formatjs/intl-pluralrules/dist/locale-data/zh');
  require('@formatjs/intl-pluralrules/dist/locale-data/it');
  require('@formatjs/intl-pluralrules/dist/locale-data/fr');
  require('@formatjs/intl-pluralrules/dist/locale-data/el');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/es');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/ja');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/de');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/pt');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/ko');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/zh');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/it');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/fr');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/el');
}

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
