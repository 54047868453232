import React, { useState, useMemo } from 'react';
import cn from 'classnames';
import { array, number, bool, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import OrderItem from './OrderItem';
import EmptyWrapper from '../common/EmptyWrapper';
import OrderContentLoader from './OrderContentLoader';
import { cleanCurrencyNumber } from '../../helpers/localeHelpers';
import '../../styles/components/order/order.scss';

const Order = ({ items, loadingItems, orderDisplaySubtotal = 0, currency, empty }) => {
  const intl = useIntl();

  const [showFullDetail, setShowFullDetail] = useState(false);

  const toggleFullDetail = () => {
    setShowFullDetail(!showFullDetail);
  };

  /**
   * Method reduces the items array, calculating the sum of the amount of lines each OrderLineItem takes.
   * Taken into account:
   *    * Each OrderLineItem: 1 line
   *    * StartDate or EndDate: 1 line each
   *    * supplierAttributes: 1 line each
   *    * discount or product discount: 1 line if any discount is present
   *    * If only one item with one displayName, it should collapse (long descriptions issue).
   */

  const lineCount = useMemo(
    () =>
      items.reduce(
        (
          accum,
          {
            startDate,
            endDate,
            supplierAttributes,
            shippingAddress,
            discount: itemLineDiscount,
            product: { discount: productDiscount },
          }
        ) =>
          accum +
          1 +
          [
            startDate,
            endDate,
            productDiscount || itemLineDiscount,
            ...(supplierAttributes || []),
            !supplierAttributes?.find(({ name }) => name === 'location') && shippingAddress,
          ].filter(elem => elem).length,
        0
      ),
    [items]
  );

  const mayCollapse = useMemo(
    () =>
      lineCount > 2 ||
      (items?.length === 1 && items[0].supplierAttributes?.filter(({ displayName }) => displayName)?.length === 1),
    [items, lineCount]
  );

  return (
    <div className={cn('order-container card', { shrank: !showFullDetail && lineCount < 2 })}>
      {loadingItems ? (
        <OrderContentLoader />
      ) : (
        <>
          <div className="order-information-title-container">
            <h3 className="order-title bold">{intl.formatMessage({ id: 'order.title' })}</h3>
            {mayCollapse && (
              <button
                id="HideDetailButton"
                onClick={toggleFullDetail}
                className={cn('expand-button', { rotated: showFullDetail })}
                title={intl.formatMessage({ id: 'order.moreDetailsAvailable' })}
                aria-label="down"
              >
                <FontAwesomeIcon icon={faChevronDown} size="lg" />
              </button>
            )}
          </div>
          <EmptyWrapper empty={empty} message={intl.formatMessage({ id: 'orderSummary.noOrder' })}>
            <div className="order-items-grid">
              <div className={cn({ 'column-span': showFullDetail || true }, 'order-items-container')}>
                {!mayCollapse || showFullDetail ? (
                  <>
                    {items.map(
                      (
                        {
                          product: { name, unitPrice, discount: productDiscount },
                          startDate,
                          endDate,
                          shippingAddress,
                          quantity,
                          discount: itemLineDiscount,
                          supplierAttributes,
                        },
                        index
                      ) => (
                        <div key={index}>
                          <OrderItem
                            key={index}
                            name={name}
                            startDate={startDate}
                            endDate={endDate}
                            shippingAddress={shippingAddress}
                            unitPrice={unitPrice}
                            quantity={quantity}
                            productDiscount={productDiscount}
                            itemLineDiscount={itemLineDiscount}
                            currency={currency}
                            supplierAttributes={supplierAttributes}
                          />
                        </div>
                      )
                    )}
                  </>
                ) : (
                  items?.length >= 1 && (
                    <>
                      <div className="first-order-item-container">
                        <h4>
                          {items[0].quantity > 1 && (
                            <>
                              {items[0].quantity}
                              {' x '}
                            </>
                          )}
                          {items[0].product.name}
                        </h4>
                      </div>
                      <span className="first-order-item-info" id="ShowDetailLink" onClick={toggleFullDetail}>
                        +{'   '}
                        {items.length > 1
                          ? `${items.length - 1}  ${intl.formatMessage({ id: 'global.more' })}`
                          : intl.formatMessage({ id: 'order.moreDetailsAvailable' })}
                      </span>
                    </>
                  )
                )}
              </div>

              <div className="order-subtotal-container">
                <div className="inline">
                  <h4 className="order-subtotal">{intl.formatMessage({ id: 'order.subtotal' })}:</h4>
                  <h4 className="bold">
                    {`${cleanCurrencyNumber(orderDisplaySubtotal, currency, intl.formatNumberToParts)} ${currency}`}
                  </h4>
                </div>
              </div>
            </div>
          </EmptyWrapper>
        </>
      )}
    </div>
  );
};

export default Order;

Order.propTypes = {
  items: array.isRequired,
  loadingItems: bool,
  orderDisplaySubtotal: number.isRequired,
  currency: string.isRequired,
  empty: bool,
};
