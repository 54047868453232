import React from 'react';
import { string, oneOfType, number, func, bool } from 'prop-types';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import '../../../styles/components/common/inputs/form-field.scss';

const FormField = ({
  label,
  name,
  maxLength,
  onChange,
  value,
  placeholder = '',
  error,
  onBlur,
  elementId,
  fullWidth,
  required,
  autoComplete,
}) => (
  <div className={classNames('form-field-input-container', { 'full-width': fullWidth })}>
    <TextField
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
      label={label}
      required={required}
      name={name}
      error={!!error}
      onBlur={onBlur}
      helperText={error}
      fullWidth
      placeholder={placeholder}
      id={elementId}
      inputProps={{ maxLength }}
    />
  </div>
);

FormField.propTypes = {
  label: string,
  name: string.isRequired,
  maxLength: string,
  onChange: func.isRequired,
  onBlur: func.isRequired,
  value: oneOfType([number, string]),
  placeholder: string,
  error: string,
  elementId: string,
  fullWidth: bool,
  required: bool,
  autoComplete: string,
};

export default FormField;
