import React from 'react';
import { string } from 'prop-types';

const ErrorMessage = ({ errorMessage, className }) => (
  <div className={`error-component-container ${className}`}>
    <span>{errorMessage}</span>
  </div>
);

ErrorMessage.propTypes = {
  errorMessage: string,
  className: string,
};

export default ErrorMessage;
