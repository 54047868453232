import { produce } from 'immer';
import * as types from '../actions/actionTypes';
import createReducer from './utils/createReducer';

export const initialState = {
  transactionToken: undefined,
  authenticated: false,
  locale: null,
  actionsEnabled: true,
  loadingClaims: true,
  loadingOrder: false,
  order: null,
  hideOrderDetails: false,
  useStripe: false,
  stripeClientSecret: null,
  stripePublicKey: null,
  loadingAuthToken: false,
  confirmingTransaction: false,
  clientRedirectUrl: null,
  supportUrl: null,
  termsUrl: null,
  supplierTag: null,
  transactionId: null,
  sessionTimeout: null,
  sessionStart: null,
  maxAttempts: null,
  extensionMinutes: null,
  loadingRedirect: false,
  loadingInvoice: false,
  taxInvoice: undefined,
  loadingCountries: false,
  countries: undefined,
  globalError: null,
  confirmedTransaction: false,
  selectedCard: '',
  secondLabel: '',
  addressRequirementsList: undefined,
};

const actionHandlers = {
  [types.RESTART_REDUCER]: produce(draft => {
    draft.authenticated = false;
    draft.transactionId = null;
    draft.transactionToken = 'expired';
    draft.order = null;
    draft.locale = null;
    draft.clientRedirectUrl = null;
    draft.sessionStart = null;
    draft.supportUrl = null;
    draft.termsUrl = null;
    draft.globalError = null;
    draft.stripeClientSecret = null;
    draft.stripePublicKey = null;
    draft.addressRequirementsList = undefined;
  }),
  [types.SET_SELECTED_CARD_AND_LABEL]: produce((draft, { selectedCard, secondLabel }) => {
    draft.selectedCard = selectedCard;
    draft.secondLabel = secondLabel;
  }),
  [types.DENY_ORDER_WITHOUT_TOKEN]: produce(draft => {
    draft.loadingClaims = false;
    draft.transactionToken = null;
  }),
  [types.ENABLE_ACTIONS]: produce((draft, { actionsEnabled }) => {
    draft.actionsEnabled = actionsEnabled;
  }),
  [types.GET_CLAIMS.REQUEST]: produce((draft, { transactionToken }) => {
    draft.loadingClaims = true;
    draft.locale = null;
    draft.transactionId = null;
    draft.actionsEnabled = true;
    draft.supplierTag = null;
    draft.clientRedirectUrl = null;
    draft.supportUrl = null;
    draft.termsUrl = null;
    draft.authenticated = false;
    draft.sessionTimeout = null;
    draft.sessionStart = null;
    draft.maxAttempts = null;
    draft.extensionMinutes = null;
    draft.order = null;
    draft.hideOrderDetails = false;
    draft.useStripe = false;
    draft.stripeClientSecret = null;
    draft.stripePublicKey = null;
    draft.taxInvoice = null;
    draft.transactionToken = transactionToken;
    draft.globalError = null;
    draft.confirmedTransaction = false;
    draft.selectedCard = null;
    draft.secondLabel = null;
    draft.addressRequirementsList = undefined;
  }),
  [types.GET_CLAIMS.SUCCESS]: produce(
    (
      draft,
      {
        claims: {
          transactionId,
          clientRedirectUrl,
          locale,
          sessionTimeout,          
          maxAttempts,
          extensionMinutes,
          supplierTag,
          supportUrl,
          termsUrl,
          hideOrderDetails,
          useStripe,
          stripeClientSecret,
          stripePublicKey,
        },
      }
    ) => {
      draft.loadingClaims = false;
      draft.stripeClientSecret = stripeClientSecret;
      draft.stripePublicKey = stripePublicKey;
      draft.locale = locale;
      draft.transactionId = transactionId;
      draft.supplierTag = supplierTag;
      draft.clientRedirectUrl = clientRedirectUrl;
      draft.hideOrderDetails = hideOrderDetails === '1';
      draft.useStripe = useStripe === '1';
      draft.supportUrl = supportUrl;
      draft.termsUrl = termsUrl;
      draft.authenticated = true;
      draft.sessionTimeout = parseFloat(sessionTimeout);
      draft.sessionStart = new Date().getTime();
      draft.maxAttempts = +maxAttempts;
      draft.extensionMinutes = parseFloat(extensionMinutes);
    }
  ),
  [types.GET_CLAIMS.FAILURE]: produce((draft, { error }) => {
    draft.loadingClaims = false;
    draft.locale = null;
    draft.transactionId = null;
    draft.supplierTag = null;
    draft.clientRedirectUrl = null;
    draft.supportUrl = null;
    draft.termsUrl = null;
    draft.authenticated = false;
    draft.sessionTimeout = null;
    draft.sessionStart = null;
    draft.maxAttempts = null;
    draft.extensionMinutes = null;
    draft.order = null;
    draft.hideOrderDetails = false;
    draft.useStripe = false;
    draft.stripeClientSecret = null;
    draft.stripePublicKey = null;
    draft.globalError = error;
    draft.addressRequirementsList = undefined;
  }),
  [types.PUMP_UP_EXPIRATION_TIMERS]: produce(draft => {
    draft.sessionTimeout += draft.extensionMinutes * 1.0;
    draft.maxAttempts--;
  }),
  [types.GET_ORDER.REQUEST]: produce(draft => {
    draft.loadingOrder = true;
    draft.order = null;
  }),
  [types.GET_ORDER.SUCCESS]: produce((draft, { paymentOrder }) => {
    draft.loadingOrder = false;
    draft.order = paymentOrder;
  }),
  [types.GET_ORDER.FAILURE]: produce(draft => {
    draft.loadingOrder = false;
    draft.order = null;
  }),
  [types.GET_FULL_ORDER.REQUEST]: produce(draft => {
    draft.loadingOrder = true;
  }),
  [types.GET_FULL_ORDER.SUCCESS]: produce((draft, { paymentOrder }) => {
    draft.loadingOrder = false;
    draft.order = paymentOrder;
  }),
  [types.GET_FULL_ORDER.FAILURE]: produce(draft => {
    draft.loadingOrder = false;
    draft.order = null;
  }),
  [types.UPDATE_ORDER.REQUEST]: produce(draft => {
    draft.loadingOrder = true;
  }),
  [types.UPDATE_ORDER.SUCCESS]: produce((draft, { paymentOrder, stripeClientSecret, stripePublicKey }) => {
    draft.loadingOrder = false;
    draft.order = paymentOrder;
    if (stripeClientSecret) {
      draft.stripeClientSecret = stripeClientSecret;
    }
    if (stripePublicKey) {
      draft.stripePublicKey = stripePublicKey;
    }
  }),
  [types.UPDATE_ORDER.FAILURE]: produce(draft => {
    draft.loadingOrder = false;
    // draft.order = null;
  }),
  [types.UPDATE_ADDRESS_REQUIREMENTS_LIST]: produce((draft, { addressRequirementsList }) => {
    draft.addressRequirementsList = addressRequirementsList;
  }),
  [types.GET_AUTH_TOKEN.REQUEST]: produce(draft => {
    draft.loadingAuthToken = true;
  }),
  [types.GET_AUTH_TOKEN.SUCCESS]: produce((draft, { clientRedirectUrl }) => {
    draft.clientRedirectUrl = clientRedirectUrl;
    draft.loadingAuthToken = false;
  }),
  [types.GET_AUTH_TOKEN.FAILURE]: produce((draft, { clientRedirectUrl }) => {
    draft.clientRedirectUrl = clientRedirectUrl;
    draft.loadingAuthToken = false;
  }),
  [types.CONFIRM_TRANSACTION.REQUEST]: produce(draft => {
    draft.confirmingTransaction = true;
    draft.confirmedTransaction = false;
  }),
  [types.CONFIRM_TRANSACTION.SUCCESS]: produce(draft => {
    draft.confirmingTransaction = false;
    draft.confirmedTransaction = true;
  }),
  [types.CONFIRM_TRANSACTION.FAILURE]: produce(draft => {
    draft.confirmingTransaction = false;
    draft.confirmedTransaction = false;
  }),
  [types.GET_REDIRECT.REQUEST]: produce(draft => {
    draft.loadingRedirect = true;
  }),
  [types.GET_REDIRECT.SUCCESS]: produce((draft, { redirectUrl }) => {
    draft.loadingRedirect = false;
    draft.clientRedirectUrl = redirectUrl;
    draft.sessionTimeout = null;
  }),
  [types.GET_REDIRECT.FAILURE]: produce(draft => {
    draft.loadingRedirect = false;
  }),
  [types.GET_INVOICE.REQUEST]: produce(draft => {
    draft.loadingInvoice = true;
    draft.taxInvoice = undefined;
  }),
  [types.GET_INVOICE.SUCCESS]: produce((draft, { taxInvoice }) => {
    draft.loadingInvoice = false;
    draft.taxInvoice = draft.transactionId && taxInvoice;
  }),
  [types.GET_INVOICE.FAILURE]: produce(draft => {
    draft.loadingInvoice = false;
    draft.taxInvoice = undefined;
  }),
  [types.GET_COUNTRIES.REQUEST]: produce(draft => {
    draft.loadingCountries = true;
    draft.countries = undefined;
  }),
  [types.GET_COUNTRIES.SUCCESS]: produce((draft, { countries }) => {
    draft.loadingCountries = false;
    draft.countries = countries;
  }),
  [types.GET_COUNTRIES.FAILURE]: produce(draft => {
    draft.loadingCountries = false;
    draft.countries = undefined;
    draft.order = null;
  }),
};

export default createReducer(initialState, actionHandlers);
