export default {
  'billing.address': 'Dirección',
  'billing.addressConfirmation': 'Confirmación de Dirección',
  'billing.addressConfirmationDescription':
    'Una dirección válida fue encontrada en base a la dirección original ingresada. Seleccione Confirmar para aceptar la dirección actualizada o Cancelar para modificar la dirección usted mismo.',
  'billing.billing': 'Facturación',
  'billing.billingInformation': 'Información de Facturación',
  'billing.cancelAddressConfirmation': 'Cancelar',
  'billing.caState': 'Provincia',
  'billing.city': 'Ciudad',
  'billing.confirmAddress': 'Confirmar',
  'billing.confirmEmail': 'Confirmar Email',
  'billing.country': 'País',
  'billing.edit': 'Editar',
  'billing.email': 'Email',
  'billing.errorObtainingCountries': 'Ocurrió un error al intentar obtener el listado de países disponibles',
  'billing.errorValidatingAddress': 'Dirección de facturación inválida',
  'billing.firstName': 'Nombre',
  'billing.knownState': 'Estado',
  'billing.lastName': 'Apellido',
  'billing.loadingCountries': 'Cargando países...',
  'billing.memo': 'Nota/Nro. Pedido Compra',
  'billing.organizationName': 'Nombre de la Organización',
  'billing.originalAddress': 'Dirección Original',
  'billing.postalCode': 'Código Postal',
  'billing.save': 'Guardar',
  'billing.state': 'Estado/Provincia/Región',
  'billing.taxId': 'ID de Impuesto',
  'billing.useBillingInformation': 'Usar Información de Facturación',
  'billing.usState': 'Estado',
  'billing.validatedAddress': 'Dirección Validada',
  'billing.VATTaxID': 'ID de Impuesto',
  'cardBrand.amex': 'American Express',
  'cardBrand.cartes_bancaires': 'Cartes Bancaires',
  'cardBrand.diners': "Diner's Club",
  'cardBrand.discover': 'Discover',
  'cardBrand.jcb': 'JCB',
  'cardBrand.mastercard': 'Mastercard',
  'cardBrand.unionpay': 'UnionPay',
  'cardBrand.visa': 'Visa',
  'field.invalidEmail': 'Ingrese un email válido',
  'field.requiredField': 'Este campo es requerido',
  'field.unmatchingEmails': 'Los emails no son iguales',
  'global.AWS_Cobranding':
    'Xvoucher es el revendedor autorizado de este programa de capacitación de Amazon Web Services.',
  'global.backToAWS': 'Volver a AWS',
  'global.backToCart': 'Volver',
  'global.change': 'Cambiar',
  'global.contactSupport': 'Contactar a Soporte',
  'global.defaultSelectOption': 'Seleccione una opción',
  'global.expiredSession': 'La sesión ha expirado',
  'global.expiringSession': 'Su sesión expirará pronto',
  'global.missingPaymentTransaction': 'No se encuentra la información de la transacción',
  'global.more': 'más',
  'global.allRightsReserved': 'todos los derechos reservados',
  'global.pageNotFound': 'La página que está buscando no existe',
  'global.poweredBy': 'Con tecnología de',
  'global.noRemainingAttempts' : 'El intento de pago falló varias veces. Serás redirigido de regreso.',
  'global.rightsReserved': 'Todos los Derechos Reservados',
  'global.support': 'Ayuda',
  'global.terms': 'Términos',
  'global.unauthorizedAccess': 'Usted no está autorizado para ver esta página',
  'invoice.email': 'Email',
  'invoice.errorOnObtainingInvoice': 'Ocurrió un error al mostrar la factura',
  'invoice.errorSendingEmail': 'Ocurrió un error al enviar la factura',
  'invoice.invoiceSent': 'La factura fue enviada',
  'invoice.mailPlaceholder': 'e.g. "john@doe.com; jane@doe.com',
  'invoice.noOrder': 'No hay pedido a ser mostrado',
  'invoice.print': 'Imprimir',
  'invoice.send': 'Enviar',
  'invoice.sessionExpired': 'Tu sesión ha expirado',
  'invoice.wrongMailFormat':
    'Puede usar hasta 10 mails válidos separados por punto y coma. e.g. "john@doe.com; jane@doe.com"',
  'order.errorGettingOrder': 'Ocurrió un error al intentar obtener el pedido',
  'order.errorUpdatingOrder': 'Ocurrió un error al intentar actualizar el pedido',
  'order.invalidToken': 'La transacción posee información inválida',
  'order.locationNotApproved': 'Lo sentimos, esta clase no está disponible para ser ordenada en su área',
  'order.moreDetailsAvailable': 'más detalles',
  'order.noOrder': 'No existe detalle del pedido para mostrar',
  'order.noToken': 'La transacción no es válida',
  'order.subtotal': 'Subtotal',
  'order.title': 'Detalle del Pedido',
  'orderItem.ends': 'Fin',
  'orderItem.itemLineDiscount': 'Descuento del Item',
  'orderItem.location': 'Ubicación',
  'orderItem.productDiscount': 'Descuento del Producto',
  'orderItem.starts': 'Comienzo',
  'orderSummary.cancel': 'Cancelar',
  'orderSummary.cardTypeSelectionLabel': 'Escoja un Tipo de Tarjeta de Crédito',
  'orderSummary.chargedAmountLabel': 'Se le cargarán a su tarjeta',
  'orderSummary.conversionReason': 'Por qué debo pagar en dólares americanos (USD)',
  'orderSummary.currencyChangeExplanation': 'Para pagar en {0}, por favor seleccione Visa o MasterCard.',
  'orderSummary.discount': 'Descuento',
  'orderSummary.estimatedTax': 'Impuestos',
  'orderSummary.fee': 'Tasa',
  'orderSummary.noOrder': 'No existe información para mostrar',
  'orderSummary.orderDiscount': 'Descuento del Pedido',
  'orderSummary.pay': 'Pagar',
  'orderSummary.productDiscount': 'Descuento de Productos',
  'orderSummary.productSubTotal': 'Subtotal del Producto',
  'orderSummary.productTotal': 'Total de Productos',
  'orderSummary.title': 'Resumen del Pedido',
  'orderSummary.total': 'Total',
  'orderSummary.totalBeforeTax': 'Total Sin Impuestos',
  'payment.cancelledPayment': 'El pago fue cancelado',
  'payment.confirmingTransaction': 'Confirmando la transacción...',
  'payment.convergeFailure': 'Ocurrió un error al intentar inicializar el pago',
  'payment.errorObtainingRedirectURL': 'Ocurrió un error al intentar obtener el sitio de redirección',
  'payment.errorProcessingTransaction': 'Ocurrió un error al procesar la transacción',
  'payment.fraudSuspectionError':
    'Para su seguridad, el intento de pago no puede ser realizado, y se han enviado los detalles a nuestro equipo de seguridad. Si cree haber recibido este mensaje por error, por favor contacte al <a href="{0}">soporte de Xvoucher</a>',
  'payment.incompatibleCardForCurrency':
    'Pagos en una moneda distinta a USD deben ser realizados con Visa o MasterCard',
  'payment.initFailure': 'Ocurrió un error al intentar inicializar el pago',
  'payment.postalCodeRequired': 'El código postal es requerido para esta transacción',
  'payment.transactionApproved': 'El pago fue aprobado',
  'payment.transactionCancelled': 'El pago fue cancelado',
  'payment.transactionDeclined': 'La transacción fue rechazada',
  'payment.transactionDeclinedCardDetails': 'La información de su tarjeta no era correcta',
  'payment.transactionError': 'Hubo un error al realizar la transacción',
  'payment.transactionProcessing': 'Se está procesando la transacción con tarjeta de crédito',
  'payment.transactionRequiresAction': 'La transacción con tarjeta de crédito requiere acciones adicionales',
  'payment.transactionRequiresCapture': 'La transacción con tarjeta de crédito requiere la captura del pago',
  'payment.transactionRequiresConfirmation': 'La transacción con tarjeta de crédito requiere confirmación',
  'payment.transactionRequiresPaymentMethod': 'La transacción con tarjeta de crédito requiere un método de pago',
  'payment.unsupportedCardOfferCurrencyConversion':
    'No se aceptan tarjetas {0} para pagos en {1}. Utilice una marca diferente de tarjeta o pague en {2}.',
  'pendo.initializationError': 'Ocurrió un error al intentar inicializar Pendo: {0}',
  'pendo.updateError': 'Ocurrió un error al intentar actualizar Pendo: {0}',
  'test.insertToken': 'Inserte token de prueba aquí',
  'country.US': 'Estados Unidos',
  'country.CA': 'Canadá',
  'country.AF': 'Afganistán',
  'country.AL': 'Albania',
  'country.DZ': 'Argelia',
  'country.AO': 'Angola',
  'country.AR': 'Argentina',
  'country.AT': 'Austria',
  'country.AU': 'Australia',
  'country.BE': 'Bélgica',
  'country.BH': 'Bahrein',
  'country.BR': 'Brasil',
  'country.BG': 'Bulgaria',
  'country.CM': 'Camerún',
  'country.CV': 'Cabo Verde',
  'country.CL': 'Chile',
  'country.CN': 'China',
  'country.CO': 'Colombia',
  'country.CZ': 'Chequia',
  'country.DK': 'Dinamarca',
  'country.EG': 'Egipto',
  'country.ET': 'Etiopía',
  'country.EE': 'Estonia',
  'country.FI': 'Finlandia',
  'country.FR': 'Francia',
  'country.GA': 'Gabón',
  'country.GM': 'Gambia',
  'country.GH': 'Ghana',
  'country.DE': 'Alemania',
  'country.GR': 'Grecia',
  'country.HK': 'Hong Kong',
  'country.HU': 'Hungría',
  'country.IN': 'India',
  'country.ID': 'Indonesia',
  'country.IQ': 'Irak',
  'country.IE': 'Irlanda',
  'country.IL': 'Israel',
  'country.IT': 'Italia',
  'country.JP': 'Japón',
  'country.JO': 'Jordán',
  'country.KE': 'Kenia',
  'country.KP': 'Corea del Norte',
  'country.KW': 'Kuwait',
  'country.LV': 'Letonia',
  'country.LB': 'Líbano',
  'country.LR': 'Liberia',
  'country.LT': 'Lituania',
  'country.LU': 'Luxemburgo',
  'country.MK': 'Macedonia del Norte',
  'country.MG': 'Madagascar',
  'country.MY': 'Malasia',
  'country.MA': 'Marruecos',
  'country.MX': 'México',
  'country.NL': 'Países Bajos',
  'country.NG': 'Nigeria',
  'country.NO': 'Noruega',
  'country.OM': 'Omán',
  'country.PK': 'Pakistán',
  'country.PE': 'Perú',
  'country.PH': 'Filipinas',
  'country.PL': 'Polonia',
  'country.PT': 'Portugal',
  'country.PR': 'Puerto Rico',
  'country.QA': 'Qatar',
  'country.RO': 'Rumania',
  'country.RW': 'Ruanda',
  'country.SA': 'Arabia Saudita',
  'country.SN': 'Senegal',
  'country.RS': 'Serbia',
  'country.SL': 'Sierra Leona',
  'country.SG': 'Singapur',
  'country.SI': 'Eslovenia',
  'country.ZA': 'Sudáfrica',
  'country.ES': 'España',
  'country.SE': 'Suecia',
  'country.TH': 'Tailandia',
  'country.TN': 'Túnez',
  'country.TR': 'Turquía',
  'country.UG': 'Uganda',
  'country.UA': 'Ucrania',
  'country.AE': 'Emiratos Árabes Unidos',
  'country.GB': 'Reino Unido',
  'country.VE': 'Venezuela (República Bolivariana de)',
  'country.YE': 'Yemen',
  'country.ZM': 'Zambia',
  'country.AM': 'Armenia',
  'country.CR': 'Costa Rica',
  'country.DO': 'República Dominicana',
  'country.LK': 'Sri Lanka',
  'country.CH': 'Suiza',
  'country.JM': 'Jamaica',
  'country.KZ': 'Kazajstán',
  'country.NZ': 'Nueva Zelanda',
  'country.NP': 'Nepal',
  'country.ZW': 'Zimbabue',
  'country.EC': 'Ecuador',
  'country.GT': 'Guatemala',
  'country.BD': 'Bangladesh',
  'country.UY': 'Uruguay',
  'country.MN': 'Mongolia',
  'country.KH': 'Camboya',
  'country.ME': 'Montenegro',
  'country.BW': 'Botswana',
  'country.KG': 'Kirguistán',
  'country.TJ': 'Tayikistán',
  'country.UZ': 'Uzbekistán',
  'country.AZ': 'Azerbaiyán',
  'country.TM': 'Turkmenistán',
  'country.TT': 'Trinidad y Tobago',
  'country.GN': 'Guinea',
  'country.AS': 'Samoa Americana',
  'country.AD': 'Andorra',
  'country.AQ': 'Antártida',
  'country.AG': 'Antigua y Barbuda',
  'country.AW': 'Aruba',
  'country.BS': 'Bahamas',
  'country.BB': 'Barbados',
  'country.BM': 'Bermudas',
  'country.BY': 'Bielorrusia',
  'country.BZ': 'Belice',
  'country.BJ': 'Benín',
  'country.BT': 'Bután',
  'country.BO': 'Bolivia',
  'country.BA': 'Bosnia y Herzegovina',
  'country.BV': 'Isla Bouvet',
  'country.GU': 'Guam',
  'country.GY': 'Guayana',
  'country.HT': 'Haití',
  'country.HM': 'Islas Heard y McDonald',
  'country.HN': 'Honduras',
  'country.IS': 'Islandia',
  'country.KI': 'Kiribati',
  'country.KR': 'Corea del Sur',
  'country.LA': 'Laos',
  'country.WS': 'Samoa',
  'country.SM': 'San Marino',
  'country.ST': 'Santo Tomé y Príncipe',
  'country.SC': 'Seychelles',
  'country.SK': 'Eslovaquia',
  'country.SB': 'Islas Salomón',
  'country.SO': 'Somalia',
  'country.GS': 'Islas Georgia del Sur y Sandwich del Sur',
  'country.SH': 'Santa Elena, Ascensión y Tristán de Cunha',
  'country.PM': 'San Pedro y Miquelón',
  'country.SR': 'Surinam',
  'country.SJ': 'Svalbard y Jan Mayen',
  'country.IO': 'Territorio Británico del Océano Indico',
  'country.BN': 'Brunei',
  'country.BF': 'Burkina Faso',
  'country.BI': 'Burundi',
  'country.KY': 'Islas Caimán',
  'country.CF': 'República Centroafricana',
  'country.TD': 'Chad',
  'country.CX': 'Isla Christmas',
  'country.CC': 'Islas Cocos',
  'country.KM': 'Comoras',
  'country.CG': 'Congo',
  'country.CK': 'Islas Cook',
  'country.CI': 'Costa de Marfil',
  'country.HR': 'Croacia',
  'country.CY': 'Chipre',
  'country.DJ': 'Djibouti',
  'country.LS': 'Lesoto',
  'country.LY': 'Libia',
  'country.LI': 'Liechtenstein',
  'country.MO': 'Macao',
  'country.MW': 'Malawi',
  'country.MV': 'Maldivas',
  'country.ML': 'Mali',
  'country.MT': 'Malta',
  'country.MH': 'Islas Marshall',
  'country.MQ': 'Martinica',
  'country.MR': 'Mauritania',
  'country.MU': 'Mauricio',
  'country.YT': 'Mayotte',
  'country.FM': 'Micronesia',
  'country.MD': 'Moldavia',
  'country.MC': 'Mónaco',
  'country.MS': 'Montserrat',
  'country.MZ': 'Mozambique',
  'country.MM': 'Myanmar',
  'country.NA': 'Namibia',
  'country.NR': 'Nauru',
  'country.SZ': 'Eswatini',
  'country.TW': 'Taiwán',
  'country.TZ': 'Tanzania, República Unida de',
  'country.TG': 'Togo',
  'country.TK': 'Tokelau',
  'country.TO': 'Tonga',
  'country.TC': 'Islas Turcas y Caicos',
  'country.TV': 'Tuvalu',
  'country.DM': 'Dominica',
  'country.TL': 'Timor-Leste',
  'country.SV': 'El Salvador',
  'country.GQ': 'Guinea Ecuatorial',
  'country.ER': 'Eritrea',
  'country.FK': 'Islas Malvinas (Falkland Islands)',
  'country.FO': 'Islas Faroe',
  'country.FJ': 'Fiyi',
  'country.GF': 'Guayana Francesa',
  'country.PF': 'Polinesia Francés',
  'country.TF': 'Territorios Australes Franceses',
  'country.GE': 'Georgia',
  'country.GD': 'Granada',
  'country.GL': 'Groenlandia',
  'country.GP': 'Guadalupe',
  'country.NC': 'Nueva Caledonia',
  'country.NI': 'Nicaragua',
  'country.NE': 'Níger',
  'country.NU': 'Niue',
  'country.NF': 'Isla Norfolk',
  'country.MP': 'Islas Marianas del Norte',
  'country.PW': 'Palau',
  'country.PA': 'Panamá',
  'country.PG': 'Papúa Nueva Guinea',
  'country.PY': 'Paraguay',
  'country.PN': 'Islas Pitcairn',
  'country.RE': 'Reunión',
  'country.RU': 'Federación Rusa',
  'country.KN': 'San Cristóbal y Nieves',
  'country.LC': 'Santa Lucía',
  'country.VC': 'San Vicente y las Granadinas',
  'country.UM': 'Islas menores alejadas de los Estados Unidos',
  'country.VU': 'Vanuatu',
  'country.VA': 'Ciudad del Vaticano',
  'country.VN': 'Vietnam',
  'country.VG': 'Islas Vírgenes Británicas',
  'country.VI': 'Islas Vírgenes (EE. UU.)',
  'country.WF': 'Wallis y Futuna',
  'country.EH': 'Sahara Occidental',
  'country.GW': 'Guinea-Bissau',
  'country.GI': 'Gibraltar',
  'country.SS': 'Sudán del Sur',
  'country.CW': 'Curacao',
  'country.AX': 'Islas Åland',
  'country.AI': 'Anguila',
  'country.CD': 'República Democrática del Congo',
  'country.CU': 'Cuba',
  'country.GG': 'Guernsey',
  'country.IR': 'Irán (República Islámica de)',
  'country.IM': 'Isla de Man',
  'country.JE': 'Jersey',
  'country.PS': 'Palestina',
  'country.MF': 'San Martín (parte francesa)',
  'country.SX': 'Sint Maarten (parte holandesa)',
  'country.SD': 'Sudán',
  'country.SY': 'República Árabe Siria',
  'country.BQ': 'Bonaire, San Eustaquio y Saba',
  'country.BL': 'San Bartolomé',
  'state.AL': 'Alabama',
  'state.AK': 'Alaska',
  'state.AZ': 'Arizona',
  'state.AR': 'Arkansas',
  'state.CA': 'California',
  'state.CO': 'Colorado',
  'state.CT': 'Connecticut',
  'state.DE': 'Delaware',
  'state.FL': 'Florida',
  'state.GA': 'Georgia',
  'state.HI': 'Hawai',
  'state.ID': 'Idaho',
  'state.IL': 'Illinois',
  'state.IN': 'Indiana',
  'state.IA': 'Iowa',
  'state.KS': 'Kansas',
  'state.KY': 'Kentucky',
  'state.LA': 'Luisiana',
  'state.ME': 'Maine',
  'state.MD': 'Maryland',
  'state.MA': 'Massachusetts',
  'state.MI': 'Michigan',
  'state.MN': 'Minnesota',
  'state.MS': 'Misisipí',
  'state.MO': 'Misuri',
  'state.MT': 'Montana',
  'state.NE': 'Nebraska',
  'state.NV': 'Nevada',
  'state.NH': 'Nuevo Hampshire',
  'state.NJ': 'New Jersey',
  'state.NM': 'Nuevo Mexico',
  'state.NY': 'Nueva York',
  'state.NC': 'Carolina del Norte',
  'state.ND': 'Dakota del Norte',
  'state.OH': 'Ohio',
  'state.OK': 'Oklahoma',
  'state.OR': 'Oregón',
  'state.PA': 'Pensilvania',
  'state.RI': 'Rhode Island',
  'state.SC': 'Carolina del Sur',
  'state.SD': 'Dakota del Sur',
  'state.TN': 'Tennesse',
  'state.TX': 'Texas',
  'state.UT': 'Utah',
  'state.VT': 'Vermont',
  'state.VA': 'Virginia',
  'state.WA': 'Washington',
  'state.WV': 'Virginia del Oeste',
  'state.WI': 'Wisconsin',
  'state.WY': 'Wyoming',
  'state.DC': 'Distrito de Columbia',
  'state.AS': 'Samoa Americana',
  'state.FM': 'Estados Federados de Micronesia',
  'state.GU': 'Guam',
  'state.MH': 'Islas Marshall',
  'state.MP': 'Islas Marianas del Norte',
  'state.PW': 'Palau',
  'state.PR': 'Puerto Rico',
  'state.VI': 'Islas Virgenes',
  'state.AE': 'Fuerzas Armadas Europa, Medio Oriente y Canadá',
  'state.AP': 'Fuerzas Armadas del Pacífico',
  'state.AA': 'Fuerzas Armadas de las Américas (excepto Canadá)',
  'province.AB': 'Alberta',
  'province.BC': 'Columbia Británica',
  'province.MB': 'Manitoba',
  'province.NB': 'Nuevo Brunswick',
  'province.NL': 'Newfoundland y Labrador',
  'province.NT': 'Territorios del Noroeste',
  'province.NS': 'Nueva Escocia',
  'province.NU': 'Nunavut',
  'province.ON': 'Ontario',
  'province.PE': 'Isla del Príncipe Eduardo',
  'province.QC': 'Quebec',
  'province.SK': 'Saskatchewan',
  'province.YT': 'Yukon',
  'state.AbuDhabi': 'Abu Dhabi',
  'state.Ajman': 'Ajman',
  'state.Dubai': 'Dubai',
  'state.Fujairah': 'Fujairah',
  'state.RasAlKhaimah': 'Ras Al Khaimah',
  'state.Sharjah': 'Sharjah',
  'state.UmmAlQuwain': 'Umm Al Quwain',
  'billing.aeState': 'Emirato',
  'instate.AN': 'Islas Andaman y Nicobar',
  'instate.AP': 'Andhra Pradesh',
  'instate.AR': 'Arunachal Pradesh',
  'instate.AS': 'Assam',
  'instate.BR': 'Bihar',
  'instate.CH': 'Chandigarh',
  'instate.CT': 'Chattisgarh',
  'instate.DN': 'Dadra y Nagar Haveli',
  'instate.DD': 'daman y diu',
  'instate.DL': 'Delhi',
  'instate.GA': 'Goa',
  'instate.GJ': 'Gujarat',
  'instate.HR': 'Hariana',
  'instate.HP': 'Himachal Pradesh',
  'instate.JK': 'Jammu y Cachemira',
  'instate.JH': 'Jharkhand',
  'instate.KA': 'Karnataka',
  'instate.KL': 'Kerala',
  'instate.LD': 'Lakshadweep',
  'instate.MP': 'Madhya Pradesh',
  'instate.MH': 'Maharashtra',
  'instate.MN': 'Manipur',
  'instate.ML': 'Megalaya',
  'instate.MZ': 'Mizoram',
  'instate.NL': 'Nagalandia',
  'instate.OR': 'Odisha',
  'instate.PY': 'Puducherry',
  'instate.PB': 'Punjab',
  'instate.RJ': 'Rajastán',
  'instate.SK': 'Sikkim',
  'instate.TN': 'tamil nadu',
  'instate.TG': 'Telangana',
  'instate.TR': 'Tripura',
  'instate.UP': 'Uttar Pradesh',
  'instate.UT': 'Uttarakhand',
  'instate.WB': 'Bengala Occidental',
  'billing.inState': 'Estado',
};
