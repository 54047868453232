import xVoucherSal from 'xvpay-sal';
import { toast } from 'react-toastify';
import { createIntl, createIntlCache } from 'react-intl';
import * as types from './actionTypes';

import {
  setOrderErrorMessage,
  deleteOrderErrorMessage,
  setBillingErrorMessage,
  deleteBillingErrorMessage,
  setPaymentErrorMessage,
  deletePaymentErrorMessage,
} from './errorActions';

import locales from '../../locales';
import { DEFAULT_LANGUAGE } from '../../constants/constants';
import sessionStorageKeys from '../../constants/sessionStorageKeys';

import AddressValidationStates from '../../constants/addressValidationState';
import AddressValidationFlags from '../../constants/addressValidationFlags';
import PaymentResultState from '../../constants/paymentResultState';

const cache = createIntlCache();

const initialLocale = DEFAULT_LANGUAGE;

let intl = createIntl(
  {
    locale: initialLocale,
    messages: locales[initialLocale],
  },
  cache
);

const transformAddressRequirements = requiredComponents =>
  // eslint-disable-next-line no-bitwise
  AddressValidationFlags.reduce((accum, { key, value }) => (requiredComponents & value ? [key, ...accum] : accum), []);

const updateLocale = locale => {
  const initialLocale = locale || DEFAULT_LANGUAGE;
  const localePriorities = [initialLocale, initialLocale.split('-')[0], DEFAULT_LANGUAGE];
  const localizedPriorities = localePriorities.map(locale => locales[locale]);
  // Find non-null element
  const localePos = localizedPriorities.findIndex(elem => elem);

  intl = createIntl(
    {
      locale: localePriorities[localePos],
      messages: localizedPriorities[localePos],
    },
    cache
  );
};

export const enableActionsAction = actionsEnabled => ({
  type: types.ENABLE_ACTIONS,
  actionsEnabled,
});

export const enableActions = actionsEnabled => async dispatch => {
  dispatch(enableActionsAction(actionsEnabled));
};

export const denyOrderWithoutTokenAction = () => ({
  type: types.DENY_ORDER_WITHOUT_TOKEN,
});

export const denyOrderWithoutToken = () => async dispatch => {
  dispatch(denyOrderWithoutTokenAction());
};

export const restartReducer = () => ({
  type: types.RESTART_REDUCER,
});

export const setSelectedCardAndLabelAction = (selectedCard, secondLabel) => ({
  type: types.SET_SELECTED_CARD_AND_LABEL,
  selectedCard,
  secondLabel,
});

export const setSelectedCardAndLabel = (selectedCard, secondLabel) => async dispatch => {
  dispatch(setSelectedCardAndLabelAction(selectedCard, secondLabel));
};

export const getCountriesByLocaleAction = () => ({
  type: types.GET_COUNTRIES.REQUEST,
});

export const getCountriesByLocaleSuccess = countries => ({
  type: types.GET_COUNTRIES.SUCCESS,
  countries,
});

export const getCountriesByLocaleFailure = () => ({
  type: types.GET_COUNTRIES.FAILURE,
});

export const getCountriesByLocale = locale => async dispatch => {
  try {
    dispatch(getCountriesByLocaleAction());
    const { countries } = await xVoucherSal.getCountriesByLocale(locale);
    dispatch(getCountriesByLocaleSuccess(countries));
  } catch (error) {
    dispatch(getCountriesByLocaleFailure());
  }
};

export const getClaimsAction = transactionToken => ({
  type: types.GET_CLAIMS.REQUEST,
  transactionToken,
});

export const getClaimsSuccess = claims => ({
  type: types.GET_CLAIMS.SUCCESS,
  claims,
});

export const getClaimsFailure = error => ({
  type: types.GET_CLAIMS.FAILURE,
  error,
});

export const getClaims = token => async dispatch => {
  try {
    sessionStorage.removeItem(sessionStorageKeys.AUTH_TOKEN_STORAGE_KEY);
    dispatch(getClaimsAction(token));
    if (!token) {
      dispatch(getClaimsFailure(intl.formatMessage({ id: 'global.unauthorizedAccess' })));
      return;
    }
    const paymentOrder = await xVoucherSal.getClaims(token);
    const { code, claims, claims: { authToken } = {} } = paymentOrder;
    if (code === 0 && authToken) {
      if (claims && claims.transactionId) {
        sessionStorage.setItem(sessionStorageKeys.AUTH_TOKEN_STORAGE_KEY, authToken);
        const { locale } = claims;
        updateLocale(locale);
        dispatch(getClaimsSuccess(claims));
        return true;
      }
      dispatch(getClaimsFailure(intl.formatMessage({ id: 'global.missingPaymentTransaction' })));
    } else {
      dispatch(getClaimsFailure(intl.formatMessage({ id: 'global.unauthorizedAccess' })));
    }
  } catch (error) {
    dispatch(getClaimsFailure());
  }
};

export const pumpUpExpirationTimersAction = () => ({
  type: types.PUMP_UP_EXPIRATION_TIMERS,
});

export const pumpUpExpirationTimers = () => async dispatch => {
  dispatch(pumpUpExpirationTimersAction());
};

export const getOrderAction = () => ({
  type: types.GET_ORDER.REQUEST,
});

export const getOrderSuccess = paymentOrder => ({
  type: types.GET_ORDER.SUCCESS,
  paymentOrder,
});

export const getOrderFailure = () => ({
  type: types.GET_ORDER.FAILURE,
});

export const getOrder = (transactionId, locale, clientRedirectUrl, supplierTag) => async dispatch => {
  try {
    dispatch(getOrderAction());
    const paymentOrder = await xVoucherSal.getOrder(transactionId, locale, supplierTag);
    const { code } = paymentOrder;
    if (code === 0) {
      dispatch(getOrderSuccess(paymentOrder));
    } else {
      dispatch(getOrderFailure());
    }
  } catch (error) {
    dispatch(getOrderFailure());
  }
};

export const getFullOrderAction = () => ({
  type: types.GET_FULL_ORDER.REQUEST,
});

export const getFullOrderSuccess = paymentOrder => ({
  type: types.GET_FULL_ORDER.SUCCESS,
  paymentOrder,
});

export const getFullOrderFailure = () => ({
  type: types.GET_FULL_ORDER.FAILURE,
});

export const getFullOrder = (transactionId, locale, clientRedirectUrl, supplierTag) => async dispatch => {
  try {
    dispatch(deleteOrderErrorMessage());
    dispatch(getFullOrderAction());
    const paymentOrder = await xVoucherSal.getFullOrder(transactionId, locale, supplierTag);
    const { code } = paymentOrder;
    if (code === 0) {
      dispatch(getFullOrderSuccess(paymentOrder));
    } else {
      setOrderErrorMessage(intl.formatMessage({ id: 'order.errorGettingOrder' }))(dispatch);
      dispatch(getFullOrderFailure());
    }
  } catch (error) {
    dispatch(getFullOrderFailure());
    setOrderErrorMessage(intl.formatMessage({ id: 'order.errorGettingOrder' }))(dispatch);
  }
};

export const updateAddressRequirementsList = addressRequirementsList => ({
  type: types.UPDATE_ADDRESS_REQUIREMENTS_LIST,
  addressRequirementsList,
});

export const getAddressRequirements = address => async dispatch => {
  try {
    dispatch(updateAddressRequirementsList());
    const { code, custom: { requiredComponents } = {} } = await xVoucherSal.getAddressRequiredComponents(address);
    if (code === 0 && requiredComponents) {
      const addressRequirementsList = transformAddressRequirements(requiredComponents);
      dispatch(updateAddressRequirementsList(addressRequirementsList));
    }
  } catch (error) { }
};

export const validateAddress = (address, locale) => async dispatch => {
  try {
    dispatch(deleteBillingErrorMessage());
    toast.dismiss(sessionStorage.getItem('updateOrderToastId'));
    const { code, message, custom } = await xVoucherSal.validateAddress(address, locale);
    const { addresses: [newAddress] = [] } = custom || {};
    if (code === 0) {
      return { state: AddressValidationStates.VALID, address: newAddress };
    }
    if (code === 306) {
      return { state: AddressValidationStates.NEEDS_CONFIRMATION, address: newAddress, message };
    }
    if (code === -304) {
      setBillingErrorMessage(intl.formatMessage({ id: 'billing.errorValidatingAddress' }))(dispatch);
      return { state: AddressValidationStates.INVALID };
    }

    setBillingErrorMessage(intl.formatMessage({ id: 'billing.errorValidatingAddress' }))(dispatch);
    return { state: AddressValidationStates.INVALID };
  } catch (error) {
    setBillingErrorMessage(intl.formatMessage({ id: 'billing.errorValidatingAddress' }))(dispatch);
    return { state: AddressValidationStates.INVALID };
  }
};

export const updateOrderAction = () => ({
  type: types.UPDATE_ORDER.REQUEST,
});

export const updateOrderSuccess = (paymentOrder, stripePublicKey, stripeClientSecret) => ({
  type: types.UPDATE_ORDER.SUCCESS,
  paymentOrder,
  stripePublicKey,
  stripeClientSecret,
});

export const updateOrderFailure = () => ({
  type: types.UPDATE_ORDER.FAILURE,
});

export const updateOrder =
  (transactionId, locale, clientRedirectUrl, supplierTag, paymentOrder, skipAction) => async dispatch => {
    try {
      dispatch(deleteBillingErrorMessage());
      dispatch(deleteOrderErrorMessage());
      toast.dismiss(sessionStorage.getItem('updateOrderToastId'));
      !skipAction && dispatch(updateOrderAction());
      const newPaymentOrder = await xVoucherSal.updateOrder(transactionId, locale, supplierTag, paymentOrder);
      const { code, stripePublicKey, stripeClientSecret } = newPaymentOrder;
      if (code === 0) {
        dispatch(updateOrderSuccess(newPaymentOrder, stripePublicKey, stripeClientSecret));
        return stripeClientSecret;
      }
      if ([-302, -600, -601, 304, 305].includes(code)) {
        // TODO replace array above. Drew's pre-release request.
        dispatch(updateOrderFailure());
        setOrderErrorMessage(intl.formatMessage({ id: 'order.locationNotApproved' }))(dispatch);
      } else {
        dispatch(updateOrderFailure());
        setOrderErrorMessage(intl.formatMessage({ id: 'order.errorUpdatingOrder' }))(dispatch);
      }
    } catch (error) {
      dispatch(updateOrderFailure());
      setOrderErrorMessage(intl.formatMessage({ id: 'order.errorUpdatingOrder' }))(dispatch);
    }
  };

export const confirmTransactionAction = () => ({
  type: types.CONFIRM_TRANSACTION.REQUEST,
});

export const confirmTransactionSuccess = () => ({
  type: types.CONFIRM_TRANSACTION.SUCCESS,
});

export const confirmTransactionFailure = () => ({
  type: types.CONFIRM_TRANSACTION.FAILURE,
});

export const confirmTransaction = (transactionId, supplierTag, locale) => async dispatch => {
  let errorMessage = '';
  try {
    dispatch(deletePaymentErrorMessage());
    dispatch(confirmTransactionAction());
    const { clientRedirectUrl, code } = await xVoucherSal.confirmTransaction(transactionId, supplierTag, locale);
    switch (code) {
      case 310:
        dispatch(confirmTransactionSuccess());
        window.location.href = clientRedirectUrl;
        return false;
      case 311:
        dispatch(confirmTransactionFailure());
        errorMessage = intl.formatMessage({ id: 'payment.transactionDeclined' });
        break;
      case 312:
        dispatch(confirmTransactionFailure());
        errorMessage = intl.formatMessage({ id: 'payment.transactionError' });
        break;
      case 313:
        dispatch(confirmTransactionFailure());
        errorMessage = intl.formatMessage({ id: 'payment.transactionCancelled' });
        break;
      case 314:
        dispatch(confirmTransactionFailure());
        // TODO: Consider changing behaviour
        errorMessage = intl.formatMessage({ id: 'payment.transactionProcessing' });
        break;
      case 315:
        dispatch(confirmTransactionFailure());
        // TODO: Consider changing behaviour. Shouldn't happen (stripe.confirmCardPayment handles this)
        errorMessage = intl.formatMessage({ id: 'payment.transactionRequiresAction' });
        break;
      case 316:
        dispatch(confirmTransactionFailure());
        // TODO: Consider changing behaviour.
        errorMessage = intl.formatMessage({ id: 'payment.transactionRequiresCapture' });
        break;
      case 317:
        dispatch(confirmTransactionFailure());
        // TODO: Consider changing behaviour.
        errorMessage = intl.formatMessage({ id: 'payment.transactionRequiresConfirmation' });
        break;
      case 318:
        dispatch(confirmTransactionFailure());
        // TODO: Consider changing behaviour.
        errorMessage = intl.formatMessage({ id: 'payment.transactionRequiresPaymentMethod' });
        break;
      case 319:
        dispatch(confirmTransactionFailure());
        errorMessage = intl.formatMessage({ id: 'payment.transactionDeclinedCardDetails' });
        break;
      case 320:
        dispatch(confirmTransactionFailure());
        errorMessage = intl.formatMessage({ id: 'payment.transactionDeclinedOfferUSD' });
        break;
      default:
        dispatch(confirmTransactionFailure());
        errorMessage = intl.formatMessage({ id: 'payment.errorProcessingTransaction' });
        break;
    }
  } catch (error) {
    dispatch(confirmTransactionFailure());
    errorMessage = intl.formatMessage({ id: 'payment.errorProcessingTransaction' });
  }
  if (errorMessage) {
    await setPaymentErrorMessage(errorMessage)(dispatch);
    return { errorMessage };
  }
  return { success: true };
};

export const getRedirectAction = () => ({
  type: types.GET_REDIRECT.REQUEST,
});

export const getRedirectSuccess = redirectUrl => ({
  type: types.GET_REDIRECT.SUCCESS,
  redirectUrl,
});

export const getRedirectFailure = () => ({
  type: types.GET_REDIRECT.FAILURE,
});

export const getRedirect =
  (transactionId, locale, supplierTag, cancelPayment, paymentResultState) => async dispatch => {
    try {
      dispatch(getRedirectAction());
      const { code, clientRedirectUrl, clientRedirectTimeoutUrl, clientRedirectCancelUrl } =
        await xVoucherSal.getRedirect(transactionId, locale, supplierTag, cancelPayment);
      if (code === 0) {
        let redirectUrl = clientRedirectUrl;
        switch (paymentResultState) {
          case PaymentResultState.EXPIRED:
            redirectUrl = clientRedirectTimeoutUrl;
            break;
          case PaymentResultState.CANCELLED:
            redirectUrl = clientRedirectCancelUrl;
            break;
          default:
            redirectUrl = clientRedirectUrl;
            break;
        }
        dispatch(getRedirectSuccess(redirectUrl || clientRedirectUrl));
        if (cancelPayment) {
          dispatch(restartReducer());
          window.location.href = redirectUrl || clientRedirectUrl;
        }
      } else {
        dispatch(getRedirectFailure());
        toast(intl.formatMessage({ id: 'payment.errorObtainingRedirectURL' }), {
          type: toast.TYPE.ERROR,
        });
      }
    } catch (error) {
      dispatch(getRedirectFailure());
      toast(intl.formatMessage({ id: 'payment.errorObtainingRedirectURL' }), {
        type: toast.TYPE.ERROR,
      });
    }
  };

export const getInvoiceAction = () => ({
  type: types.GET_INVOICE.REQUEST,
});

export const getInvoiceSuccess = taxInvoice => ({
  type: types.GET_INVOICE.SUCCESS,
  taxInvoice,
});

export const getInvoiceFailure = () => ({
  type: types.GET_INVOICE.FAILURE,
});

export const getInvoice = (transactionId, supplierTag, locale) => async dispatch => {
  try {
    dispatch(getInvoiceAction());
    const { taxInvoice, code } = await xVoucherSal.getInvoice(transactionId, supplierTag, locale);
    if (code === 0) {
      dispatch(getInvoiceSuccess(taxInvoice));
    } else {
      dispatch(getInvoiceFailure());
    }
  } catch (error) {
    dispatch(getInvoiceFailure());
  }
};

export const sendInvoiceAction = () => ({
  type: types.SEND_INVOICE.REQUEST,
});

export const sendInvoiceSuccess = () => ({
  type: types.SEND_INVOICE.SUCCESS,
});

export const sendInvoiceFailure = () => ({
  type: types.SEND_INVOICE.FAILURE,
});

export const sendInvoice = (transactionId, supplierTag, locale, creditCardResponse) => async dispatch => {
  try {
    dispatch(sendInvoiceAction());
    const { code } = await xVoucherSal.sendInvoice(transactionId, supplierTag, locale, creditCardResponse);
    if (code === 0) {
      dispatch(sendInvoiceSuccess());
    } else {
      dispatch(sendInvoiceFailure());
      toast(intl.formatMessage({ id: 'invoice.errorSendingEmail' }), { type: toast.TYPE.ERROR });
    }
  } catch (error) {
    dispatch(sendInvoiceFailure());
    toast(intl.formatMessage({ id: 'invoice.errorSendingEmail' }), { type: toast.TYPE.ERROR });
  }
};
