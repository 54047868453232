import es from './es';
import en from './en';
import ja from './ja';
import de from './de';
import pt from './pt';
import ko from './ko';
import zh from './zh';
import it from './it';
import fr from './fr';
import el from './el';

export default { en, es, ja, de, pt, ko, zh, it, fr, el };
