import React from 'react';
import { useIntl } from 'react-intl';
import '../../styles/components/cobranding.scss';

const Cobranding = () => {
  const intl = useIntl();

  return (
    <div className="cobranding-wrapper">
      <p>{intl.formatMessage({ id: 'global.AWS_Cobranding' })}</p>
    </div>
  );
};

export default Cobranding;
