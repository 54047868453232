export default {
  'billing.address': 'Indirizzo',
  'billing.addressConfirmation': "Conferma dell'indirizzo",
  'billing.addressConfirmationDescription':
    "In base all'indirizzo originale inserito è stato trovato un indirizzo convalidato. Selezionare Conferma per accettare l'indirizzo aggiornato o Annulla per effettuare personalmente delle modifiche.",
  'billing.billing': 'Fatturazione',
  'billing.billingInformation': 'Informazioni sulla Fatturazione',
  'billing.cancelAddressConfirmation': 'Annulla',
  'billing.caState': 'Provincia',
  'billing.city': 'Città',
  'billing.confirmAddress': 'Conferma',
  'billing.confirmEmail': "Conferma l'e-mail",
  'billing.country': 'Paese',
  'billing.edit': 'Modifica',
  'billing.email': 'E-mail',
  'billing.errorObtainingCountries':
    "Si è verificato un errore durante l'ottenimento dell'elenco dei Paesi disponibili",
  'billing.errorValidatingAddress': 'Indirizzo di fatturazione non valido',
  'billing.firstName': 'Nome',
  'billing.knownState': 'Stato',
  'billing.lastName': 'Cognome',
  'billing.loadingCountries': 'Caricamento dei Paesi in corso...',
  'billing.memo': 'Promemoria/Numero ordine di acquisto',
  'billing.organizationName': "Nome dell'organizzazione",
  'billing.originalAddress': 'Indirizzo Originale',
  'billing.postalCode': 'Codice Postale',
  'billing.save': 'Salva',
  'billing.state': 'Stato/Provincia/Regione',
  'billing.taxId': 'Codice fiscale',
  'billing.useBillingInformation': 'Utilizza le Informazioni di Fatturazione',
  'billing.usState': 'Stato',
  'billing.validatedAddress': 'Indirizzo Convalidato',
  'billing.VATTaxID': 'Codice fiscale',
  'cardBrand.amex': 'American Express',
  'cardBrand.cartes_bancaires': 'Cartes Bancaires',
  'cardBrand.diners': "Diner's Club",
  'cardBrand.discover': 'Discover',
  'cardBrand.jcb': 'JCB',
  'cardBrand.mastercard': 'Mastercard',
  'cardBrand.unionpay': 'UnionPay',
  'cardBrand.visa': 'Visa',
  'field.invalidEmail': "Inserire un'e-mail valida",
  'field.requiredField': 'Campo obbligatorio',
  'field.unmatchingEmails': 'Gli indirizzi e-mail non corrispondono',
  'global.AWS_Cobranding':
    'Xvoucher è il rivenditore autorizzato di questo programma di formazione Amazon Web Services.',
  'global.backToAWS': 'Torna a AWS',
  'global.backToCart': 'Indietro',
  'global.change': 'Cambia',
  'global.contactSupport': 'Contatta il Supporto',
  'global.defaultSelectOption': "Selezionare un'opzione",
  'global.expiredSession': 'La sessione è scaduta',
  'global.expiringSession': 'La sessione scadrà presto',
  'global.missingPaymentTransaction': 'Transazione di pagamento non trovata',
  'global.more': 'altro',
  'global.allRightsReserved': 'tutti i diritti riservati',
  'global.pageNotFound': 'La pagina che stavi cercando non è stata trovata',
  'global.poweredBy': 'Powered by',
  'global.noRemainingAttempts' : 'Il tentativo di pagamento è fallito più volte. Verrai reindirizzato indietro.',
  'global.rightsReserved': 'Tutti i Diritti Riservati',
  'global.support': 'Supporto',
  'global.terms': 'Termini',
  'global.unauthorizedAccess': 'Non sei autorizzato ad accedere a questa pagina',
  'invoice.email': 'E-mail',
  'invoice.errorOnObtainingInvoice': 'Si è verificato un errore durante la visualizzazione della fattura',
  'invoice.errorSendingEmail': "Si è verificato un errore durante l'invio della fattura",
  'invoice.invoiceSent': 'Fattura inviata',
  'invoice.mailPlaceholder': 'e.g. "john@doe.com; jane@doe.com',
  'invoice.noOrder': 'Nessun ordine da visualizzare',
  'invoice.print': 'Stampa',
  'invoice.send': 'Invia',
  'invoice.sessionExpired': 'La sessione di fatturazione è scaduta',
  'invoice.wrongMailFormat':
    'Si possono usare fino a 10 indirizzi e-mail validi separati da un punto e virgola. Ad esempio "john@doe.com; jane@doe.com"',
  'order.errorGettingOrder': "Si è verificato un errore nel cercare di ottenere l'ordine",
  'order.errorUpdatingOrder': "Si è verificato un errore nel cercare di aggiornare l'ordine",
  'order.invalidToken': 'La transazione non è valida',
  'order.locationNotApproved': "Purtroppo questo corso non è disponibile per l'acquisto nella tua zona",
  'order.moreDetailsAvailable': 'Maggiori dettagli',
  'order.noOrder': "Nessun dettaglio dell'ordine da visualizzare",
  'order.noToken': 'Non è stata trovata alcuna informazione sulla transazione',
  'order.subtotal': 'Subtotale',
  'order.title': "Dettagli Dell'ordine",
  'orderItem.ends': 'Finisci',
  'orderItem.itemLineDiscount': 'Sconto sulla Linea di Articoli',
  'orderItem.location': 'Posizione',
  'orderItem.productDiscount': 'Sconto sui Prodotti',
  'orderItem.starts': 'Inizia',
  'orderSummary.cancel': 'Annulla',
  'orderSummary.cardTypeSelectionLabel': 'Selezionare un tipo di carta di credito',
  'orderSummary.chargedAmountLabel': 'La somma verrà addebitata sulla tua carta',
  'orderSummary.conversionReason': 'Perché devo pagare in USD',
  'orderSummary.currencyChangeExplanation': 'Per effettuare un pagamento in {0}, selezionare Visa o MasterCard.',
  'orderSummary.discount': 'Sconto',
  'orderSummary.estimatedTax': 'Imposte',
  'orderSummary.fee': 'Commissione',
  'orderSummary.noOrder': "Nessun riepilogo dell'ordine da visualizzare",
  'orderSummary.orderDiscount': "Sconto sull'ordine",
  'orderSummary.pay': 'Paga',
  'orderSummary.productDiscount': 'Sconto sui Prodotti',
  'orderSummary.productSubTotal': 'Subtotale dei Prodotti',
  'orderSummary.productTotal': 'Totale dei Prodotti',
  'orderSummary.title': "Riepilogo dell'ordine",
  'orderSummary.total': 'Totale',
  'orderSummary.totalBeforeTax': 'Totale al Netto Delle Imposte',
  'payment.cancelledPayment': 'Il pagamento è stato annullato',
  'payment.confirmingTransaction': 'Conferma della transazione in corso...',
  'payment.convergeFailure': 'Si è verificato un problema nel tentare di inizializzare il pagamento',
  'payment.errorObtainingRedirectURL': "Si è verificato un errore nel tentare di ottenere l'URL di reindirizzamento",
  'payment.errorProcessingTransaction': "Si è verificato un errore durante l'elaborazione della transazione",
  'payment.fraudSuspectionError':
    'Per la tua sicurezza, il tentativo di pagamento non può essere presentato e i dettagli sono stati inoltrati al nostro team che si occupa di sicurezza. Se credi di aver ricevuto questo messaggio per errore, contatta il team di supporto <a href="{0}">Xvoucher</a>.',
  'payment.incompatibleCardForCurrency':
    'I pagamenti in valuta diversa dal dollaro devono essere effettuati con Visa o MasterCard',
  'payment.initFailure': 'Si è verificato un problema nel tentare di inizializzare il pagamento',
  'payment.postalCodeRequired': 'Per questa transazione è necessario un codice postale',
  'payment.transactionApproved': 'La transazione con carta di credito è stata approvata',
  'payment.transactionCancelled': 'La transazione con carta di credito è stata annullata',
  'payment.transactionDeclined': 'La transazione è stata rifiutata',
  'payment.transactionDeclinedCardDetails': 'I dati della tua carta non sono corretti',
  'payment.transactionError': 'La transazione con carta di credito ha presentato un errore',
  'payment.transactionProcessing': "L'elaborazione della transazione con carta di credito è in corso",
  'payment.transactionRequiresAction': 'La transazione con carta di credito richiede un intervento',
  'payment.transactionRequiresCapture': 'La transazione con carta di credito richiede la cattura',
  'payment.transactionRequiresConfirmation': 'La transazione con carta di credito richiede una conferma',
  'payment.transactionRequiresPaymentMethod': 'La transazione con carta di credito richiede un metodo di pagamento',
  'payment.unsupportedCardOfferCurrencyConversion':
    '{0} le carte non sono supportate per i pagamenti in {1}. Utilizzare un tipo di carta diverso o pagare in {2}.',
  'pendo.initializationError': 'Si è verificato un errore nel tentare di inizializzare Pendo: {0}',
  'pendo.updateError': 'Si è verificato un errore nel tentare di aggiornare Pendo: {0}',
  'test.insertToken': 'Inserire qui il token di test',
  'country.US': 'Stati Uniti',
  'country.CA': 'Canada',
  'country.AF': 'Afghanistan',
  'country.AL': 'Albania',
  'country.DZ': 'Algeria',
  'country.AO': 'Angola',
  'country.AR': 'Argentina',
  'country.AT': 'Austria',
  'country.AU': 'Australia',
  'country.BE': 'Belgio',
  'country.BH': 'Bahrain',
  'country.BR': 'Brasile',
  'country.BG': 'Bulgaria',
  'country.CM': 'Camerun',
  'country.CV': 'Capo Verde',
  'country.CL': 'Cile',
  'country.CN': 'Cina',
  'country.CO': 'Colombia',
  'country.CZ': 'Repubblica Ceca',
  'country.DK': 'Danimarca',
  'country.EG': 'Egitto',
  'country.ET': 'Etiopia',
  'country.EE': 'Estonia',
  'country.FI': 'Finlandia',
  'country.FR': 'Francia',
  'country.GA': 'Gabon',
  'country.GM': 'Gambia',
  'country.GH': 'Ghana',
  'country.DE': 'Germania',
  'country.GR': 'Grecia',
  'country.HK': 'Hong Kong',
  'country.HU': 'Ungheria',
  'country.IN': 'India',
  'country.ID': 'Indonesia',
  'country.IQ': 'Iraq',
  'country.IE': 'Irlanda',
  'country.IL': 'Israele',
  'country.IT': 'Italia',
  'country.JP': 'Giappone',
  'country.JO': 'Giordania',
  'country.KE': 'Kenya',
  'country.KP': 'Corea (Repubblica Popolare Democratica di)',
  'country.KW': 'Kuwait',
  'country.LV': 'Lettonia',
  'country.LB': 'Libano',
  'country.LR': 'Liberia',
  'country.LT': 'Lituania',
  'country.LU': 'Lussemburgo',
  'country.MK': 'Macedonia del Nord',
  'country.MG': 'Madagascar',
  'country.MY': 'Malaysia',
  'country.MA': 'Marocco',
  'country.MX': 'Messico',
  'country.NL': 'Paesi Bassi',
  'country.NG': 'Nigeria',
  'country.NO': 'Norvegia',
  'country.OM': 'Oman',
  'country.PK': 'Pakistan',
  'country.PE': 'Perù',
  'country.PH': 'Filippine',
  'country.PL': 'Polonia',
  'country.PT': 'Portogallo',
  'country.PR': 'Portorico',
  'country.QA': 'Qatar',
  'country.RO': 'Romania',
  'country.RW': 'Ruanda',
  'country.SA': 'Arabia Saudita',
  'country.SN': 'Senegal',
  'country.RS': 'Serbia',
  'country.SL': 'Sierra Leone',
  'country.SG': 'Singapore',
  'country.SI': 'Slovenia',
  'country.ZA': 'Sudafrica',
  'country.ES': 'Spagna',
  'country.SE': 'Svezia',
  'country.TH': 'Thailandia',
  'country.TN': 'Tunisia',
  'country.TR': 'Turchia',
  'country.UG': 'Uganda',
  'country.UA': 'Ucraina',
  'country.AE': 'Emirati Arabi Uniti',
  'country.GB': 'Regno Unito',
  'country.VE': 'Venezuela (Repubblica Bolivariana del)',
  'country.YE': 'Yemen',
  'country.ZM': 'Zambia',
  'country.AM': 'Armenia',
  'country.CR': 'Costa Rica',
  'country.DO': 'Repubblica Dominicana',
  'country.LK': 'Sri Lanka',
  'country.CH': 'Svizzera',
  'country.JM': 'Giamaica',
  'country.KZ': 'Kazakistan',
  'country.NZ': 'Nuova Zelanda',
  'country.NP': 'Nepal',
  'country.ZW': 'Zimbabwe',
  'country.EC': 'Ecuador',
  'country.GT': 'Guatemala',
  'country.BD': 'Bangladesh',
  'country.UY': 'Uruguay',
  'country.MN': 'Mongolia',
  'country.KH': 'Cambogia',
  'country.ME': 'Montenegro',
  'country.BW': 'Botswana',
  'country.KG': 'Kirghizistan',
  'country.TJ': 'Tajikistan',
  'country.UZ': 'Uzbekistan',
  'country.AZ': 'Azerbaijan',
  'country.TM': 'Turkmenistan',
  'country.TT': 'Trinidad e Tobago',
  'country.GN': 'Guinea',
  'country.AS': 'Samoa americane',
  'country.AD': 'Andorra',
  'country.AQ': 'Antartide',
  'country.AG': 'Antigua e Barbuda',
  'country.AW': 'Aruba',
  'country.BS': 'Bahamas',
  'country.BB': 'Barbados',
  'country.BM': 'Bermuda',
  'country.BY': 'Bielorussia',
  'country.BZ': 'Belize',
  'country.BJ': 'Benin',
  'country.BT': 'Bhutan',
  'country.BO': 'Bolivia (Stato Plurinazionale della)',
  'country.BA': 'Bosnia ed Erzegovina',
  'country.BV': 'Isola Bouvet',
  'country.GU': 'Guam',
  'country.GY': 'Guyana',
  'country.HT': 'Haiti',
  'country.HM': 'Isola Heard e Isole McDonald',
  'country.HN': 'Honduras',
  'country.IS': 'Islanda',
  'country.KI': 'Kiribati',
  'country.KR': 'Corea (Repubblica di)',
  'country.LA': 'Laos (Repubblica Democratica Popolare del)',
  'country.WS': 'Samoa',
  'country.SM': 'San Marino',
  'country.ST': 'Sao Tome e Principe',
  'country.SC': 'Seychelles',
  'country.SK': 'Slovacchia',
  'country.SB': 'Isole Salomone',
  'country.SO': 'Somalia',
  'country.GS': 'Georgia del Sud e Isole Sandwich Australi',
  'country.SH': "Sant'Elena, Ascensione, Tristan da Cunha",
  'country.PM': 'Saint Pierre e Miquelon',
  'country.SR': 'Suriname',
  'country.SJ': 'Svalbard e Jan Mayen',
  'country.IO': "Territorio britannico dell'Oceano Indiano",
  'country.BN': 'Brunei Darussalam',
  'country.BF': 'Burkina Faso',
  'country.BI': 'Burundi',
  'country.KY': 'Isole Cayman',
  'country.CF': 'Repubblica Centrafricana',
  'country.TD': 'Chad',
  'country.CX': 'Isola di Natale',
  'country.CC': 'Isole Cocos (Keeling)',
  'country.KM': 'Comore',
  'country.CG': 'Congo',
  'country.CK': 'Isole Cook',
  'country.CI': "Costa d'Avorio",
  'country.HR': 'Croazia',
  'country.CY': 'Cipro',
  'country.DJ': 'Gibuti',
  'country.LS': 'Lesotho',
  'country.LY': 'Libia',
  'country.LI': 'Liechtenstein',
  'country.MO': 'Macao',
  'country.MW': 'Malawi',
  'country.MV': 'Maldive',
  'country.ML': 'Mali',
  'country.MT': 'Malta',
  'country.MH': 'Isole Marshall',
  'country.MQ': 'Martinica',
  'country.MR': 'Mauritania',
  'country.MU': 'Mauritius',
  'country.YT': 'Mayotte',
  'country.FM': 'Micronesia (Stati Federati della)',
  'country.MD': 'Moldavia (Repubblica di)',
  'country.MC': 'Monaco',
  'country.MS': 'Montserrat',
  'country.MZ': 'Mozambico',
  'country.MM': 'Myanmar',
  'country.NA': 'Namibia',
  'country.NR': 'Nauru',
  'country.SZ': 'Eswatini',
  'country.TW': 'Taiwan (Provincia della Cina)',
  'country.TZ': 'Tanzania (Repubblica Unita della)',
  'country.TG': 'Togo',
  'country.TK': 'Tokelau',
  'country.TO': 'Tonga',
  'country.TC': 'Isole Turks e Caicos',
  'country.TV': 'Tuvalu',
  'country.DM': 'Dominica',
  'country.TL': 'Timor-Leste',
  'country.SV': 'El Salvador',
  'country.GQ': 'Guinea Equatoriale',
  'country.ER': 'Eritrea',
  'country.FK': 'Isole Falkland (Malvinas)',
  'country.FO': 'Isole Faroe',
  'country.FJ': 'Fiji',
  'country.GF': 'Guiana francese',
  'country.PF': 'Polinesia francese',
  'country.TF': 'Territori francesi meridionali',
  'country.GE': 'Georgia',
  'country.GD': 'Grenada',
  'country.GL': 'Groenlandia',
  'country.GP': 'Guadalupa',
  'country.NC': 'Nuova Caledonia',
  'country.NI': 'Nicaragua',
  'country.NE': 'Niger',
  'country.NU': 'Niue',
  'country.NF': 'Isola Norfolk',
  'country.MP': 'Isole Marianne Settentrionali',
  'country.PW': 'Palau',
  'country.PA': 'Panama',
  'country.PG': 'Papua Nuova Guinea',
  'country.PY': 'Paraguay',
  'country.PN': 'Pitcairn',
  'country.RE': 'Reunion',
  'country.RU': 'Federazione Russa',
  'country.KN': 'San Kitts e Nevis',
  'country.LC': 'Santa Lucia',
  'country.VC': 'Saint Vincent e Grenadine',
  'country.UM': 'Isole minori esterne degli Stati Uniti',
  'country.VU': 'Vanuatu',
  'country.VA': 'Santa Sede',
  'country.VN': 'Vietnam',
  'country.VG': 'Isole Vergini (britanniche)',
  'country.VI': 'Isole Vergini (statunitensi)',
  'country.WF': 'Wallis e Futuna',
  'country.EH': 'Sahara occidentale',
  'country.GW': 'Guinea-Bissau',
  'country.GI': 'Gibilterra',
  'country.SS': 'Sudan del Sud',
  'country.CW': 'Curacao',
  'country.AX': 'Isole Åland',
  'country.AI': 'Anguilla',
  'country.CD': 'Congo (Repubblica Democratica del)',
  'country.CU': 'Cuba',
  'country.GG': 'Guernsey',
  'country.IR': "Iran (Repubblica Islamica dell')",
  'country.IM': 'Isola di Man',
  'country.JE': 'Jersey',
  'country.PS': 'Palestina, Stato di',
  'country.MF': 'San Martino (parte francese)',
  'country.SX': 'Sint Maarten (parte olandese)',
  'country.SD': 'Sudan',
  'country.SY': 'Siria (Repubblica Araba di)',
  'country.BQ': 'Bonaire, Sint Eustatius e Saba',
  'country.BL': 'San Bartolomeo',
  'state.AL': 'Alabama',
  'state.AK': 'Alaska',
  'state.AZ': 'Arizona',
  'state.AR': 'Arkansas',
  'state.CA': 'California',
  'state.CO': 'Colorado',
  'state.CT': 'Connecticut',
  'state.DE': 'Delaware',
  'state.FL': 'Florida',
  'state.GA': 'Georgia',
  'state.HI': 'Hawaii',
  'state.ID': 'Idaho',
  'state.IL': 'Illinois',
  'state.IN': 'Indiana',
  'state.IA': 'Iowa',
  'state.KS': 'Kansas',
  'state.KY': 'Kentucky',
  'state.LA': 'Louisiana',
  'state.ME': 'Maine',
  'state.MD': 'Maryland',
  'state.MA': 'Massachusetts',
  'state.MI': 'Michigan',
  'state.MN': 'Minnesota',
  'state.MS': 'Mississippi',
  'state.MO': 'Missouri',
  'state.MT': 'Montana',
  'state.NE': 'Nebraska',
  'state.NV': 'Nevada',
  'state.NH': 'New Hampshire',
  'state.NJ': 'New Jersey',
  'state.NM': 'New Mexico',
  'state.NY': 'New York',
  'state.NC': 'North Carolina',
  'state.ND': 'North Dakota',
  'state.OH': 'Ohio',
  'state.OK': 'Oklahoma',
  'state.OR': 'Oregon',
  'state.PA': 'Pennsylvania',
  'state.RI': 'Rhode Island',
  'state.SC': 'South Carolina',
  'state.SD': 'South Dakota',
  'state.TN': 'Tennessee',
  'state.TX': 'Texas',
  'state.UT': 'Utah',
  'state.VT': 'Vermont',
  'state.VA': 'Virginia',
  'state.WA': 'Washington',
  'state.WV': 'West Virginia',
  'state.WI': 'Wisconsin',
  'state.WY': 'Wyoming',
  'state.DC': 'Distretto di Columbia',
  'state.AS': 'Samoa americane',
  'state.FM': 'Stati Federati di Micronesia',
  'state.GU': 'Guam',
  'state.MH': 'Isole Marshall',
  'state.MP': 'Isole Marianne Settentrionali',
  'state.PW': 'Palau',
  'state.PR': 'Portorico',
  'state.VI': 'Isole Vergini',
  'state.AE': 'Forze armate Europa, Medio Oriente e Canada',
  'state.AP': 'Forze armate Pacifico',
  'state.AA': 'Forze armate Americhe (eccetto Canada)',
  'province.AB': 'Alberta',
  'province.BC': 'Columbia Britannica',
  'province.MB': 'Manitoba',
  'province.NB': 'New Brunswick',
  'province.NL': 'Terranova e Labrador',
  'province.NT': 'Territori del Nord-Ovest',
  'province.NS': 'Nuova Scozia',
  'province.NU': 'Nunavut',
  'province.ON': 'Ontario',
  'province.PE': 'Isola del Principe Edoardo',
  'province.QC': 'Quebec',
  'province.SK': 'Saskatchewan',
  'province.YT': 'Yukon',
  'state.AbuDhabi': 'Abu Dhabi',
  'state.Ajman': 'Ajman',
  'state.Dubai': 'Dubai',
  'state.Fujairah': 'Fujairah',
  'state.RasAlKhaimah': 'Ras Al Khaimah',
  'state.Sharjah': 'Sharjah',
  'state.UmmAlQuwain': 'Umm Al Quwain',
  'billing.aeState': 'Emirato',
  'instate.AN': 'Isole Andamane e Nicobare',
  'instate.AP': 'Andhra Pradesh',
  'instate.AR': 'Arunachal Pradesh',
  'instate.AS': 'Assam',
  'instate.BR': 'Bihar',
  'instate.CH': 'Chandigarh',
  'instate.CT': 'Chattisgarh',
  'instate.DN': 'Dadra e Nagar Haveli',
  'instate.DD': 'Daman e Diu',
  'instate.DL': 'Delhi',
  'instate.GA': 'Goa',
  'instate.GJ': 'Gujarat',
  'instate.HR': 'Haryana',
  'instate.HP': 'Himachal Pradesh',
  'instate.JK': 'Jammu e Kashmir',
  'instate.JH': 'Jharkhand',
  'instate.KA': 'Karnataka',
  'instate.KL': 'Kerala',
  'instate.LD': 'Lakshadweep',
  'instate.MP': 'Madhya Pradesh',
  'instate.MH': 'Maharashtra',
  'instate.MN': 'Manipur',
  'instate.ML': 'Meghalaya',
  'instate.MZ': 'Mizoram',
  'instate.NL': 'Nagaland',
  'instate.OR': 'Odisha',
  'instate.PY': 'Puducherry',
  'instate.PB': 'Punjab',
  'instate.RJ': 'Rajasthan',
  'instate.SK': 'Sikkim',
  'instate.TN': 'Tamil Nadu',
  'instate.TG': 'Telangana',
  'instate.TR': 'Tripura',
  'instate.UP': 'Uttar Pradesh',
  'instate.UT': 'Uttarakhand',
  'instate.WB': 'Bengala occidentale',
  'billing.inState': 'Stato',
};
