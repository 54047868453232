import React from 'react';
import { object, func, oneOfType, bool, string } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import routes from '../../constants/routesPaths';

const PrivateRoute = ({ component: Component, exact = false, path, authenticated }) => (
  <Route
    exact={exact}
    path={path}
    render={props =>
      (authenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: routes.login,
            state: { from: props.location },
          }}
        />
      ))
    }
  />
);

PrivateRoute.propTypes = {
  component: oneOfType([object, func]).isRequired,
  path: string.isRequired,
  authenticated: bool.isRequired,
  exact: bool,
  location: object,
};

export default PrivateRoute;
