import React, { Fragment } from 'react';
import ContentLoader from 'react-content-loader';

const BillingInformationLoader = () => {
  const [lineCount, lineSeparation, lineHeight, labelOffset] = [5, 40, 16, 2];

  return (
    <ContentLoader
      style={{ marginTop: '0.6rem' }}
      height={(lineCount + 3) * lineHeight * 2}
      width="100%"
      backgroundColor="#eaeaea"
      foregroundColor="#f4f4f4"
    >
      <rect x="0" y="0" rx="3" ry="3" width="250" height="20" />
      {[...Array(lineCount)].map((_, index) => (
        <Fragment key={index}>
          <rect
            x="0"
            y={(index + 1) * lineSeparation + labelOffset}
            rx="3"
            ry="3"
            width="80"
            height={lineHeight - labelOffset * 2}
          />
          <rect x="30%" y={(index + 1) * lineSeparation} rx="3" ry="3" width="70%" height={lineHeight} />
        </Fragment>
      ))}
      <rect x="35%" y={(lineCount + 1) * lineHeight * 2 + lineSeparation * 1.2} rx="3" ry="3" width="30%" height="20" />
    </ContentLoader>
  );
};

export default BillingInformationLoader;
