import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { func, bool, string } from 'prop-types';
import cn from 'classnames';
import SlideButton from '../common/inputs/SlideButton';
import '../../styles/components/stripe/stripe-elements.scss';

const CheckoutForm = ({
  handleStripeChange,
  paymentFunction,
  isButtonDisabled,
  isButtonLoading,
  buttonMessage,
  shouldConvertCurrency,
  buttonMessageConvertedCurrency,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event => {
    event.preventDefault();
    const card = elements.getElement(CardElement);
    paymentFunction(stripe, card);
  };

  const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        fontSize: '14px',
        color: 'black',
        fontFamily: "'Roboto', sans-serif",
        letterSpacing: '0.025em',
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };

  return (
    <form className="stripe-form" onSubmit={handleSubmit}>
      <fieldset className="stripe-form-group">
        <div className="stripe-form-row">
          <CardElement options={CARD_OPTIONS} onChange={handleStripeChange} />
        </div>
      </fieldset>
      <SlideButton
        id="PaymentButton"
        className={cn('primary-button', 'full-size', 'margin-top', 'success', 'slide-button')}
        disabled={isButtonDisabled}
        loading={isButtonLoading}
        leftText={buttonMessage}
        rightText={buttonMessageConvertedCurrency}
        showLeft={shouldConvertCurrency}
      />
    </form>
  );
};

CheckoutForm.propTypes = {
  handleStripeChange: func.isRequired,
  paymentFunction: func,
  isButtonDisabled: bool,
  isButtonLoading: bool,
  buttonMessage: string,
  shouldConvertCurrency: bool,
  buttonMessageConvertedCurrency: string,
};

export default CheckoutForm;
