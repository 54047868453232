import React from 'react';
import { string, oneOfType, number, func, bool, array } from 'prop-types';
import cn from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/TextField';
import '../../../styles/components/common/inputs/form-field.scss';

const FormDropdown = ({
  defaultMessage,
  label,
  name,
  onChange,
  value,
  error,
  onBlur,
  dropdownOptions,
  loading,
  fullWidth,
  disabled,
  elementId,
  required,
  autoComplete,
}) => {
  // eslint-disable-next-line no-nested-ternary
  const labelText = loading ? 'Loading...' : label;
  return (
    <div className={cn('form-field-input-container', { 'full-width': fullWidth })}>
      <Select
        autoComplete={autoComplete}
        id={elementId}
        name={name}
        value={loading || !dropdownOptions || dropdownOptions.length <= 0 ? '' : value}
        onBlur={onBlur}
        className={cn({ 'input-form-error': error })}
        select
        label={labelText}
        onChange={evt => {
          onChange(
            evt,
            dropdownOptions.find(({ value: optionValue }) => optionValue === evt.target.value)
          );
        }}
        disabled={disabled || loading || !dropdownOptions || dropdownOptions.length <= 1}
        fullWidth
        error={!!error}
        helperText={error}
        required={required}
      >
        {defaultMessage && <MenuItem value="">{defaultMessage}</MenuItem>}
        {dropdownOptions.map(({ label, value, secondLabel }) => (
          <MenuItem key={value} value={value}>
            {secondLabel ? (
              <div className="d-flex justify-between fullWidth">
                <div>{label}</div>
                <div>{secondLabel}</div>
              </div>
            ) : (
              `${label}`
            )}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
FormDropdown.propTypes = {
  defaultMessage: string,
  label: string,
  name: string.isRequired,
  onChange: func.isRequired,
  onBlur: func,
  value: oneOfType([number, string]),
  disabled: bool,
  error: oneOfType([bool, string]),
  dropdownOptions: array,
  loading: bool,
  fullWidth: bool,
  elementId: string,
  required: bool,
  autoComplete: string,
};
export default FormDropdown;
