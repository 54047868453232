import React from 'react';
import { bool, func, oneOfType, node, element, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import mainLogo from '../../assets/logo_main.svg';
import Cobranding from './Cobranding';

const ModalWrapper = ({ supplierTag, handleCancel, backEnabled, children }) => (
  <div className="main-component-container">
    <div className="main-component-modal-container">
      {handleCancel && (
        <button id="CancelButton" className="cancel-button" onClick={handleCancel} disabled={!backEnabled} aria-label="cancel">
          <FontAwesomeIcon icon={faArrowLeft} />
          <FormattedMessage id={supplierTag?.includes('kiku') ? 'global.backToAWS' : 'global.backToCart'} />
        </button>
      )}
      <div className="visual-modal-container">
        <div className="logo-container">
          <img src={mainLogo} alt="" />
        </div>
        {children}
      </div>
    </div>
    {supplierTag?.includes('kiku') && <Cobranding />}
  </div>
);

ModalWrapper.propTypes = {
  supplierTag: string,
  handleCancel: func,
  backEnabled: bool,
  children: oneOfType([node, element]).isRequired,
};

export default ModalWrapper;
