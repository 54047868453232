import React from 'react';
import AnimationLine from '../components/AnimationLine';
import MainComponent from '../components/MainComponent';

import '../styles/pages/main-page.scss';

const MainPage = () => (
  <div className="xv-pay-container">
    <div className="animation-line">
      <AnimationLine />
    </div>
    <div className="main-page-modal-container">
      <MainComponent />
    </div>
  </div>
);

export default MainPage;
