import { produce } from 'immer';
import * as types from '../actions/actionTypes';
import createReducer from './utils/createReducer';

const initialState = {
  orderErrorMessage: '',
  billingErrorMessage: '',
  paymentErrorMessage: '',
};

const actionHandlers = {
  [types.SET_ORDER_ERROR_MESSAGE]: produce((draft, { orderErrorMessage }) => {
    draft.orderErrorMessage = orderErrorMessage;
  }),
  [types.DELETE_ORDER_ERROR_MESSAGE]: produce(draft => {
    draft.orderErrorMessage = '';
  }),
  [types.SET_BILLING_ERROR_MESSAGE]: produce((draft, { billingErrorMessage }) => {
    draft.billingErrorMessage = billingErrorMessage;
  }),
  [types.DELETE_BILLING_ERROR_MESSAGE]: produce(draft => {
    draft.billingErrorMessage = '';
  }),
  [types.SET_PAYMENT_ERROR_MESSAGE]: produce((draft, { paymentErrorMessage }) => {
    draft.paymentErrorMessage = paymentErrorMessage;
  }),
  [types.DELETE_PAYMENT_ERROR_MESSAGE]: produce(draft => {
    draft.paymentErrorMessage = '';
  }),
};

export default createReducer(initialState, actionHandlers);
