// LANGUAGES

export const DEFAULT_LANGUAGE = 'en';
export const SPANISH_LANGUAGE = 'es';
export const PORTUGUESE_LANGUAGE = 'pt';
export const JAPANESE_LANGUAGE = 'ja';
export const GERMAN_LANGUAGE = 'de';

export const SUPPORTED_LANGUAGES = [
  DEFAULT_LANGUAGE,
  SPANISH_LANGUAGE,
  PORTUGUESE_LANGUAGE,
  JAPANESE_LANGUAGE,
  GERMAN_LANGUAGE,
];

// ERRORS

export const ERROR_MESSAGE_SPAWN_TIME = 10000;
export const ORDER_ERROR_KEY = 'orderErrorMessage';
export const BILLING_ERROR_KEY = 'billingErrorMessage';
export const PAYMENT_ERROR_KEY = 'paymentErrorMessage';
export const PENDO_DEFAULT_VISITOR_ID = 'VISITOR-UNIQUE-ID';

