import routesPaths from './constants/routesPaths';

import MainPage from './pages/MainPage';
import InvoicePage from './pages/InvoicePage';
import PostTestPage from './pages/PostTestPage';
import NotFoundPage from './pages/NotFoundPage';

const routes = [
  {
    path: routesPaths.test,
    component: PostTestPage,
    exact: true,
    private: false,
  },
  {
    path: routesPaths.index,
    component: MainPage,
    exact: true,
    private: false,
  },
  {
    path: routesPaths.invoice,
    component: InvoicePage,
    exact: true,
    private: false,
  },
  {
    component: NotFoundPage,
  },
];

export default routes;
