export default {
  'billing.address': '住所',
  'billing.addressConfirmation': '住所確認',
  'billing.addressConfirmationDescription':
    '入力されていた元の住所に基づいて、検証された住所が見つかりました。[確認] を選択して、更新された住所を承認してください。または、[キャンセル] を選択して、ご自身で変更を加えてください。',
  'billing.billing': '請求',
  'billing.billingInformation': '支払い情報',
  'billing.cancelAddressConfirmation': 'キャンセル',
  'billing.caState': '都道府県',
  'billing.city': '市区町村',
  'billing.confirmAddress': '確認',
  'billing.confirmEmail': 'Eメールの確認',
  'billing.country': '国',
  'billing.edit': '編集',
  'billing.email': 'Eメール',
  'billing.errorObtainingCountries': '利用可能な国のリストの取得中にエラーが発生しました',
  'billing.errorValidatingAddress': '無効な請求先住所',
  'billing.firstName': '名',
  'billing.knownState': '都道府県',
  'billing.lastName': '姓',
  'billing.loadingCountries': '国を読み込んでいます...',
  'billing.memo': 'メモ/発注番',
  'billing.organizationName': '組織名',
  'billing.originalAddress': '元の住所',
  'billing.postalCode': '郵便番号',
  'billing.save': 'セーブ',
  'billing.state': '都道府県/地域',
  'billing.taxId': '税金ID',
  'billing.useBillingInformation': '支払い情報を使用する',
  'billing.usState': '都道府県',
  'billing.validatedAddress': '検証された住所',
  'billing.VATTaxID': '税金ID',
  'cardBrand.amex': 'American Express',
  'cardBrand.cartes_bancaires': 'Cartes Bancaires',
  'cardBrand.diners': "Diner's Club",
  'cardBrand.discover': 'Discover',
  'cardBrand.jcb': 'JCB',
  'cardBrand.mastercard': 'Mastercard',
  'cardBrand.unionpay': 'UnionPay',
  'cardBrand.visa': 'Visa',
  'field.invalidEmail': '正しいメールアドレスを入力してください',
  'field.requiredField': 'この項目は必須です',
  'field.unmatchingEmails': 'Eメールが一致しません',
  'global.AWS_Cobranding': 'Xvoucherは、このアマゾンウェブサービストレーニングプログラムの認定再販業者です。',
  'global.backToAWS': 'AWSに戻る',
  'global.backToCart': 'バック',
  'global.change': '変更',
  'global.contactSupport': 'サポートに問い合わせ',
  'global.defaultSelectOption': 'オプションを1つ選択してください',
  'global.expiredSession': 'セッションの期限が切れました',
  'global.expiringSession': 'セッションはまもなく期限切れになります',
  'global.missingPaymentTransaction': '支払取引は見つかりません',
  'global.more': 'もっと',
  'global.allRightsReserved': 'all rights reserved',
  'global.pageNotFound': 'お探しのページは見つかりませんでした',
  'global.poweredBy': '搭載',
  'global.noRemainingAttempts' : '支払いの試行が複数回失敗しました。 リダイレクトされて戻ります。',
  'global.rightsReserved': 'All Rights Reserved',
  'global.support': 'サポート',
  'global.terms': '条項',
  'global.unauthorizedAccess': 'このページにアクセスする権限がありません',
  'invoice.email': 'Eメール',
  'invoice.errorOnObtainingInvoice': '請求書の表示中にエラーが発生しました',
  'invoice.errorSendingEmail': '請求書の送信中にエラーが発生しました',
  'invoice.invoiceSent': '請求書が送信されました',
  'invoice.mailPlaceholder': 'e.g. "john@doe.com; jane@doe.com',
  'invoice.noOrder': '表示できる注文はありません',
  'invoice.print': '印刷',
  'invoice.send': '送信',
  'invoice.sessionExpired': '請求書セッションの有効期限が切れています',
  'invoice.wrongMailFormat':
    'セミコロンで区切られた有効なメールを 10 個まで使用できます。（例：john@doe.com; jane@doe.com）',
  'order.errorGettingOrder': '注文の取得中にエラーが発生しました',
  'order.errorUpdatingOrder': '注文の更新中にエラーが発生しました',
  'order.invalidToken': '取引が無効です',
  'order.locationNotApproved': 'このクラスはお住まいの地域ではご利用いただけません',
  'order.moreDetailsAvailable': '詳細',
  'order.noOrder': '表示できる注文の詳細はありません',
  'order.noToken': '取引情報が見つかりません',
  'order.subtotal': '小計',
  'order.title': '注文の詳細',
  'orderItem.ends': '終了',
  'orderItem.itemLineDiscount': '項目行割引',
  'orderItem.location': '所在地',
  'orderItem.productDiscount': '製品割引',
  'orderItem.starts': '開始',
  'orderSummary.cancel': 'キャンセル',
  'orderSummary.cardTypeSelectionLabel': 'クレジットカードの種類を選択してください',
  'orderSummary.chargedAmountLabel': 'カードに請求されます',
  'orderSummary.conversionReason': 'なぜ米ドルで支払う必要があるのですか',
  'orderSummary.currencyChangeExplanation': '{0}でお支払いいただくには、VisaまたはMasterCardを選択してください。',
  'orderSummary.discount': '割引',
  'orderSummary.estimatedTax': '税金',
  'orderSummary.fee': '手数料',
  'orderSummary.noOrder': '表示できる注文の概要はありません',
  'orderSummary.orderDiscount': '注文割引',
  'orderSummary.pay': '支払い',
  'orderSummary.productDiscount': '製品割引',
  'orderSummary.productSubTotal': '製品小計',
  'orderSummary.productTotal': '製品合計',
  'orderSummary.title': '注文の概要',
  'orderSummary.total': '合計',
  'orderSummary.totalBeforeTax': '税引前合計',
  'payment.cancelledPayment': '支払いはキャンセルされました',
  'payment.confirmingTransaction': '取引を確認しています...',
  'payment.convergeFailure': '支払いの初期化中に問題が発生しました',
  'payment.errorObtainingRedirectURL': 'リダイレクトURLの取得中にエラーが発生しました',
  'payment.errorProcessingTransaction': '取引の処理中にエラーが発生しました',
  'payment.fraudSuspectionError':
    'セキュリティ保護のため、お支払いを送信することはできず、詳細はセキュリティチームに転送されました。このメッセージを誤って受信したと思われる場合は、<a href="{0}">Xvoucher サポート</a> へお問い合わせください。',
  'payment.incompatibleCardForCurrency':
    '米ドル以外の通貨での支払いは、VisaまたはMasterCardを使用して行う必要があります',
  'payment.initFailure': '支払いの初期化中に問題が発生しました',
  'payment.postalCodeRequired': 'この取引には郵便番号が必要です',
  'payment.transactionApproved': 'クレジットカード取引が承認されました',
  'payment.transactionCancelled': '支払いはキャンセルされました',
  'payment.transactionDeclined': '取引は拒否されました',
  'payment.transactionDeclinedCardDetails': 'カードの詳細が正しく入力されていませんでした',
  'payment.transactionError': 'クレジットカード取引でエラーが発生しました',
  'payment.transactionProcessing': '取引の処理中にエラーが発生しました',
  'payment.transactionRequiresAction': 'クレジットカード取引にはアクションが必要です',
  'payment.transactionRequiresCapture': 'クレジットカード取引にはキャプチャが必要です',
  'payment.transactionRequiresConfirmation': 'クレジットカード取引には確認が必要です',
  'payment.transactionRequiresPaymentMethod': 'クレジットカード取引には支払い方法が必要です',
  'payment.unsupportedCardOfferCurrencyConversion':
    '{0}カードでは{1}で支払いをすることができません。別のブランドのカードをご利用いただくか、{2}でお支払いください。',
  'pendo.initializationError': 'Pendoを初期化しようとしたときにエラーが発生しました：{0}',
  'pendo.updateError': 'Pendoを更新しようとしたときにエラーが発生しました：{0}',
  'test.insertToken': 'ここにテストトークンを挿入',
  'country.US': 'アメリカ合衆国',
  'country.CA': 'カナダ',
  'country.AF': 'アフガニスタン',
  'country.AL': 'アルバニア',
  'country.DZ': 'アルジェリア',
  'country.AO': 'アンゴラ',
  'country.AR': 'アルゼンチン',
  'country.AT': 'オーストリア',
  'country.AU': 'オーストラリア',
  'country.BE': 'ベルギー',
  'country.BH': 'バーレーン',
  'country.BR': 'ブラジル',
  'country.BG': 'ブルガリア',
  'country.CM': 'カメルーン',
  'country.CV': 'カーボベルデ',
  'country.CL': 'チリ',
  'country.CN': '中国',
  'country.CO': 'コロンビア',
  'country.CZ': 'チェコ',
  'country.DK': 'デンマーク',
  'country.EG': 'エジプト',
  'country.ET': 'エチオピア',
  'country.EE': 'エストニア',
  'country.FI': 'フィンランド',
  'country.FR': 'フランス',
  'country.GA': 'ガボン',
  'country.GM': 'ガンビア',
  'country.GH': 'ガーナ',
  'country.DE': 'ドイツ',
  'country.GR': 'ギリシャ',
  'country.HK': '香港',
  'country.HU': 'ハンガリー',
  'country.IN': 'インド',
  'country.ID': 'インドネシア',
  'country.IQ': 'イラク',
  'country.IE': 'アイルランド',
  'country.IL': 'イスラエル',
  'country.IT': 'イタリア',
  'country.JP': '日本',
  'country.JO': 'ヨルダン',
  'country.KE': 'ケニア',
  'country.KP': '朝鮮民主主義人民共和国',
  'country.KW': 'クウェート',
  'country.LV': 'ラトビア',
  'country.LB': 'レバノン',
  'country.LR': 'リベリア',
  'country.LT': 'リトアニア',
  'country.LU': 'ルクセンブルク',
  'country.MK': '北マケドニア',
  'country.MG': 'マダガスカル',
  'country.MY': 'マレーシア',
  'country.MA': 'モロッコ',
  'country.MX': 'メキシコ',
  'country.NL': 'オランダ',
  'country.NG': 'ナイジェリア',
  'country.NO': 'ノルウェー',
  'country.OM': 'オマーン',
  'country.PK': 'パキスタン',
  'country.PE': 'ペルー',
  'country.PH': 'フィリピン',
  'country.PL': 'ポーランド',
  'country.PT': 'ポルトガル',
  'country.PR': 'プエルトリコ',
  'country.QA': 'カタール',
  'country.RO': 'ルーマニア',
  'country.RW': 'ルワンダ',
  'country.SA': 'サウジアラビア',
  'country.SN': 'セネガル',
  'country.RS': 'セルビア',
  'country.SL': 'シエラレオネ',
  'country.SG': 'シンガポール',
  'country.SI': 'スロベニア',
  'country.ZA': '南アフリカ共和国',
  'country.ES': 'スペイン',
  'country.SE': 'スウェーデン',
  'country.TH': 'タイ',
  'country.TN': 'チュニジア',
  'country.TR': 'トルコ',
  'country.UG': 'ウガンダ',
  'country.UA': 'ウクライナ',
  'country.AE': 'アラブ首長国連邦',
  'country.GB': 'イギリス',
  'country.VE': 'ベネズエラ',
  'country.YE': 'イエメン',
  'country.ZM': 'ザンビア',
  'country.AM': 'アルメニア',
  'country.CR': 'コスタリカ',
  'country.DO': 'ドミニカ共和国',
  'country.LK': 'スリランカ',
  'country.CH': 'スイス',
  'country.JM': 'ジャマイカ',
  'country.KZ': 'カザフスタン',
  'country.NZ': 'ニュージーランド',
  'country.NP': 'ネパール',
  'country.ZW': 'ジンバブエ',
  'country.EC': 'エクアドル',
  'country.GT': 'グアテマラ',
  'country.BD': 'バングラデシュ',
  'country.UY': 'ウルグアイ',
  'country.MN': 'モンゴル',
  'country.KH': 'カンボジア',
  'country.ME': 'モンテネグロ',
  'country.BW': 'ボツワナ',
  'country.KG': 'キルギスタン',
  'country.TJ': 'タジキスタン',
  'country.UZ': 'ウズベキスタン',
  'country.AZ': 'アゼルバイジャン',
  'country.TM': 'トルクメニスタン',
  'country.TT': 'トリニダード・トバゴ',
  'country.GN': 'ギニア',
  'country.AS': 'アメリカ領サモア',
  'country.AD': 'アンドラ',
  'country.AQ': '南極',
  'country.AG': 'アンティグァ・バーブーダ',
  'country.AW': 'アルバ',
  'country.BS': 'バハマ',
  'country.BB': 'バルバドス',
  'country.BM': 'バーミューダ',
  'country.BY': 'ベラルーシ',
  'country.BZ': 'ベリーズ',
  'country.BJ': 'ベナン',
  'country.BT': 'ブータン',
  'country.BO': 'ボリビア',
  'country.BA': 'ボスニア・ヘルツェゴビナ',
  'country.BV': 'ブーベ島',
  'country.GU': 'グアム',
  'country.GY': 'ガイアナ',
  'country.HT': 'ハイチ',
  'country.HM': 'ハード・マクドナルド諸島',
  'country.HN': 'ホンジュラス',
  'country.IS': 'アイスランド',
  'country.KI': 'キリバス',
  'country.KR': '大韓民国',
  'country.LA': 'ラオス',
  'country.WS': '西サモア',
  'country.SM': 'サンマリノ',
  'country.ST': 'サントメ・プリンシペ',
  'country.SC': 'セイシェル',
  'country.SK': 'スロバキア',
  'country.SB': 'ソロモン諸島',
  'country.SO': 'ソマリア',
  'country.GS': 'サウスジョージア島・サウスサンドイッチ島',
  'country.SH': 'セントヘレナ、アセンション、トリスタンダクーニャ',
  'country.PM': 'サンピエール・ミクロン諸島',
  'country.SR': 'スリナム',
  'country.SJ': 'スバールバル・ヤンマイエン島',
  'country.IO': 'イギリスインド洋領',
  'country.BN': 'ブルネイ・ダルサラーム',
  'country.BF': 'ブルキナファソ',
  'country.BI': 'ブルンジ',
  'country.KY': 'ケイマン諸島',
  'country.CF': '中央アフリカ共和国',
  'country.TD': 'チャド',
  'country.CX': 'クリスマス諸島',
  'country.CC': 'ココス諸島',
  'country.KM': 'コモロ',
  'country.CG': 'コンゴ',
  'country.CK': 'クック諸島',
  'country.CI': 'コートジボアール',
  'country.HR': 'クロアチア',
  'country.CY': 'キプロス',
  'country.DJ': 'ジブチ',
  'country.LS': 'レソト',
  'country.LY': 'リビア',
  'country.LI': 'リヒテンシュタイン',
  'country.MO': 'マカオ',
  'country.MW': 'マラウイ',
  'country.MV': 'モルディブ',
  'country.ML': 'マリ',
  'country.MT': 'マルタ',
  'country.MH': 'マーシャル諸島',
  'country.MQ': 'マルチニーク',
  'country.MR': 'モーリタニア',
  'country.MU': 'モーリシャス',
  'country.YT': 'マヨット島(仏領)',
  'country.FM': 'ミクロネシア',
  'country.MD': 'モルドバ',
  'country.MC': 'モナコ',
  'country.MS': 'モントセラト',
  'country.MZ': 'モザンビーク',
  'country.MM': 'ミャンマー',
  'country.NA': 'ナミビア',
  'country.NR': 'ナウル',
  'country.SZ': 'エスワティーニ',
  'country.TW': '台湾',
  'country.TZ': 'タンザニア',
  'country.TG': 'トーゴ',
  'country.TK': 'トケラウ',
  'country.TO': 'トンガ',
  'country.TC': 'タークス諸島・カイコス諸島',
  'country.TV': 'ツバル',
  'country.DM': 'ドミニカ',
  'country.TL': '東ティモール',
  'country.SV': 'エルサルバドル',
  'country.GQ': '赤道ギニア',
  'country.ER': 'エリトリア',
  'country.FK': 'フォークランド諸島',
  'country.FO': 'フ ェ ロ ー 諸島',
  'country.FJ': 'フィジー',
  'country.GF': 'フランス領ギニア',
  'country.PF': 'フランス領ポリネシア',
  'country.TF': '仏南方領',
  'country.GE': 'グルジア',
  'country.GD': 'グレナダ',
  'country.GL': 'グリーンランド',
  'country.GP': 'グアドループ',
  'country.NC': 'ニューカレドニア',
  'country.NI': 'ニカラグア',
  'country.NE': 'ニジェール',
  'country.NU': 'ニウエ',
  'country.NF': 'ノーフォーク諸島',
  'country.MP': '北マリアナ諸島',
  'country.PW': 'パラオ',
  'country.PA': 'パナマ',
  'country.PG': 'パプアニューギニア',
  'country.PY': 'パラグアイ',
  'country.PN': 'ピトケアン諸島',
  'country.RE': 'レユニオン島',
  'country.RU': 'ロシア連邦',
  'country.KN': 'セントクリストファー・ネイビス',
  'country.LC': 'セントルシア',
  'country.VC': 'セントビンセントおよびグレナディーン諸島',
  'country.UM': 'アメリカ合衆国小離島',
  'country.VU': 'バヌアツ',
  'country.VA': 'バチカン市',
  'country.VN': 'ベトナム',
  'country.VG': 'バージン諸島（イギリス）',
  'country.VI': 'バージン諸島（米国）',
  'country.WF': 'ワリスフツナ諸島',
  'country.EH': '西サハラ',
  'country.GW': 'ギニアビサオ',
  'country.GI': 'ジブラルタル',
  'country.SS': '南スーダン',
  'country.CW': 'キュラソー',
  'country.AX': 'オーランド諸島',
  'country.AI': 'アンギラ',
  'country.CD': 'コンゴ民主共和国',
  'country.CU': 'キューバ',
  'country.GG': 'ガーンジー',
  'country.IR': 'イラン',
  'country.IM': 'マン島',
  'country.JE': 'ジャージー',
  'country.PS': 'パレスチナ',
  'country.MF': 'セントマーチン（フランス語部分）',
  'country.SX': 'シントマールテン（オランダ語部分）',
  'country.SD': 'スーダン',
  'country.SY': 'シリア',
  'country.BQ': 'ボネール、シントユースタティウス、サバ',
  'country.BL': 'セントバーセルミー',
  'state.AL': 'アラバマ',
  'state.AK': 'アラスカ',
  'state.AZ': 'アリゾナ',
  'state.AR': 'アーカンソー',
  'state.CA': 'カリフォルニア',
  'state.CO': 'コロラド',
  'state.CT': 'コネチカット',
  'state.DE': 'デラウェア',
  'state.FL': 'フロリダ',
  'state.GA': 'ジョージア',
  'state.HI': 'ハワイ',
  'state.ID': 'アイダホ',
  'state.IL': 'イリノイ',
  'state.IN': 'インディアナ',
  'state.IA': 'アイオワ',
  'state.KS': 'カンザス',
  'state.KY': 'ケンタッキー',
  'state.LA': 'ルイジアナ',
  'state.ME': 'メイン州',
  'state.MD': 'メリーランド',
  'state.MA': 'マサチューセッツ',
  'state.MI': 'ミシガン',
  'state.MN': 'ミネソタ',
  'state.MS': 'ミシシッピ',
  'state.MO': 'ミズーリ州',
  'state.MT': 'モンタナ',
  'state.NE': 'ネブラスカ',
  'state.NV': 'ネバダ',
  'state.NH': 'ニューハンプシャー',
  'state.NJ': 'ニュージャージー',
  'state.NM': 'ニューメキシコ',
  'state.NY': 'ニューヨーク',
  'state.NC': 'ノースカロライナ州',
  'state.ND': 'ノースダコタ州',
  'state.OH': 'オハイオ',
  'state.OK': 'オクラホマ',
  'state.OR': 'オレゴン',
  'state.PA': 'ペンシルバニア',
  'state.RI': 'ロードアイランド',
  'state.SC': 'サウスカロライナ',
  'state.SD': 'サウス・ダコタ',
  'state.TN': 'テネシー',
  'state.TX': 'テキサス',
  'state.UT': 'ユタ',
  'state.VT': 'バーモント',
  'state.VA': 'バージニア',
  'state.WA': 'ワシントン',
  'state.WV': 'ウェストバージニア',
  'state.WI': 'ウィスコンシン',
  'state.WY': 'ワイオミング',
  'state.DC': 'コロンビア特別区',
  'state.AS': 'アメリカ領サモア',
  'state.FM': 'ミクロネシア連邦',
  'state.GU': 'グアム',
  'state.MH': 'マーシャル諸島',
  'state.MP': '北マリアナ諸島',
  'state.PW': 'パラオ',
  'state.PR': 'プエルトリコ',
  'state.VI': 'ヴァージン諸島',
  'state.AE': '軍ヨーロッパ、中東、カナダ',
  'state.AP': '太平洋軍',
  'state.AA': 'アメリカ軍（カナダを除く）',
  'province.AB': 'アルバータ',
  'province.BC': 'ブリティッシュコロンビア',
  'province.MB': 'マニトバ',
  'province.NB': 'ニューブランズウィック',
  'province.NL': 'ニューファンドランドとラブラドール',
  'province.NT': 'ノースウェスト準州',
  'province.NS': 'ノバスコシア',
  'province.NU': 'ヌナブト',
  'province.ON': 'オンタリオ',
  'province.PE': 'プリンス・エドワード島',
  'province.QC': 'ケベック',
  'province.SK': 'サスカチュワン州',
  'province.YT': 'ユーコン',
  'state.AbuDhabi': 'Abu Dhabi',
  'state.Ajman': 'Ajman',
  'state.Dubai': 'Dubai',
  'state.Fujairah': 'Fujairah',
  'state.RasAlKhaimah': 'Ras Al Khaimah',
  'state.Sharjah': 'Sharjah',
  'state.UmmAlQuwain': 'Umm Al Quwain',
  'billing.aeState': 'エミレーツ',
  'instate.AN': 'アンダマン・ニコバル諸島',
  'instate.AP': 'アーンドラ プラデーシュ州',
  'instate.AR': 'アルナーチャル プラデーシュ州',
  'instate.AS': 'アッサム州',
  'instate.BR': 'ビハール州',
  'instate.CH': 'チャンディーガル',
  'instate.CT': 'チャッティースガル州',
  'instate.DN': 'ダドラとナガル・ハベリ',
  'instate.DD': 'ダマンとディウ',
  'instate.DL': 'デリー',
  'instate.GA': '行きます',
  'instate.GJ': 'グジャラート州',
  'instate.HR': 'ハリヤナ州',
  'instate.HP': 'ヒマーチャル プラデーシュ州',
  'instate.JK': 'ジャンムー・カシミール',
  'instate.JH': 'ジャールカンド州',
  'instate.KA': 'カルナータカ州',
  'instate.KL': 'ケーララ州',
  'instate.LD': 'ラクシャディープ諸島',
  'instate.MP': 'マディヤ プラデーシュ州',
  'instate.MH': 'マハラシュトラ州',
  'instate.MN': 'マニプール',
  'instate.ML': 'メガラヤ州',
  'instate.MZ': 'ミゾラム州',
  'instate.NL': 'ナガランド州',
  'instate.OR': 'オリッサ州',
  'instate.PY': 'ポンディシェリ',
  'instate.PB': 'パンジャーブ',
  'instate.RJ': 'ラジャスタン州',
  'instate.SK': 'シッキム',
  'instate.TN': 'タミル・ナードゥ州',
  'instate.TG': 'テランガーナ',
  'instate.TR': 'トリプラ',
  'instate.UP': 'ウッタル・プラデーシュ州',
  'instate.UT': 'ウッタラーカンド州',
  'instate.WB': '西ベンガル州',
  'billing.inState': '都道府県',
};
