/* eslint-disable no-confusing-arrow */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { object, bool, string, func, number } from 'prop-types';
import { connect } from 'react-redux';
import { produce } from 'immer';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router';
import qs from 'query-string';
import Order from './order/Order';
import OrderSummary from './order/OrderSummary';
import BillingInformation from './billing/BillingInformation';
import BillingInformationConfirmation from './billing/BillingInformationConfirmation';
import ModalWrapper from './common/ModalWrapper';
import Footer from './common/Footer';
import StripeContainer from './order/StripeContainer';
import {
  getFullOrder,
  validateAddress,
  updateOrder,
  confirmTransaction,
  getRedirect,
  getClaims,
  enableActions,
  getAddressRequirements,
  pumpUpExpirationTimers,
} from '../redux/actions/ordersActions';

import {
  setPaymentErrorMessage,
  deletePaymentErrorMessage,
  setBillingErrorMessage,
} from '../redux/actions/errorActions';
import { initializePendo, updateVisitorInformation } from '../redux/actions/pendoActions';
import AddressValidationStates from '../constants/addressValidationState';
import PaymentResultState from '../constants/paymentResultState';
import { cleanCurrencyNumber } from '../helpers/localeHelpers';

import '../styles/components/main-component.scss';
import ErrorMessage from './common/ErrorMessage';

const MainComponent = ({
  order,
  error,
  locale,
  actionsEnabled,
  enableActions,
  loadingOrder,
  loadingClaims,
  getClaims,
  hideOrderDetails,
  useStripe,
  stripeClientSecret,
  stripePublicKey,
  getFullOrder,
  validateAddress,
  updateOrder,
  confirmTransaction,
  getRedirect,
  clientRedirectUrl,
  supplierTag,
  transactionId,
  sessionTimeout,
  sessionStart,
  transactionToken,
  confirmedTransaction,
  history: { push },
  location: { search },
  orderErrorMessage,
  billingErrorMessage,
  paymentErrorMessage,
  setPaymentErrorMessage,
  deletePaymentErrorMessage,
  setBillingErrorMessage,
  selectedCard,
  secondLabel,
  initializePendo,
  updateVisitorInformation,
  addressRequirementsList,
  getAddressRequirements,
  pumpUpExpirationTimers,
  maxAttempts
}) => {
  const intl = useIntl();  

  const [orderInformation, setOrderInformation] = useState({
    subtotal: 0,
    items: [],
    currency: '',
    totalDiscount: 0,
    totalTax: 0,
    totalFee: 0,
    total: 0,
    orderDiscount: 0,
    billingAddress: null,
    purchaser: null,
    memo: '',
  });

  const [billingInformationEditMode, setBillingInformationEditMode] = useState(true);
  const [emptyOrder, setEmptyOrder] = useState(false);
  const [payEnabled, setPayEnabled] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newBillingInformation, setNewBillingInformation] = useState({
    newPurchaser: {},
    validatedAddress: {},
    enteredAddress: {},
  });
  const [addressChangeMessage, setAddressChangeMessage] = useState('');
  const [isAddressBeingValidated, setIsAddressBeingValidated] = useState(false);
  const [isValidationPending, setIsValidationPending] = useState(false);
  const [stripeComponentComplete, setStripeComponentComplete] = useState(false);
  const [cardBrand, setCardBrand] = useState('unknown');

  const timers = useRef([]);

  useEffect(() => {
    sessionStart && !sessionTimeout && push('/notfound');
  }, []);

  useEffect(() => {
    initializePendo && supplierTag && intl && initializePendo(intl, supplierTag);
  }, [initializePendo, supplierTag, intl]);

  useEffect(() => {
    if (!loading) {
      const { token } = qs.parse(search);
      if (error && transactionToken === token) {
        push('/notfound');
      } else if (!token || token !== transactionToken) {
        getClaims(token);
      }
    }
  }, [error, getClaims, push, search, transactionToken, loading]);

  useEffect(() => {
    if (!sessionTimeout) return;

    const allowedSpan = 1000 * 60 * sessionTimeout;
    const elapsedTime = (new Date().getTime() - sessionStart) / allowedSpan; // 0.0 equals start. 1.0 means timeout.
    const toastTime = Math.min(Math.max(sessionTimeout * 0.1, 1), 5) / sessionTimeout;
    timers.current.forEach(timer => clearTimeout(timer));
    timers.current = [];

    timers.current.push(
      setTimeout(() => {
        setIsTokenExpired(true);
        setLoading(true);
        clientRedirectUrl &&
          supplierTag &&
          transactionId &&
          getRedirect(transactionId, locale, supplierTag, true, PaymentResultState.EXPIRED);
      }, Math.max(0, (1.0 - elapsedTime) * allowedSpan))
    );

    elapsedTime < 1 &&
      timers.current.push(
        setTimeout(() => {
          toast(intl.formatMessage({ id: 'global.expiringSession' }), {
            type: toast.TYPE.WARNING,
            toastId: 'paymentToastId',
            autoClose: Math.min(toastTime, 1.0 - elapsedTime) * allowedSpan,
            pauseOnHover: false,
          });
        }, Math.max(0, 1.0 - toastTime - elapsedTime) * allowedSpan)
      );

    return () => {
      timers.current.forEach(timer => clearTimeout(timer));
      timers.current = [];
    };
  }, [sessionTimeout, sessionStart, intl, clientRedirectUrl, getRedirect, locale, supplierTag, transactionId]);

  useEffect(() => {
    transactionId && !order && getFullOrder(transactionId, locale, clientRedirectUrl, supplierTag);
  }, [order, transactionId, locale, clientRedirectUrl, supplierTag, getFullOrder]);

  const calculateSubTotal = (total, totalFee, totalTax, orderDiscount) => total - totalFee - totalTax + orderDiscount;

  const memorizedCalculateSubTotal = useCallback(
    (total, totalFee, totalTax, orderDiscount) => calculateSubTotal(total, totalFee, totalTax, orderDiscount),
    []
  );  

  useEffect(() => {
    if (!!order) {
      setEmptyOrder(false);
      const {
        paymentOrder: {
          orderLineItems,
          currency: newCurrency,
          discount: newOrderDiscount = 0,
          billingAddress: newBillingAddress,
          purchaser: newPurchaser,
          organization: newOrganization = {},
          organization: { name: newOrganizationName, taxId: newOrganizationTaxId } = {},
          currencyConversion: newCurrencyConversion,
          orderB2B: newOrderB2B,
          note: newMemo,
        },
        totalDiscount: newTotalDiscount = 0,
        totalTax: newTotalTax = 0,
        totalFee: newTotalFee = 0,
        total: newTotal,
      } = order;

      setPayEnabled(
        !!newBillingAddress &&
        !!newPurchaser &&
        (!newOrderB2B || (!!newOrganization && newOrganizationName && newOrganizationTaxId)) &&
        newTotal > 0
      );

      const isInEditMode =
        !newBillingAddress || !newPurchaser || (newOrderB2B && (!newOrganizationName || !newOrganizationTaxId));

      setBillingInformationEditMode(isInEditMode);

      !isInEditMode && updateVisitorInformation(intl, supplierTag, newPurchaser);

      const newOrderInformation = {
        subtotal: memorizedCalculateSubTotal(newTotal, newTotalFee, newTotalTax, newOrderDiscount),
        items: orderLineItems,
        currency: newCurrency,
        totalDiscount: newTotalDiscount,
        totalTax: newTotalTax,
        totalFee: newTotalFee,
        total: newTotal,
        orderDiscount: newOrderDiscount,
        billingAddress: newBillingAddress,
        purchaser: newPurchaser,
        organization: newOrganization,
        currencyConversion: newCurrencyConversion,
        orderB2B: newOrderB2B,
        memo: newMemo,
      };
      setOrderInformation(newOrderInformation);
    } else {
      setEmptyOrder(true);
    }
  }, [order, memorizedCalculateSubTotal, updateVisitorInformation, supplierTag, transactionId, intl]);

  const performUpdateOrder = async (billingAddress, purchaser, organization, memo) => {
    const newOrder = produce(order, draft => {
      draft.paymentOrder.billingAddress = billingAddress;
      draft.paymentOrder.purchaser = purchaser;
      draft.paymentOrder.note = memo;
      draft.paymentOrder.organization = organization;
    });

    const { paymentOrder: newPaymentOrder } = newOrder;

    await updateOrder(transactionId, locale, clientRedirectUrl, supplierTag, newPaymentOrder);
  };

  const updateOrderForConversion = async currencyChange => {
    const newOrder = produce(order, draft => {
      draft.paymentOrder.currencyConversion.useForPayment = currencyChange;
    });

    const { paymentOrder: newPaymentOrder } = newOrder;

    return updateOrder(transactionId, locale, clientRedirectUrl, supplierTag, newPaymentOrder, true);
  };

  const useBillingInformationHandler = async (
    firstName,
    lastName,
    email,
    address,
    city,
    country,
    state,
    postalCode,
    memo,
    organizationName,
    taxId
  ) => {
    const { purchaser: { username: oldUsername, userId: oldUserId } = {} } = orderInformation || {};

    const newBillingAddress = {
      address1: address,
      city,
      country,
      state,
      postalCode,
    };

    const newPurchaser = {
      firstName,
      lastName,
      email,
      oldUsername,
      oldUserId,
    };

    const newOrganization = {
      name: organizationName,
      taxId,
    };

    const newOrderInformation = produce(orderInformation, draft => {
      draft.billingAddress = newBillingAddress;
      draft.purchaser = newPurchaser;
      draft.memo = memo;
      draft.organization = newOrganization;
    });

    setOrderInformation(newOrderInformation);

    // Validate address && check if user must be notified
    setIsAddressBeingValidated(true);
    const {
      state: validationState,
      address: validatedAddress,
      message,
    } = await validateAddress(newBillingAddress, locale);
    if (validationState === AddressValidationStates.VALID) {
      await performUpdateOrder(validatedAddress || newBillingAddress, newPurchaser, newOrganization, memo);
      setBillingInformationEditMode(false);
    } else if (validationState === AddressValidationStates.NEEDS_CONFIRMATION) {
      setNewBillingInformation({ newPurchaser, validatedAddress, enteredAddress: newBillingAddress });
      setAddressChangeMessage(message);
      setIsValidationPending(true);
    }
    setIsAddressBeingValidated(false);
  };

  const performUpdateOrderHandler = async () => {
    const { newPurchaser, validatedAddress } = newBillingInformation;
    const { organization, memo } = orderInformation;

    setIsValidationPending(false);
    setIsAddressBeingValidated(true);
    setBillingInformationEditMode(false);
    await performUpdateOrder(validatedAddress, newPurchaser, organization, memo);
    setIsAddressBeingValidated(false);
    setAddressChangeMessage('');
  };

  const shouldConvertCurrency = (baseCurrency, brand) =>
    order?.paymentOrder?.currencyConversion &&
    baseCurrency !== 'USD' &&
    !['visa', 'mastercard', 'amex', 'unknown'].includes(brand);

  const getCardBandName = brand => intl.formatMessage({ id: `cardBrand.${brand}` });

  const handleStripeChange = ({ complete, brand }) => {
    const conversion = shouldConvertCurrency(order, brand);
    if (brand !== cardBrand && conversion) {
      setPaymentErrorMessage(
        intl.formatMessage(
          { id: 'payment.unsupportedCardOfferCurrencyConversion' },
          {
            0: getCardBandName(brand),
            1: order.paymentOrder.currencyConversion.baseCurrency,
            2: order.paymentOrder.currencyConversion.convertedCurrency,
          }
        )
      );
    } else if (paymentErrorMessage && !conversion) {
      deletePaymentErrorMessage();
    }
    setCardBrand(brand);
    setStripeComponentComplete(complete);
  };

  const setBillingInformationEditModeHandler = changeMode => {
    setBillingInformationEditMode(changeMode);
    setPayEnabled(!changeMode && !orderErrorMessage);
  };

  const cancelToast = async key => {
    const toastId = sessionStorage.getItem(key);
    toastId && toast.dismiss(toastId);
  };

  const {
    subtotal,
    items,
    currency,
    totalDiscount,
    totalTax,
    totalFee,
    orderDiscount,
    billingAddress,
    purchaser,
    organization,
    orderB2B,
    memo,
  } = orderInformation;
  // TODO: total was taken away due to the usage of convertedAmount et al.

  // **Compute billingAddressEditable: Disable if Xvoucher Marketplace India**
  const billingAddressEditable = !(
    order?.paymentOrder?.resellerId === 47536
  );

  const { validatedAddress, enteredAddress } = newBillingInformation;

  const handleCancel = () => {
    if (actionsEnabled && !confirmedTransaction) {
      setLoading(true);
      getRedirect(transactionId, locale, supplierTag, true, PaymentResultState.CANCELLED);
    }
  };

  const handlePayment = async (currencyChange, stripe, card) => {
    if (payEnabled && actionsEnabled) {
      if (maxAttempts === 0) {
        cancelToast('noRemainingAttemptsId');
        toast(intl.formatMessage({ id: 'global.noRemainingAttempts' }), {
          type: toast.TYPE.WARNING,
          toastId: 'noRemainingAttemptsId',
          autoClose: 5000,
          pauseOnHover: false,
          onClose: () => handleCancel()
        });
      }
      await deletePaymentErrorMessage();
      cancelToast('paymentToastId');
      await pumpUpExpirationTimers();
      enableActions(false);
      let newStripeClientSecret;
      if (currencyChange) {
        newStripeClientSecret = await updateOrderForConversion(currencyChange);
        if (!newStripeClientSecret) {
          enableActions(true);
          return;
        }
      }
      await stripe.confirmCardPayment(newStripeClientSecret || stripeClientSecret, {
        payment_method: {
          card,
        },
      });
      const { errorMessage } = await confirmTransaction(transactionId, supplierTag, locale);
      (error || errorMessage) && enableActions(true);
    }
  };

 

  /* eslint-disable indent */
  const getPayMessage = convertCurrency => {
    if (convertCurrency && !order?.paymentOrder?.currencyConversion) return '';
    return convertCurrency
      ? `${cleanCurrencyNumber(
        order.paymentOrder.currencyConversion.convertedOrderTotal,
        order.paymentOrder.currencyConversion.convertedCurrency,
        intl.formatNumberToParts
      )} ${order.paymentOrder.currencyConversion.convertedCurrency}`
      : `${cleanCurrencyNumber(subtotal + totalTax + totalFee, currency, intl.formatNumberToParts)} ${currency}`;
  };
  /* eslint-enable indent */

  return (
    <>
      <ModalWrapper
        supplierTag={supplierTag}
        handleCancel={handleCancel}
        backEnabled={actionsEnabled && !confirmedTransaction}
      >
        <>
          {transactionToken && (
            <div className="order-information-container">
              <div className="order-billing-component-container">
                {!loadingOrder && !loadingClaims && !loading && !isAddressBeingValidated && orderErrorMessage && (
                  <ErrorMessage errorMessage={orderErrorMessage} />
                )}
                {(items?.length > 0 || loadingClaims) && !hideOrderDetails && (
                  <>
                    <Order
                      items={items}
                      loadingItems={loadingOrder || loadingClaims || loading || !order}
                      orderDisplaySubtotal={subtotal}
                      currency={currency}
                      empty={emptyOrder || isTokenExpired}
                    />
                    <hr />
                  </>
                )}
                {!loadingOrder && !loadingClaims && !loading && !isAddressBeingValidated && billingErrorMessage && (
                  <ErrorMessage errorMessage={billingErrorMessage} />
                )}
                {!isTokenExpired &&
                  (isValidationPending ? (
                    <BillingInformationConfirmation
                      originalAddressInfo={enteredAddress}
                      validatedAddressInfo={validatedAddress}
                      onClick={performUpdateOrderHandler}
                      clickMsg={intl.formatMessage({ id: 'billing.confirmAddress' })}
                      onClose={() => {
                        setBillingErrorMessage(intl.formatMessage({ id: 'billing.errorValidatingAddress' }));
                        setIsAddressBeingValidated(false);
                        setIsValidationPending(false);
                      }}
                      message={addressChangeMessage}
                      closeMsg={intl.formatMessage({ id: 'billing.cancelAddressConfirmation' })}
                    />
                  ) : (
                    <BillingInformation
                      loading={
                        isAddressBeingValidated || loadingOrder || loadingClaims || loading || !items?.length || !order
                      }
                      editMode={billingInformationEditMode}
                      cancelEdit={() => !billingErrorMessage && setBillingInformationEditModeHandler(false)}
                      changeModeFunction={setBillingInformationEditModeHandler}
                      billingInformationFunction={useBillingInformationHandler}
                      billingAddress={billingAddress}
                      purchaser={purchaser}
                      organization={organization}
                      actionsEnabled={actionsEnabled}
                      orderB2B={orderB2B}
                      supplierTag={supplierTag}
                      memo={memo}
                      addressRequirementsList={addressRequirementsList}
                      getAddressRequirements={getAddressRequirements}
                      currency={currency}
                      billingAddressEditable={billingAddressEditable}
                    />
                  ))}
              </div>
              {!billingInformationEditMode && (
                <>
                  <hr />
                  <div className="summary-component-container">
                    <OrderSummary
                      loading={loadingOrder || loadingClaims || loading || !order}
                      editMode={billingInformationEditMode}
                      subtotal={subtotal}
                      totalTax={totalTax}
                      orderDiscount={orderDiscount}
                      totalDiscount={totalDiscount}
                      totalFee={totalFee}
                      currency={currency}
                      empty={emptyOrder || isTokenExpired}
                      payEnabled={payEnabled && actionsEnabled}
                      paymentFunction={handlePayment}
                      useStripe={useStripe}
                      currencyConversion={shouldConvertCurrency(order, cardBrand)}
                      selectedCard={selectedCard}
                      secondLabel={secondLabel}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {!loadingOrder && !loadingClaims && !loading && !isAddressBeingValidated && !billingInformationEditMode && (
            <>
              <hr />
              {paymentErrorMessage && <ErrorMessage errorMessage={paymentErrorMessage} className="shorter" />}
              {useStripe && (
                <>
                  <div className="stripe-component-container">
                    {stripePublicKey && (
                      <StripeContainer
                        locale={intl.locale}
                        handleStripeChange={handleStripeChange}
                        paymentFunction={(stripe, card) =>
                          handlePayment(shouldConvertCurrency(order, cardBrand), stripe, card)
                        }
                        shouldConvertCurrency={shouldConvertCurrency(order, cardBrand)}
                        // TODO: Contemplate both cases -> disabled={!payEnabled || (convertedCurrency && !selectedCard)}
                        isButtonDisabled={!(payEnabled && actionsEnabled) || !stripeComponentComplete || loadingOrder}
                        isButtonLoading={!actionsEnabled}
                        buttonMessage={`${intl.formatMessage({ id: 'orderSummary.pay' })} ${getPayMessage(false)}`}
                        buttonMessageConvertedCurrency={`${intl.formatMessage({
                          id: 'orderSummary.pay',
                        })} ${getPayMessage(shouldConvertCurrency(order, cardBrand))}`}
                        stripePublicKey={stripePublicKey}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </>
      </ModalWrapper>
      <Footer supplierTag={supplierTag} />
    </>
  );
};

MainComponent.propTypes = {
  order: object,
  error: string,
  locale: string,
  actionsEnabled: bool,
  enableActions: func.isRequired,
  loadingOrder: bool,
  loadingClaims: bool,
  getClaims: func,
  hideOrderDetails: bool,
  useStripe: bool,
  stripeClientSecret: string,
  stripePublicKey: string,
  getFullOrder: func.isRequired,
  validateAddress: func.isRequired,
  updateOrder: func.isRequired,
  confirmTransaction: func.isRequired,
  clientRedirectUrl: string,
  getRedirect: func.isRequired,
  supplierTag: string,
  transactionId: string,
  sessionTimeout: number,
  sessionStart: number,
  maxAttempts : number,
  transactionToken: string,
  history: object,
  location: object,
  confirmedTransaction: bool,
  orderErrorMessage: string,
  billingErrorMessage: string,
  paymentErrorMessage: string,
  setPaymentErrorMessage: func,
  deletePaymentErrorMessage: func,
  setBillingErrorMessage: func,
  selectedCard: string,
  secondLabel: string,
  initializePendo: func,
  updateVisitorInformation: func,
  getAddressRequirements: func,
};

const mapState = ({ order, error }) => ({
  error: order.globalError,
  order: order.order,
  hideOrderDetails: order.hideOrderDetails,
  useStripe: order.useStripe,
  stripeClientSecret: order.stripeClientSecret,
  stripePublicKey: order.stripePublicKey,
  locale: order.locale,
  actionsEnabled: order.actionsEnabled,
  loadingOrder: order.loadingOrder,
  loadingClaims: order.loadingClaims,
  clientRedirectUrl: order.clientRedirectUrl,
  transactionId: order.transactionId,
  sessionTimeout: order.sessionTimeout,
  maxAttempts: order.maxAttempts,
  sessionStart: order.sessionStart,
  supplierTag: order.supplierTag,
  transactionToken: order.transactionToken,
  confirmedTransaction: order.confirmedTransaction,
  orderErrorMessage: error.orderErrorMessage,
  billingErrorMessage: error.billingErrorMessage,
  paymentErrorMessage: error.paymentErrorMessage,
  selectedCard: order.selectedCard,
  secondLabel: order.secondLabel,
  addressRequirementsList: order.addressRequirementsList,
});

const mapDispatch = {
  getFullOrder,
  validateAddress,
  updateOrder,
  confirmTransaction,
  getRedirect,
  getClaims,
  enableActions,
  setPaymentErrorMessage,
  deletePaymentErrorMessage,
  setBillingErrorMessage,
  initializePendo,
  updateVisitorInformation,
  getAddressRequirements,
  pumpUpExpirationTimers,
};

export default connect(mapState, mapDispatch)(withRouter(MainComponent));
