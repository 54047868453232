import { combineReducers } from 'redux';
import order from './ordersReducer';
import error from './errorReducer';

const rootReducer = combineReducers({
  order,
  error,
});

export default rootReducer;
