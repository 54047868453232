export default [
  { code: 'AL', translationKey: 'state.AL' },
  { code: 'AK', translationKey: 'state.AK' },
  { code: 'AZ', translationKey: 'state.AZ' },
  { code: 'AR', translationKey: 'state.AR' },
  { code: 'CA', translationKey: 'state.CA' },
  { code: 'CO', translationKey: 'state.CO' },
  { code: 'CT', translationKey: 'state.CT' },
  { code: 'DE', translationKey: 'state.DE' },
  { code: 'FL', translationKey: 'state.FL' },
  { code: 'GA', translationKey: 'state.GA' },
  { code: 'HI', translationKey: 'state.HI' },
  { code: 'ID', translationKey: 'state.ID' },
  { code: 'IL', translationKey: 'state.IL' },
  { code: 'IN', translationKey: 'state.IN' },
  { code: 'IA', translationKey: 'state.IA' },
  { code: 'KS', translationKey: 'state.KS' },
  { code: 'KY', translationKey: 'state.KY' },
  { code: 'LA', translationKey: 'state.LA' },
  { code: 'ME', translationKey: 'state.ME' },
  { code: 'MD', translationKey: 'state.MD' },
  { code: 'MA', translationKey: 'state.MA' },
  { code: 'MI', translationKey: 'state.MI' },
  { code: 'MN', translationKey: 'state.MN' },
  { code: 'MS', translationKey: 'state.MS' },
  { code: 'MO', translationKey: 'state.MO' },
  { code: 'MT', translationKey: 'state.MT' },
  { code: 'NE', translationKey: 'state.NE' },
  { code: 'NV', translationKey: 'state.NV' },
  { code: 'NH', translationKey: 'state.NH' },
  { code: 'NJ', translationKey: 'state.NJ' },
  { code: 'NM', translationKey: 'state.NM' },
  { code: 'NY', translationKey: 'state.NY' },
  { code: 'NC', translationKey: 'state.NC' },
  { code: 'ND', translationKey: 'state.ND' },
  { code: 'OH', translationKey: 'state.OH' },
  { code: 'OK', translationKey: 'state.OK' },
  { code: 'OR', translationKey: 'state.OR' },
  { code: 'PA', translationKey: 'state.PA' },
  { code: 'RI', translationKey: 'state.RI' },
  { code: 'SC', translationKey: 'state.SC' },
  { code: 'SD', translationKey: 'state.SD' },
  { code: 'TN', translationKey: 'state.TN' },
  { code: 'TX', translationKey: 'state.TX' },
  { code: 'UT', translationKey: 'state.UT' },
  { code: 'VT', translationKey: 'state.VT' },
  { code: 'VA', translationKey: 'state.VA' },
  { code: 'WA', translationKey: 'state.WA' },
  { code: 'WV', translationKey: 'state.WV' },
  { code: 'WI', translationKey: 'state.WI' },
  { code: 'WY', translationKey: 'state.WY' },
  { code: 'DC', translationKey: 'state.DC' },
  { code: 'AS', translationKey: 'state.AS' },
  { code: 'FM', translationKey: 'state.FM' },
  { code: 'GU', translationKey: 'state.GU' },
  { code: 'MH', translationKey: 'state.MH' },
  { code: 'MP', translationKey: 'state.MP' },
  { code: 'PW', translationKey: 'state.PW' },
  { code: 'PR', translationKey: 'state.PR' },
  { code: 'VI', translationKey: 'state.VI' },
  { code: 'AE', translationKey: 'state.AE' },
  { code: 'AP', translationKey: 'state.AP' },
  { code: 'AA', translationKey: 'state.AA' },
];
