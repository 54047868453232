import React, { useEffect, useMemo } from 'react';
import { object, bool, func, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import cn from 'classnames';
import { AddressComponent } from 'xvoucher-react-ui';

import { buildReusableTranslationsByID } from '../../helpers/localeHelpers';
import { unnullifyObject } from '../../helpers/javascriptHelpers';
import BillingInformationLoader from './BillingInformationLoader';
import BillingInformationDisplay from './BillingInformationDisplay';

import FormField from '../common/inputs/FormFieldMUI';
import FormDropdown from '../common/inputs/FormDropdown';
import usStates from '../../constants/usStates';
import caStates from '../../constants/caStates';
import aeStates from '../../constants/aeStates';
import inStates from '../../constants/inStates';
import countries from '../../constants/countries';
import sessionStorageKeys from '../../constants/sessionStorageKeys';
import '../../styles/components/billing/billing-information.scss';

const countrySortFunction = (a, b) => {
  const priorityDiff = (b.priority || 0) - (a.priority || 0);
  return priorityDiff || a.label.localeCompare(b.label);
};

const BillingInformation = ({
  editMode,
  changeModeFunction,
  billingInformationFunction,
  cancelEdit,
  loading,
  billingAddress,
  purchaser,
  organization,
  actionsEnabled,
  orderB2B,
  addressRequirementsList,
  memo = '',
  getAddressRequirements,
  currency,
  billingAddressEditable
}) => {
  const intl = useIntl();

  const reusableTranslations = buildReusableTranslationsByID(
    ['field.requiredField', 'field.invalidEmail'],
    intl.formatMessage
  );

  /* eslint-disable react/no-this-in-sfc */
  /* eslint-disable object-shorthand */
  /* eslint-disable func-names */
  function equalTo(ref, msg) {
    return Yup.mixed().test({
      name: 'equalTo',
      exclusive: false,
      message: msg,
      params: {
        reference: ref.path,
      },
      test: function (value) {
        return value?.trim() === this.resolve(ref)?.trim();
      },
    });
  }
  Yup.addMethod(Yup.string, 'equalTo', equalTo);

  const addressRequirementsShape = useMemo(() => {
    const shape = {
      address:
        addressRequirementsList?.some(req => ['Street', 'StreetName', 'StreetNumber'].includes(req)) &&
        Yup.string().trim().required(reusableTranslations['field.requiredField']),
      city:
        addressRequirementsList?.includes('City') &&
        Yup.string().trim().required(reusableTranslations['field.requiredField']),
      knownState: Yup.string()
        .trim()
        .when('country', {
          is: val => ['US', 'CA', 'AE', 'IN'].includes(val) && addressRequirementsList?.includes('State'),
          then: Yup.string().trim().required(reusableTranslations['field.requiredField']),
        }),
      state: Yup.string()
        .trim()
        .when('country', {
          is: val => !['US', 'CA', 'AE', 'IN'].includes(val) && addressRequirementsList?.includes('State'),
          then: Yup.string().trim().required(reusableTranslations['field.requiredField']),
        }),
      postalCode:
        addressRequirementsList?.includes('PostalCode') &&
        Yup.string().trim().required(reusableTranslations['field.requiredField']),
    };
    /* eslint no-unused-vars: "off" */
    return Object.fromEntries(Object.entries(shape).filter(([_, v]) => v));
  }, [addressRequirementsList, reusableTranslations]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required(reusableTranslations['field.requiredField']),
    lastName: Yup.string().trim().required(reusableTranslations['field.requiredField']),
    email: Yup.string()
      .trim()
      .email(reusableTranslations['field.invalidEmail'])
      .required(reusableTranslations['field.requiredField']),
    confirmEmail: Yup.string()
      .trim()
      .email(reusableTranslations['field.invalidEmail'])
      .equalTo(Yup.ref('email'), intl.formatMessage({ id: 'field.unmatchingEmails' }))
      .required(reusableTranslations['field.requiredField']),
    country: Yup.string()
      .trim()
      .required(reusableTranslations['field.requiredField']),
    organizationName: orderB2B && Yup.string().trim().required(reusableTranslations['field.requiredField']),
    taxId: orderB2B && Yup.string().trim().required(reusableTranslations['field.requiredField']),
    ...addressRequirementsShape,
  });

  const billingInformationSubmit = (
    {
      firstName,
      lastName,
      email,
      confirmEmail,
      address,
      city,
      country,
      state,
      knownState,
      postalCode,
      memo,
      organizationName,
      taxId,
      currency,
    },
    dirty
  ) => {
    if (!dirty) {
      cancelEdit();
      return;
    }
    if (email === confirmEmail) {
      const strState = country === 'US' || country === 'CA' || country === 'AE' || country === 'IN' ? knownState : state;
      billingInformationFunction(
        firstName.trim(),
        lastName.trim(),
        email.trim(),
        address.trim(),
        city.trim(),
        country.trim(),
        strState && strState.trim(),
        postalCode.trim(),
        memo.trim(),
        organizationName.trim(),
        taxId.trim()
      );
    } else {
      toast(intl.formatMessage({ id: 'field.unmatchingEmails' }), { type: toast.TYPE.ERROR });
    }
  };

  const { firstName = '', lastName = '', email = '' } = unnullifyObject(purchaser) || {};
  const {
    address1 = '',
    address2 = '',
    address3 = '',
    address4 = '',
    city = '',
    postalCode = '',
    country = '',
    state = '',
  } = unnullifyObject(billingAddress) || {};

  const { name: organizationName = '', taxId = '' } = unnullifyObject(organization) || {};

  const address = [address1, address2, address3, address4].filter(elem => elem).join(', ');

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    handleBlur,
    resetForm,
    setFieldValue,
    validateForm,
    dirty,
  } = useFormik({
    initialValues: {
      firstName,
      lastName,
      email,
      confirmEmail: email,
      address,
      city,
      country,
      state: ['US', 'CA', 'AE', 'IN'].includes(country) ? '' : state,
      knownState: !['US', 'CA', 'AE', 'IN'].includes(country) ? '' : state,
      postalCode,
      memo,
      organizationName,
      taxId,
      currency,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: () => {
      if (actionsEnabled) {
        billingInformationSubmit(values, dirty);
        resetForm();
      }
    },
  });

  const authToken = sessionStorage.getItem(sessionStorageKeys.AUTH_TOKEN_STORAGE_KEY);

  useEffect(() => {
    if (values.country && authToken) {
      getAddressRequirements({ country: values.country });
    }
  }, [values.country, authToken, getAddressRequirements]);

  useEffect(() => {
    editMode && validateForm();
  }, [validateForm, editMode]);

  useEffect(() => {
    if (!billingAddressEditable) {
      changeModeFunction(false); // Ensure we are not in edit mode
    }
  }, [billingAddressEditable, changeModeFunction]);

  const knownStates = country => {
    switch (country) {
      case 'US':
        return usStates;
      case 'CA':
        return caStates;
      case 'AE':
        return aeStates;
      case 'IN':
        return inStates;
      default:
        return [];
    }
  };

  return (
    <div className={cn('billing-information-container card', { shrank: !editMode })}>
      {loading ? (
        <BillingInformationLoader />
      ) : (
        <>
          <div className="billing-information-title-container">
            <h3 className="bold">{intl.formatMessage({ id: 'billing.billing' })}</h3>
            {!loading && billingAddressEditable && (
            <button
              id={editMode && billingAddressEditable ? 'SaveBillingInformationButton' : 'EditBillingInformationButton'}
              onClick={() => {
                editMode ? handleSubmit() : actionsEnabled && changeModeFunction(true);
              }}
              className="action-button"
              disabled={!actionsEnabled}
              type="button"
            >
              {intl.formatMessage({ id: editMode ? 'billing.save' : 'billing.edit' })}
            </button>
          )}
          </div>
          {editMode ? (
            <form className="billing-information-form" onSubmit={handleSubmit}>
              <FormField
                elementId="FirstName"
                label={intl.formatMessage({ id: 'billing.firstName' })}
                name="firstName"
                maxLength="50"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                error={touched.firstName && errors.firstName}
                required
              />
              <FormField
                elementId="LastName"
                label={intl.formatMessage({ id: 'billing.lastName' })}
                name="lastName"
                maxLength="50"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                error={touched.lastName && errors.lastName}
                required
              />
              <FormField
                elementId="Email"
                label={intl.formatMessage({ id: 'billing.email' })}
                name="email"
                maxLength="100"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={touched.email && errors.email}
                required
              />
              <FormField
                elementId="ConfirmEmail"
                label={intl.formatMessage({ id: 'billing.confirmEmail' })}
                name="confirmEmail"
                maxLength="100"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmEmail}
                error={touched.confirmEmail && errors.confirmEmail}
                required
              />
              <AddressComponent
                intl={intl}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                countries={countries}
                states={knownStates(values.country)}
                countrySortFunction={countrySortFunction}
                FormField={FormField}
                FormDropdown={FormDropdown}
                addressRequirementsList={addressRequirementsList}
              />
              {orderB2B && (
                <>
                  <FormField
                    elementId="OrganizationName"
                    label={intl.formatMessage({ id: 'billing.organizationName' })}
                    name="organizationName"
                    maxLength="50"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.organizationName}
                    placeholder={intl.formatMessage({ id: 'billing.organizationName' })}
                    error={touched.organizationName && errors.organizationName}
                    hideLabel
                    required
                  />
                  <FormField
                    elementId="TaxId"
                    label={intl.formatMessage({ id: 'billing.taxId' })}
                    name="taxId"
                    maxLength="50"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.taxId}
                    placeholder={intl.formatMessage({ id: 'billing.taxId' })}
                    error={touched.taxId && errors.taxId}
                    hideLabel
                    required
                  />
                </>
              )}
              <FormField
                elementId="Memo"
                label={intl.formatMessage({ id: 'billing.memo' })}
                name="memo"
                maxLength="250"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.memo}
                error={touched.memo && errors.memo}
                fullWidth
              />
            </form>
          ) : (
            <BillingInformationDisplay
              firstName={firstName}
              lastName={lastName}
              email={email}
              address={address}
              city={city}
              state={state}
              postalCode={postalCode}
              country={country}
              simplified
            />
          )}
        </>
      )}
    </div>
  );
};

BillingInformation.propTypes = {
  editMode: bool,
  changeModeFunction: func.isRequired,
  billingInformationFunction: func.isRequired,
  cancelEdit: func.isRequired,
  loading: bool,
  billingAddress: object,
  purchaser: object,
  organization: object,
  actionsEnabled: bool,
  orderB2B: bool,
  memo: string,
  getAddressRequirements: func,
  currency: string.isRequired,
  billingAddressEditable: bool,
};

BillingInformation.defaultProps = {
  billingAddressEditable: true,
};

export default BillingInformation;
