const countries = [
  { isoCode2: 'US', translationKey: 'country.US', priority: 2 },
  { isoCode2: 'CA', translationKey: 'country.CA', priority: 1 },
  { isoCode2: 'AF', translationKey: 'country.AF' },
  { isoCode2: 'AL', translationKey: 'country.AL' },
  { isoCode2: 'DZ', translationKey: 'country.DZ' },
  { isoCode2: 'AO', translationKey: 'country.AO' },
  { isoCode2: 'AR', translationKey: 'country.AR' },
  { isoCode2: 'AT', translationKey: 'country.AT' },
  { isoCode2: 'AU', translationKey: 'country.AU' },
  { isoCode2: 'BE', translationKey: 'country.BE' },
  { isoCode2: 'BH', translationKey: 'country.BH' },
  { isoCode2: 'BR', translationKey: 'country.BR' },
  { isoCode2: 'BG', translationKey: 'country.BG' },
  { isoCode2: 'CM', translationKey: 'country.CM' },
  { isoCode2: 'CV', translationKey: 'country.CV' },
  { isoCode2: 'CL', translationKey: 'country.CL' },
  { isoCode2: 'CN', translationKey: 'country.CN' },
  { isoCode2: 'CO', translationKey: 'country.CO' },
  { isoCode2: 'CZ', translationKey: 'country.CZ' },
  { isoCode2: 'DK', translationKey: 'country.DK' },
  { isoCode2: 'EG', translationKey: 'country.EG' },
  { isoCode2: 'ET', translationKey: 'country.ET' },
  { isoCode2: 'EE', translationKey: 'country.EE' },
  { isoCode2: 'FI', translationKey: 'country.FI' },
  { isoCode2: 'FR', translationKey: 'country.FR' },
  { isoCode2: 'GA', translationKey: 'country.GA' },
  { isoCode2: 'GM', translationKey: 'country.GM' },
  { isoCode2: 'GH', translationKey: 'country.GH' },
  { isoCode2: 'DE', translationKey: 'country.DE' },
  { isoCode2: 'GR', translationKey: 'country.GR' },
  { isoCode2: 'HK', translationKey: 'country.HK' },
  { isoCode2: 'HU', translationKey: 'country.HU' },
  { isoCode2: 'IN', translationKey: 'country.IN' },
  { isoCode2: 'ID', translationKey: 'country.ID' },
  { isoCode2: 'IQ', translationKey: 'country.IQ' },
  { isoCode2: 'IE', translationKey: 'country.IE' },
  { isoCode2: 'IL', translationKey: 'country.IL' },
  { isoCode2: 'IT', translationKey: 'country.IT' },
  { isoCode2: 'JP', translationKey: 'country.JP' },
  { isoCode2: 'JO', translationKey: 'country.JO' },
  { isoCode2: 'KE', translationKey: 'country.KE' },
  { isoCode2: 'KW', translationKey: 'country.KW' },
  { isoCode2: 'LV', translationKey: 'country.LV' },
  { isoCode2: 'LB', translationKey: 'country.LB' },
  { isoCode2: 'LR', translationKey: 'country.LR' },
  { isoCode2: 'LT', translationKey: 'country.LT' },
  { isoCode2: 'LU', translationKey: 'country.LU' },
  { isoCode2: 'MK', translationKey: 'country.MK' },
  { isoCode2: 'MG', translationKey: 'country.MG' },
  { isoCode2: 'MY', translationKey: 'country.MY' },
  { isoCode2: 'MA', translationKey: 'country.MA' },
  { isoCode2: 'MX', translationKey: 'country.MX' },
  { isoCode2: 'NL', translationKey: 'country.NL' },
  { isoCode2: 'NG', translationKey: 'country.NG' },
  { isoCode2: 'NO', translationKey: 'country.NO' },
  { isoCode2: 'OM', translationKey: 'country.OM' },
  { isoCode2: 'PK', translationKey: 'country.PK' },
  { isoCode2: 'PE', translationKey: 'country.PE' },
  { isoCode2: 'PH', translationKey: 'country.PH' },
  { isoCode2: 'PL', translationKey: 'country.PL' },
  { isoCode2: 'PT', translationKey: 'country.PT' },
  { isoCode2: 'PR', translationKey: 'country.PR' },
  { isoCode2: 'QA', translationKey: 'country.QA' },
  { isoCode2: 'RO', translationKey: 'country.RO' },
  { isoCode2: 'RW', translationKey: 'country.RW' },
  { isoCode2: 'SA', translationKey: 'country.SA' },
  { isoCode2: 'SN', translationKey: 'country.SN' },
  { isoCode2: 'RS', translationKey: 'country.RS' },
  { isoCode2: 'SL', translationKey: 'country.SL' },
  { isoCode2: 'SG', translationKey: 'country.SG' },
  { isoCode2: 'SI', translationKey: 'country.SI' },
  { isoCode2: 'ZA', translationKey: 'country.ZA' },
  { isoCode2: 'ES', translationKey: 'country.ES' },
  { isoCode2: 'SE', translationKey: 'country.SE' },
  { isoCode2: 'TH', translationKey: 'country.TH' },
  { isoCode2: 'TN', translationKey: 'country.TN' },
  { isoCode2: 'TR', translationKey: 'country.TR' },
  { isoCode2: 'UG', translationKey: 'country.UG' },
  { isoCode2: 'UA', translationKey: 'country.UA' },
  { isoCode2: 'AE', translationKey: 'country.AE' },
  { isoCode2: 'GB', translationKey: 'country.GB' },
  { isoCode2: 'VE', translationKey: 'country.VE' },
  { isoCode2: 'YE', translationKey: 'country.YE' },
  { isoCode2: 'ZM', translationKey: 'country.ZM' },
  { isoCode2: 'AM', translationKey: 'country.AM' },
  { isoCode2: 'CR', translationKey: 'country.CR' },
  { isoCode2: 'DO', translationKey: 'country.DO' },
  { isoCode2: 'LK', translationKey: 'country.LK' },
  { isoCode2: 'CH', translationKey: 'country.CH' },
  { isoCode2: 'JM', translationKey: 'country.JM' },
  { isoCode2: 'KZ', translationKey: 'country.KZ' },
  { isoCode2: 'NZ', translationKey: 'country.NZ' },
  { isoCode2: 'NP', translationKey: 'country.NP' },
  { isoCode2: 'ZW', translationKey: 'country.ZW' },
  { isoCode2: 'EC', translationKey: 'country.EC' },
  { isoCode2: 'GT', translationKey: 'country.GT' },
  { isoCode2: 'BD', translationKey: 'country.BD' },
  { isoCode2: 'UY', translationKey: 'country.UY' },
  { isoCode2: 'MN', translationKey: 'country.MN' },
  { isoCode2: 'KH', translationKey: 'country.KH' },
  { isoCode2: 'ME', translationKey: 'country.ME' },
  { isoCode2: 'BW', translationKey: 'country.BW' },
  { isoCode2: 'KG', translationKey: 'country.KG' },
  { isoCode2: 'TJ', translationKey: 'country.TJ' },
  { isoCode2: 'UZ', translationKey: 'country.UZ' },
  { isoCode2: 'AZ', translationKey: 'country.AZ' },
  { isoCode2: 'TM', translationKey: 'country.TM' },
  { isoCode2: 'TT', translationKey: 'country.TT' },
  { isoCode2: 'GN', translationKey: 'country.GN' },
  { isoCode2: 'AS', translationKey: 'country.AS' },
  { isoCode2: 'AD', translationKey: 'country.AD' },
  { isoCode2: 'AQ', translationKey: 'country.AQ' },
  { isoCode2: 'AG', translationKey: 'country.AG' },
  { isoCode2: 'AW', translationKey: 'country.AW' },
  { isoCode2: 'BS', translationKey: 'country.BS' },
  { isoCode2: 'BB', translationKey: 'country.BB' },
  { isoCode2: 'BM', translationKey: 'country.BM' },
  { isoCode2: 'BY', translationKey: 'country.BY' },
  { isoCode2: 'BZ', translationKey: 'country.BZ' },
  { isoCode2: 'BJ', translationKey: 'country.BJ' },
  { isoCode2: 'BT', translationKey: 'country.BT' },
  { isoCode2: 'BO', translationKey: 'country.BO' },
  { isoCode2: 'BA', translationKey: 'country.BA' },
  { isoCode2: 'BV', translationKey: 'country.BV' },
  { isoCode2: 'GU', translationKey: 'country.GU' },
  { isoCode2: 'GY', translationKey: 'country.GY' },
  { isoCode2: 'HT', translationKey: 'country.HT' },
  { isoCode2: 'HM', translationKey: 'country.HM' },
  { isoCode2: 'HN', translationKey: 'country.HN' },
  { isoCode2: 'IS', translationKey: 'country.IS' },
  { isoCode2: 'KI', translationKey: 'country.KI' },
  { isoCode2: 'KR', translationKey: 'country.KR' },
  { isoCode2: 'LA', translationKey: 'country.LA' },
  { isoCode2: 'WS', translationKey: 'country.WS' },
  { isoCode2: 'SM', translationKey: 'country.SM' },
  { isoCode2: 'ST', translationKey: 'country.ST' },
  { isoCode2: 'SC', translationKey: 'country.SC' },
  { isoCode2: 'SK', translationKey: 'country.SK' },
  { isoCode2: 'SB', translationKey: 'country.SB' },
  { isoCode2: 'SO', translationKey: 'country.SO' },
  { isoCode2: 'GS', translationKey: 'country.GS' },
  { isoCode2: 'SH', translationKey: 'country.SH' },
  { isoCode2: 'PM', translationKey: 'country.PM' },
  { isoCode2: 'SR', translationKey: 'country.SR' },
  { isoCode2: 'SJ', translationKey: 'country.SJ' },
  { isoCode2: 'IO', translationKey: 'country.IO' },
  { isoCode2: 'BN', translationKey: 'country.BN' },
  { isoCode2: 'BF', translationKey: 'country.BF' },
  { isoCode2: 'BI', translationKey: 'country.BI' },
  { isoCode2: 'KY', translationKey: 'country.KY' },
  { isoCode2: 'CF', translationKey: 'country.CF' },
  { isoCode2: 'TD', translationKey: 'country.TD' },
  { isoCode2: 'CX', translationKey: 'country.CX' },
  { isoCode2: 'CC', translationKey: 'country.CC' },
  { isoCode2: 'KM', translationKey: 'country.KM' },
  { isoCode2: 'CG', translationKey: 'country.CG' },
  { isoCode2: 'CK', translationKey: 'country.CK' },
  { isoCode2: 'CI', translationKey: 'country.CI' },
  { isoCode2: 'HR', translationKey: 'country.HR' },
  { isoCode2: 'CY', translationKey: 'country.CY' },
  { isoCode2: 'DJ', translationKey: 'country.DJ' },
  { isoCode2: 'LS', translationKey: 'country.LS' },
  { isoCode2: 'LY', translationKey: 'country.LY' },
  { isoCode2: 'LI', translationKey: 'country.LI' },
  { isoCode2: 'MO', translationKey: 'country.MO' },
  { isoCode2: 'MW', translationKey: 'country.MW' },
  { isoCode2: 'MV', translationKey: 'country.MV' },
  { isoCode2: 'ML', translationKey: 'country.ML' },
  { isoCode2: 'MT', translationKey: 'country.MT' },
  { isoCode2: 'MH', translationKey: 'country.MH' },
  { isoCode2: 'MQ', translationKey: 'country.MQ' },
  { isoCode2: 'MR', translationKey: 'country.MR' },
  { isoCode2: 'MU', translationKey: 'country.MU' },
  { isoCode2: 'YT', translationKey: 'country.YT' },
  { isoCode2: 'FM', translationKey: 'country.FM' },
  { isoCode2: 'MD', translationKey: 'country.MD' },
  { isoCode2: 'MC', translationKey: 'country.MC' },
  { isoCode2: 'MS', translationKey: 'country.MS' },
  { isoCode2: 'MZ', translationKey: 'country.MZ' },
  { isoCode2: 'MM', translationKey: 'country.MM' },
  { isoCode2: 'NA', translationKey: 'country.NA' },
  { isoCode2: 'NR', translationKey: 'country.NR' },
  { isoCode2: 'SZ', translationKey: 'country.SZ' },
  { isoCode2: 'TW', translationKey: 'country.TW' },
  { isoCode2: 'TZ', translationKey: 'country.TZ' },
  { isoCode2: 'TG', translationKey: 'country.TG' },
  { isoCode2: 'TK', translationKey: 'country.TK' },
  { isoCode2: 'TO', translationKey: 'country.TO' },
  { isoCode2: 'TC', translationKey: 'country.TC' },
  { isoCode2: 'TV', translationKey: 'country.TV' },
  { isoCode2: 'DM', translationKey: 'country.DM' },
  { isoCode2: 'TL', translationKey: 'country.TL' },
  { isoCode2: 'SV', translationKey: 'country.SV' },
  { isoCode2: 'GQ', translationKey: 'country.GQ' },
  { isoCode2: 'ER', translationKey: 'country.ER' },
  { isoCode2: 'FK', translationKey: 'country.FK' },
  { isoCode2: 'FO', translationKey: 'country.FO' },
  { isoCode2: 'FJ', translationKey: 'country.FJ' },
  { isoCode2: 'GF', translationKey: 'country.GF' },
  { isoCode2: 'PF', translationKey: 'country.PF' },
  { isoCode2: 'TF', translationKey: 'country.TF' },
  { isoCode2: 'GE', translationKey: 'country.GE' },
  { isoCode2: 'GD', translationKey: 'country.GD' },
  { isoCode2: 'GL', translationKey: 'country.GL' },
  { isoCode2: 'GP', translationKey: 'country.GP' },
  { isoCode2: 'NC', translationKey: 'country.NC' },
  { isoCode2: 'NI', translationKey: 'country.NI' },
  { isoCode2: 'NE', translationKey: 'country.NE' },
  { isoCode2: 'NU', translationKey: 'country.NU' },
  { isoCode2: 'NF', translationKey: 'country.NF' },
  { isoCode2: 'MP', translationKey: 'country.MP' },
  { isoCode2: 'PW', translationKey: 'country.PW' },
  { isoCode2: 'PA', translationKey: 'country.PA' },
  { isoCode2: 'PG', translationKey: 'country.PG' },
  { isoCode2: 'PY', translationKey: 'country.PY' },
  { isoCode2: 'PN', translationKey: 'country.PN' },
  { isoCode2: 'RE', translationKey: 'country.RE' },
  { isoCode2: 'RU', translationKey: 'country.RU' },
  { isoCode2: 'KN', translationKey: 'country.KN' },
  { isoCode2: 'LC', translationKey: 'country.LC' },
  { isoCode2: 'VC', translationKey: 'country.VC' },
  { isoCode2: 'UM', translationKey: 'country.UM' },
  { isoCode2: 'VU', translationKey: 'country.VU' },
  { isoCode2: 'VA', translationKey: 'country.VA' },
  { isoCode2: 'VN', translationKey: 'country.VN' },
  { isoCode2: 'VG', translationKey: 'country.VG' },
  { isoCode2: 'VI', translationKey: 'country.VI' },
  { isoCode2: 'WF', translationKey: 'country.WF' },
  { isoCode2: 'EH', translationKey: 'country.EH' },
  { isoCode2: 'GW', translationKey: 'country.GW' },
  { isoCode2: 'GI', translationKey: 'country.GI' },
  { isoCode2: 'SS', translationKey: 'country.SS' },
  { isoCode2: 'CW', translationKey: 'country.CW' },
  { isoCode2: 'AX', translationKey: 'country.AX' },
  { isoCode2: 'AI', translationKey: 'country.AI' },
  { isoCode2: 'CD', translationKey: 'country.CD' },
  { isoCode2: 'GG', translationKey: 'country.GG' },
  { isoCode2: 'IM', translationKey: 'country.IM' },
  { isoCode2: 'JE', translationKey: 'country.JE' },
  { isoCode2: 'PS', translationKey: 'country.PS' },
  { isoCode2: 'MF', translationKey: 'country.MF' },
  { isoCode2: 'SX', translationKey: 'country.SX' },
  { isoCode2: 'SD', translationKey: 'country.SD' },
  { isoCode2: 'BQ', translationKey: 'country.BQ' },
  { isoCode2: 'BL', translationKey: 'country.BL' },
  // Removed for Embargo
  // { isoCode2: 'CU', translationKey: 'country.CU' },
  // { isoCode2: 'IR', translationKey: 'country.IR' },
  // { isoCode2: 'KP', translationKey: 'country.KP' },
  // { isoCode2: 'SY', translationKey: 'country.SY' },
  // Removed for Embargo
];

export default countries;
