export default {
  'billing.address': 'Διεύθυνση',
  'billing.addressConfirmation': 'Επιβεβαίωση διεύθυνσης',
  'billing.addressConfirmationDescription':
    'Βρέθηκε επαληθευμένη διεύθυνση βάσει της αρχικής διεύθυνσης που καταχωρήθηκε. Επιλέξτε "Επιβεβαίωση" για να αποδεχτείτε την νέα διεύθυνση ή "Ακύρωση" για να κάνετε αλλαγές.',
  'billing.billing': 'Χρέωση',
  'billing.billingInformation': 'Στοιχεία χρέωσης',
  'billing.cancelAddressConfirmation': 'Ακύρωση',
  'billing.caState': 'Επαρχία',
  'billing.city': 'Πόλη',
  'billing.confirmAddress': 'Επιβεβαίωση',
  'billing.confirmEmail': 'Επιβεβαίωση Email',
  'billing.country': 'Χώρα',
  'billing.edit': 'Επεξεργασία',
  'billing.email': 'Email',
  'billing.errorObtainingCountries': 'Συνέβη σφάλμα κατά τη λήψη της διαθέσιμης λίστας χωρών',
  'billing.errorValidatingAddress': 'Άκυρη διεύθυνση χρέωσης',
  'billing.firstName': 'Όνομα',
  'billing.knownState': 'Πολιτεία',
  'billing.lastName': 'Επώνυμο',
  'billing.loadingCountries': 'Γίνεται φόρτωση χωρών...',
  'billing.memo': 'Σημείωμα/Αριθμός παραγγελίας αγοράς',
  'billing.organizationName': 'Όνομα οργανισμού',
  'billing.originalAddress': 'Αρχική διεύθυνση',
  'billing.postalCode': 'Ταχ. κωδικός',
  'billing.save': 'Αποθήκευση',
  'billing.state': 'Πολιτεία/Επαρχία/Περιοχή',
  'billing.taxId': 'ΑΦΜ',
  'billing.useBillingInformation': 'Χρήση στοιχείων χρέωσης',
  'billing.usState': 'Πολιτεία',
  'billing.validatedAddress': 'Επικυρωμένη διεύθυνση',
  'billing.VATTaxID': 'ΑΦΜ',
  'cardBrand.amex': 'American Express',
  'cardBrand.cartes_bancaires': 'Cartes Bancaires',
  'cardBrand.diners': "Diner's Club",
  'cardBrand.discover': 'Discover',
  'cardBrand.jcb': 'JCB',
  'cardBrand.mastercard': 'Mastercard',
  'cardBrand.unionpay': 'UnionPay',
  'cardBrand.visa': 'Visa',
  'field.invalidEmail': 'Καταχωρήστε έγκυρο email',
  'field.requiredField': 'Αυτό το πεδίο είναι υποχρεωτικό',
  'field.unmatchingEmails': 'Τα email δεν συμφωνούν',
  'global.AWS_Cobranding':
    'Η Xvoucher είναι εξουσιοδοτημένος μεταπωλητής αυτού του προγράμματος εκπαίδευσης της Amazon Web Services.',
  'global.backToAWS': 'Επιστροφή στην AWS',
  'global.backToCart': 'Πίσω',
  'global.change': 'Αλλαγή',
  'global.contactSupport': 'Επικοινωνία με υποστήριξη',
  'global.defaultSelectOption': 'Καθορίστε μια επιλογή',
  'global.expiredSession': 'Η συνεδρία έληξε',
  'global.expiringSession': 'Η συνεδρία σας θα λήξει σύντομα',
  'global.missingPaymentTransaction': 'Η συναλλαγή πληρωμής δεν βρέθηκε',
  'global.more': 'περισσότερα',
  'global.allRightsReserved': 'mε επιφύλαξη παντός δικαιώματος',
  'global.pageNotFound': 'Η σελίδα που αναζητάτε δεν βρέθηκε',
  'global.poweredBy': 'Παρέχεται από',
  'global.noRemainingAttempts' : 'Η προσπάθεια πληρωμής απέτυχε πολλές φορές. Θα ανακατευθυνθείτε πίσω.',
  'global.rightsReserved': 'Με επιφύλαξη παντός δικαιώματος',
  'global.support': 'Υποστήριξη',
  'global.terms': 'Όροι',
  'global.unauthorizedAccess': 'Δεν έχετε εξουσιοδότηση πρόσβασης αυτής της σελίδας',
  'invoice.email': 'Email',
  'invoice.errorOnObtainingInvoice': 'Συνέβη σφάλμα κατά την προβολή του τιμολογίου',
  'invoice.errorSendingEmail': 'Συνέβη σφάλμα κατά την αποστολή του τιμολογίου',
  'invoice.invoiceSent': 'Αποστάλθηκε το τιμολόγιο',
  'invoice.mailPlaceholder': 'e.g. "john@doe.com; jane@doe.com',
  'invoice.noOrder': 'Δεν υπάρχει παραγγελία για προβολή',
  'invoice.print': 'Εκτύπωση',
  'invoice.send': 'Αποστολή',
  'invoice.sessionExpired': 'Η περίοδος τιμολογίου έληξε',
  'invoice.wrongMailFormat':
    'Μπορούν να χρησιμοποιηθούν έως 10 έγκυρες διευθύνσεις email διαχωρισμένες με ερωτηματικό ";", π.χ. "john@doe.com; jane@doe.com"',
  'order.errorGettingOrder': 'Συνέβη σφάλμα κατά την απόπειρα λήψης της παραγγελίας',
  'order.errorUpdatingOrder': 'Συνέβη σφάλμα κατά την απόπειρα επικαιροποίησης της παραγγελίας',
  'order.invalidToken': 'Η συναλλαγή είναι άκυρη',
  'order.locationNotApproved': 'Δυστυχώς, αυτή η τάξη δεν είναι διαθέσιμη για αγορά στην περιοχή σας',
  'order.moreDetailsAvailable': 'Περισσότερες λεπτομέρειες',
  'order.noOrder': 'Δεν υπάρχουν λεπτομέρειες παραγγελίας για προβολή',
  'order.noToken': 'Δεν βρέθηκαν πληροφορίες συναλλαγής',
  'order.subtotal': 'Υποσύνολο',
  'order.title': 'Στοιχεία παραγγελίας',
  'orderItem.ends': 'Λήγει',
  'orderItem.itemLineDiscount': 'Έκπτωση γραμμής στοιχείου',
  'orderItem.location': 'Τοποθεσία',
  'orderItem.productDiscount': 'Έκπτωση προϊόντος',
  'orderItem.starts': 'Αρχίζει',
  'orderSummary.cancel': 'Ακύρωση',
  'orderSummary.cardTypeSelectionLabel': 'Επιλέξτε τύπο πιστωτικής κάρτας',
  'orderSummary.chargedAmountLabel': 'Θα χρεωθεί η κάρτα σας',
  'orderSummary.conversionReason': 'Γιατί πρέπει να πληρώσω σε USD',
  'orderSummary.currencyChangeExplanation': 'Για πληρωμή σε {0}, επιλέξτε Visa ή MasterCard.',
  'orderSummary.discount': 'Έκπτωση',
  'orderSummary.estimatedTax': 'Φόρος',
  'orderSummary.fee': 'Χρέωση',
  'orderSummary.noOrder': 'Δεν υπάρχει σύνοψη παραγγελίας για προβολή',
  'orderSummary.orderDiscount': 'Έκπτωση παραγγελίας',
  'orderSummary.pay': 'Πληρωμή',
  'orderSummary.productDiscount': 'Έκπτωση προϊόντος',
  'orderSummary.productSubTotal': 'Υποσύνολο προϊόντος',
  'orderSummary.productTotal': 'Σύνολο προϊόντος',
  'orderSummary.title': 'Σύνοψη παραγγελίας',
  'orderSummary.total': 'Σύνολο',
  'orderSummary.totalBeforeTax': 'Σύνολο προ φόρου',
  'payment.cancelledPayment': 'Η πληρωμή ακυρώθηκε',
  'payment.confirmingTransaction': 'Γίνεται επιβεβαίωση συναλλαγής...',
  'payment.convergeFailure': 'Προέκυψε πρόβλημα κατά την αρχικοποίηση της πληρωμής',
  'payment.errorObtainingRedirectURL': 'Συνέβη σφάλμα κατά την απόπειρα λήψης URL ανακατεύθυνσης',
  'payment.errorProcessingTransaction': 'Συνέβη σφάλμα κατά την επεξεργασία της συναλλαγής',
  'payment.fraudSuspectionError':
    'Για την ασφάλειά σας, η πληρωμή δεν μπορεί να υποβληθεί και οι λεπτομέρειες έχουν προωθηθεί στην ομάδα ασφαλείας μας. Αν πιστεύετε ότι έχετε λάβει αυτό το μήνυμα από σφάλμα, επικοινωνήστε με την ομάδα υποστήριξης <a href="{0}">Xvoucher support</a>.',
  'payment.incompatibleCardForCurrency': 'Πληρωμές σε νόμισμα άλλο από USD πρέπει να γίνονται με Visa ή MasterCard',
  'payment.initFailure': 'Προέκυψε πρόβλημα κατά την αρχικοποίηση της πληρωμής',
  'payment.postalCodeRequired': 'Απαιτείται ταχυδρομικός κωδικός για αυτήν τη συναλλαγή',
  'payment.transactionApproved': 'Η συναλλαγή πιστωτικής κάρτας εγκρίθηκε',
  'payment.transactionCancelled': 'Η συναλλαγή πιστωτικής κάρτας ακυρώθηκε',
  'payment.transactionDeclined': 'Η συναλλαγή απορρίφθηκε',
  'payment.transactionDeclinedCardDetails': 'Τα στοιχεία της κάρτας σας είναι εσφαλμένα',
  'payment.transactionError': 'Η συναλλαγή πιστωτικής κάρτας είχε σφάλμα',
  'payment.transactionProcessing': 'Γίνεται επεξεργασία της συναλλαγής πιστωτικής κάρτας',
  'payment.transactionRequiresAction': 'Η συναλλαγή πιστωτικής κάρτας απαιτεί ενέργεια',
  'payment.transactionRequiresCapture': 'Η συναλλαγή πιστωτικής κάρτας απαιτεί σύλληψη',
  'payment.transactionRequiresConfirmation': 'Η συναλλαγή πιστωτικής κάρτας απαιτεί επιβεβαίωση',
  'payment.transactionRequiresPaymentMethod': 'Η συναλλαγή πιστωτικής κάρτας απαιτεί μέθοδο πληρωμής',
  'payment.unsupportedCardOfferCurrencyConversion':
    'Κάρτες {0} δεν υποστηρίζονται για πληρωμές σε {1}. Χρησιμοποιήστε διαφορετικό τύπο κάρτας ή πληρώστε σε {2}.',
  'pendo.initializationError': 'Συνέβη σφάλμα κατά την προσπάθεια αρχικοποίησης του Pendo: {0}',
  'pendo.updateError': 'Συνέβη σφάλμα κατά την απόπειρα επικαιροποίησης του Pendo: {0}',
  'test.insertToken': 'Εισάγετε τον κωδικό δοκιμής εδώ',
  'country.US': 'Ηνωμένες Πολιτείες',
  'country.CA': 'Καναδάς',
  'country.AF': 'Αφγανιστάν',
  'country.AL': 'Αλβανία',
  'country.DZ': 'Αλγερία',
  'country.AO': 'Ανγκόλα',
  'country.AR': 'Αργεντινή',
  'country.AT': 'Αυστρία',
  'country.AU': 'Αυστραλία',
  'country.BE': 'Βέλγιο',
  'country.BH': 'Μπαχρέιν',
  'country.BR': 'Βραζιλία',
  'country.BG': 'Βουλγαρία',
  'country.CM': 'Καμερούν',
  'country.CV': 'Κάμπο Βέρντε',
  'country.CL': 'Χιλή',
  'country.CN': 'Κίνα',
  'country.CO': 'Κολομβία',
  'country.CZ': 'Τσεχία',
  'country.DK': 'Δανία',
  'country.EG': 'Αίγυπτος',
  'country.ET': 'Αιθιοπία',
  'country.EE': 'Εσθονία',
  'country.FI': 'Φινλανδία',
  'country.FR': 'Γαλλία',
  'country.GA': 'Γκαμπόν',
  'country.GM': 'Γκάμπια',
  'country.GH': 'Γκάνα',
  'country.DE': 'Γερμανία',
  'country.GR': 'Ελλάδα',
  'country.HK': 'Χονγκ Κονγκ',
  'country.HU': 'Ουγγαρία',
  'country.IN': 'Ινδία',
  'country.ID': 'Ινδονησία',
  'country.IQ': 'Ιράκ',
  'country.IE': 'Ιρλανδία',
  'country.IL': 'Ισραήλ',
  'country.IT': 'Ιταλία',
  'country.JP': 'Ιαπωνία',
  'country.JO': 'Ιορδανία',
  'country.KE': 'Κένυα',
  'country.KP': 'Κορέα (Λαϊκή Δημοκρατία της)',
  'country.KW': 'Κουβέιτ',
  'country.LV': 'Λετονία',
  'country.LB': 'Λίβανος',
  'country.LR': 'Λιβηρία',
  'country.LT': 'Λιθουανία',
  'country.LU': 'Λουξεμβούργο',
  'country.MK': 'Βόρεια Μακεδονία',
  'country.MG': 'Μαδαγασκάρη',
  'country.MY': 'Μαλαισία',
  'country.MA': 'Μαρόκο',
  'country.MX': 'Μεξικό',
  'country.NL': 'Ολλανδία',
  'country.NG': 'Νιγηρία',
  'country.NO': 'Νορβηγία',
  'country.OM': 'Ομάν',
  'country.PK': 'Πακιστάν',
  'country.PE': 'Περού',
  'country.PH': 'Φιλιππίνες',
  'country.PL': 'Πολωνία',
  'country.PT': 'Πορτογαλία',
  'country.PR': 'Πουέρτο Ρίκο',
  'country.QA': 'Κατάρ',
  'country.RO': 'Ρουμανία',
  'country.RW': 'Ρουάντα',
  'country.SA': 'Σαουδική Αραβία',
  'country.SN': 'Σενεγάλη',
  'country.RS': 'Σερβία',
  'country.SL': 'Σιέρα Λέονε',
  'country.SG': 'Σιγκαπούρη',
  'country.SI': 'Σλοβενία',
  'country.ZA': 'Νότια Αφρική',
  'country.ES': 'Ισπανία',
  'country.SE': 'Σουηδία',
  'country.TH': 'Ταϊλάνδη',
  'country.TN': 'Τυνησία',
  'country.TR': 'Τουρκία',
  'country.UG': 'Ουγκάντα',
  'country.UA': 'Ουκρανία',
  'country.AE': 'Ηνωμένα Αραβικά Εμιράτα',
  'country.GB': 'Ηνωμένο Βασίλειο',
  'country.VE': 'Βενεζουέλα',
  'country.YE': 'Υεμένη',
  'country.ZM': 'Ζάμπια',
  'country.AM': 'Αρμενία',
  'country.CR': 'Κόστα Ρίκα',
  'country.DO': 'Δομινικανή Δημοκρατία',
  'country.LK': 'Σρι Λάνκα',
  'country.CH': 'Ελβετία',
  'country.JM': 'Τζαμάικα',
  'country.KZ': 'Καζακστάν',
  'country.NZ': 'Νέα Ζηλανδία',
  'country.NP': 'Νεπάλ',
  'country.ZW': 'Ζιμπάμπουε',
  'country.EC': 'Εκουαδόρ',
  'country.GT': 'Γουατεμάλα',
  'country.BD': 'Μπαγκλαντές',
  'country.UY': 'Ουρουγουάη',
  'country.MN': 'Μογγολία',
  'country.KH': 'Καμπότζη',
  'country.ME': 'Μαυροβούνιο',
  'country.BW': 'Μποτσουάνα',
  'country.KG': 'Κιργιζία',
  'country.TJ': 'Τατζικιστάν',
  'country.UZ': 'Ουζμπεκιστάν',
  'country.AZ': 'Αζερμπαϊτζάν',
  'country.TM': 'Τουρκμενιστάν',
  'country.TT': 'Τρίνινταντ και Tομπάγκο',
  'country.GN': 'Γουινέα',
  'country.AS': 'Αμερικανική Σαμόα',
  'country.AD': 'Ανδόρρα',
  'country.AQ': 'Ανταρκτική',
  'country.AG': 'Αντίγκουα και Μπαρμπούντα',
  'country.AW': 'Αρούμπα',
  'country.BS': 'Μπαχάμες',
  'country.BB': 'Μπαρμπάντος',
  'country.BM': 'Βερμούδες',
  'country.BY': 'Λευκορωσία',
  'country.BZ': 'Μπελίζ',
  'country.BJ': 'Μπενίν',
  'country.BT': 'Μπουτάν',
  'country.BO': 'Βολιβία',
  'country.BA': 'Βοσνία και Ερζεγοβίνη',
  'country.BV': 'Νήσος Μπουβέ',
  'country.GU': 'Γκουάμ',
  'country.GY': 'Γουιάνα',
  'country.HT': 'Αϊτή',
  'country.HM': 'Νήσος Χερντ και Νήσοι Μακ Ντόναλντ',
  'country.HN': 'Ονδούρα',
  'country.IS': 'Ισλανδία',
  'country.KI': 'Κιριμπάτι',
  'country.KR': 'Δημοκρατία της Κορέας',
  'country.LA': 'Λάος',
  'country.WS': 'Σαμόα',
  'country.SM': 'Σαν Μαρίνο',
  'country.ST': 'Σάο Τομέ και Πρίνσιπε',
  'country.SC': 'Σεϋχέλλες',
  'country.SK': 'Σλοβακία',
  'country.SB': 'Νήσοι Σολομώντα',
  'country.SO': 'Σομαλία',
  'country.GS': 'Νότια Γεωργία και Νότιες Νήσοι Σάντουιτς',
  'country.SH': 'Αγία Ελένη, Ασενσιόν και Τριστάν ντα Κούνια',
  'country.PM': 'Σαιν-Πιερ και Μικελόν',
  'country.SR': 'Σουρινάμ',
  'country.SJ': 'Σβάλμπαρντ και Γιαν Μάγεν',
  'country.IO': 'Βρετανικά Εδάφη Ινδικού Ωκεανού',
  'country.BN': 'Μπρουνέι',
  'country.BF': 'Μπουρκίνα Φάσο',
  'country.BI': 'Μπουρουντί',
  'country.KY': 'Νήσοι Κέιμαν',
  'country.CF': 'Κεντροαφρικανική Δημοκρατία',
  'country.TD': 'Τσαντ',
  'country.CX': 'Νήσος Χριστουγέννων',
  'country.CC': 'Νήσοι Κόκος',
  'country.KM': 'Κόμορος',
  'country.CG': 'Κόνγκο',
  'country.CK': 'Νήσοι Κουκ',
  'country.CI': 'Ακτή Ελεφαντοστού',
  'country.HR': 'Κροατία',
  'country.CY': 'Κύπρος',
  'country.DJ': 'Τζιμπουτί',
  'country.LS': 'Λεσόθο',
  'country.LY': 'Λιβύη',
  'country.LI': 'Λιχτενστάιν',
  'country.MO': 'Μακάο',
  'country.MW': 'Μαλάουι',
  'country.MV': 'Μαλδίβες',
  'country.ML': 'Μάλι',
  'country.MT': 'Μάλτα',
  'country.MH': 'Νήσοι Μάρσαλ',
  'country.MQ': 'Μαρτινίκα',
  'country.MR': 'Μαυριτανία',
  'country.MU': 'Μαυρίκιος',
  'country.YT': 'Μαγιότ',
  'country.FM': 'Μικρονησία',
  'country.MD': 'Μολδαβία',
  'country.MC': 'Μονακό',
  'country.MS': 'Μονσεράτ',
  'country.MZ': 'Μοζαμβίκη',
  'country.MM': 'Μυανμάρ',
  'country.NA': 'Ναμίμπια',
  'country.NR': 'Ναούρου',
  'country.SZ': 'Εσουατίνι',
  'country.TW': 'Ταϊβάν',
  'country.TZ': 'Τανζανία',
  'country.TG': 'Τόγκο',
  'country.TK': 'Τοκελάου',
  'country.TO': 'Τόγκα',
  'country.TC': 'Νήσοι Τερκς και Κέικος',
  'country.TV': 'Τουβαλού',
  'country.DM': 'Ντομίνικα',
  'country.TL': 'Τίμορ Λέστε',
  'country.SV': 'Ελ Σαλβαδόρ',
  'country.GQ': 'Γουινέα Ισημερινού',
  'country.ER': 'Ερυθρέα',
  'country.FK': 'Νήσοι Φώκλαντ',
  'country.FO': 'Νήσοι Φέροες',
  'country.FJ': 'Φίτζι',
  'country.GF': 'Γαλλική Γουιάνα',
  'country.PF': 'Γαλλική Πολυνησία',
  'country.TF': 'Γαλλικά Νότια Εδάφη',
  'country.GE': 'Γεωργία',
  'country.GD': 'Γρενάδα',
  'country.GL': 'Γροιλανδία',
  'country.GP': 'Γουαδελούπη',
  'country.NC': 'Νέα Καληδονία',
  'country.NI': 'Νικαράγουα',
  'country.NE': 'Νίγηρας',
  'country.NU': 'Νιούε',
  'country.NF': 'Νήσος Νόρφολκ',
  'country.MP': 'Βόρειες Νήσοι Μαριάνα',
  'country.PW': 'Παλάου',
  'country.PA': 'Παναμάς',
  'country.PG': 'Παπούα Νέα Γουινέα',
  'country.PY': 'Παραγουάη',
  'country.PN': 'Πίτκερν',
  'country.RE': 'Ρεουνιόν',
  'country.RU': 'Ρωσική δημοκρατία',
  'country.KN': 'Σαιντ Κιτς και Νέβις',
  'country.LC': 'Αγία Λουκία',
  'country.VC': 'Άγιος Βικέντιος και Γρεναδίνες',
  'country.UM': 'Απομακρυσμένες Νησίδες ΗΠΑ',
  'country.VU': 'Βανουάτου',
  'country.VA': 'Αγία Έδρα',
  'country.VN': 'Βιετνάμ',
  'country.VG': 'Παρθένες Νήσοι (Βρετανικές)',
  'country.VI': 'Παρθένες Νήσοι (ΗΠΑ)',
  'country.WF': 'Ουώλλις και Φουτούνα',
  'country.EH': 'Δυτική Σαχάρα',
  'country.GW': 'Γουινέα Μπισάου',
  'country.GI': 'Γιβραλτάρ',
  'country.SS': 'Νότιο Σουδάν',
  'country.CW': 'Κουρακάο',
  'country.AX': 'Νήσοι Άλαντ',
  'country.AI': 'Ανγκουίλα',
  'country.CD': 'Κονγκό',
  'country.CU': 'Κούβα',
  'country.GG': 'Γκέρνσεϊ',
  'country.IR': 'Ιράν',
  'country.IM': 'Νήσος Μαν',
  'country.JE': 'Τζέρσεϊ',
  'country.PS': 'Παλαιστίνη',
  'country.MF': 'Άγιος Μαρτίνος (Γαλλικό τμήμα)',
  'country.SX': 'Σιντ Μαρτέν (Ολλανδικό τμήμα)',
  'country.SD': 'Σουδάν',
  'country.SY': 'Συρία',
  'country.BQ': 'Μποναίρ, Άγιος Ευστάθιος και Σάμπα',
  'country.BL': 'Άγιος Βαρθολομαίος',
  'state.AL': 'Αλαμπάμα',
  'state.AK': 'Αλάσκα',
  'state.AZ': 'Αριζόνα',
  'state.AR': 'Άρκανσας',
  'state.CA': 'Καλιφόρνια',
  'state.CO': 'Κολοράντο',
  'state.CT': 'Κονέκτικατ',
  'state.DE': 'Ντέλαγουερ',
  'state.FL': 'Φλόριντα',
  'state.GA': 'Τζόρτζια',
  'state.HI': 'Χαβάη',
  'state.ID': 'Αϊντάχο',
  'state.IL': 'Ιλινόι',
  'state.IN': 'Ιντιάνα',
  'state.IA': 'Αϊόβα',
  'state.KS': 'Κάνσας',
  'state.KY': 'Κεντάκι',
  'state.LA': 'Λουιζιάνα',
  'state.ME': 'Μέιν',
  'state.MD': 'Μέριλαντ',
  'state.MA': 'Μασαχουσέτη',
  'state.MI': 'Μίσιγκαν',
  'state.MN': 'Μινεσότα',
  'state.MS': 'Μισισίπι',
  'state.MO': 'Μιζούρι',
  'state.MT': 'Μοντάνα',
  'state.NE': 'Νεμπράσκα',
  'state.NV': 'Νεβάδα',
  'state.NH': 'Νιου Χάμσαϊρ',
  'state.NJ': 'Νιου Τζέρσεϊ',
  'state.NM': 'Νέο Μεξικό',
  'state.NY': 'Νέα Υόρκη',
  'state.NC': 'Βόρεια Καρολίνα',
  'state.ND': 'Βόρεια Ντακότα',
  'state.OH': 'Οχάιο',
  'state.OK': 'Οκλαχόμα',
  'state.OR': 'Όρεγκον',
  'state.PA': 'Πενσυλβάνια',
  'state.RI': 'Ρόουντ Άιλαντ',
  'state.SC': 'Νότια Καρολίνα',
  'state.SD': 'Νότια Ντακότα',
  'state.TN': 'Τενεσί',
  'state.TX': 'Τέξας',
  'state.UT': 'Γιούτα',
  'state.VT': 'Βερμόντ',
  'state.VA': 'Βιρτζίνια',
  'state.WA': 'Ουάσινγκτον',
  'state.WV': 'Δυτική Βιρτζίνια',
  'state.WI': 'Ουισκόνσιν',
  'state.WY': 'Ουαϊόμινγκ',
  'state.DC': 'Περιφέρεια της Κολούμπια',
  'state.AS': 'Αμερικανική Σαμόα',
  'state.FM': 'Ομοσπονδιακές πολιτείες Μικρονησίας',
  'state.GU': 'Γκουάμ',
  'state.MH': 'Νήσοι Μάρσαλ',
  'state.MP': 'Βόρειες νήσοι Μαριάνα',
  'state.PW': 'Παλάου',
  'state.PR': 'Πουέρτο Ρίκο',
  'state.VI': 'Αμερικανικές Παρθένες Νήσοι',
  'state.AE': 'Ένοπλες δυνάμεις Ευρώπη, Μέση Ανατολή και Καναδάς',
  'state.AP': 'Ένοπλες δυνάμεις Ειρηνικός',
  'state.AA': 'Ένοπλες δυνάμεις Αμερική (εκτός Καναδά)',
  'province.AB': 'Αλμπέρτα',
  'province.BC': 'Βρετανική Κολομβία',
  'province.MB': 'Μανιτόμπα',
  'province.NB': 'Νιου Μπράνσγουικ',
  'province.NL': 'Νέα Γη και Λαμπραντόρ',
  'province.NT': 'Νοτιοδυτικά εδάφη',
  'province.NS': 'Νέα Σκοτία',
  'province.NU': 'Νούναβουτ',
  'province.ON': 'Οντάριο',
  'province.PE': 'Νήσος του Πρίγκηπα Εδουάρδου',
  'province.QC': 'Κεμπέκ',
  'province.SK': 'Σασκάτσουαν',
  'province.YT': 'Γιούκον',
  'state.AbuDhabi': 'Abu Dhabi',
  'state.Ajman': 'Ajman',
  'state.Dubai': 'Dubai',
  'state.Fujairah': 'Fujairah',
  'state.RasAlKhaimah': 'Ras Al Khaimah',
  'state.Sharjah': 'Sharjah',
  'state.UmmAlQuwain': 'Umm Al Quwain',
  'billing.aeState': 'Εμιράτο',
  'instate.AN': 'Νήσοι Ανταμάν και Νικομπάρ',
  'instate.AP': 'Άντρα Πραντές',
  'instate.AR': 'Αρουνάτσαλ Πραντές',
  'instate.AS': 'Ασάμ',
  'instate.BR': 'Μπιχάρ',
  'instate.CH': 'Chandigarh',
  'instate.CT': 'Τσατισγκάρ',
  'instate.DN': 'Dadra και Nagar Haveli',
  'instate.DD': 'Daman και Diu',
  'instate.DL': 'Δελχί',
  'instate.GA': 'Γκόα',
  'instate.GJ': 'Γκουτζαράτ',
  'instate.HR': 'Χαριάνα',
  'instate.HP': 'Χιματσάλ Πραντές',
  'instate.JK': 'Τζαμού και Κασμίρ',
  'instate.JH': 'Τζαρκάντ',
  'instate.KA': 'Καρνατάκα',
  'instate.KL': 'Κεράλα',
  'instate.LD': 'Lakshadweep',
  'instate.MP': 'Madhya Pradesh',
  'instate.MH': 'Μαχαράστρα',
  'instate.MN': 'Μανιπούρ',
  'instate.ML': 'Μεγκαλάγια',
  'instate.MZ': 'Μιζοράμ',
  'instate.NL': 'Nagaland',
  'instate.OR': 'Οντίσα',
  'instate.PY': 'Puducherry',
  'instate.PB': 'Παντζάμπ',
  'instate.RJ': 'Ρατζαστάν',
  'instate.SK': 'Σικίμ',
  'instate.TN': 'Ταμίλ Ναντού',
  'instate.TG': 'Τελαγγάνα',
  'instate.TR': 'Τριπούρα',
  'instate.UP': 'Ούταρ Πραντές',
  'instate.UT': 'Ουταραχάντ',
  'instate.WB': 'Δυτική Βεγγάλη',
  'billing.inState': 'Πολιτεία',
};
