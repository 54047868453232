export default [
  { code: 'AB', translationKey: 'province.AB' },
  { code: 'BC', translationKey: 'province.BC' },
  { code: 'MB', translationKey: 'province.MB' },
  { code: 'NB', translationKey: 'province.NB' },
  { code: 'NL', translationKey: 'province.NL' },
  { code: 'NT', translationKey: 'province.NT' },
  { code: 'NS', translationKey: 'province.NS' },
  { code: 'NU', translationKey: 'province.NU' },
  { code: 'ON', translationKey: 'province.ON' },
  { code: 'PE', translationKey: 'province.PE' },
  { code: 'QC', translationKey: 'province.QC' },
  { code: 'SK', translationKey: 'province.SK' },
  { code: 'YT', translationKey: 'province.YT' },
];
