export const unnullifyObject = obj => {
  if (!obj) return;
  const retObj = {};
  Object.keys(obj).forEach(key => {
    retObj[key] = obj[key] || '';
  });
  return retObj;
};

export const stringFormat = (str, params) =>
  params.reduce((total, curr, index) => total.replace(new RegExp(`\\{${index}\\}`, 'g'), curr), str);
