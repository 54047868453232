import * as types from './actionTypes';

const setOrderErrorMessageAction = orderErrorMessage => ({
  type: types.SET_ORDER_ERROR_MESSAGE,
  orderErrorMessage,
});

export const setOrderErrorMessage = orderErrorMessage => async dispatch => {
  dispatch(setOrderErrorMessageAction(orderErrorMessage));
};

const deleteOrderErrorMessageAction = () => ({
  type: types.DELETE_ORDER_ERROR_MESSAGE,
});

export const deleteOrderErrorMessage = () => async dispatch => {
  dispatch(deleteOrderErrorMessageAction());
};

const setBillingErrorMessageAction = billingErrorMessage => ({
  type: types.SET_BILLING_ERROR_MESSAGE,
  billingErrorMessage,
});

export const setBillingErrorMessage = billingErrorMessage => async dispatch => {
  dispatch(setBillingErrorMessageAction(billingErrorMessage));
};

const deleteBillingErrorMessageAction = () => ({
  type: types.DELETE_BILLING_ERROR_MESSAGE,
});

export const deleteBillingErrorMessage = () => async dispatch => {
  dispatch(deleteBillingErrorMessageAction());
};

const setPaymentErrorMessageAction = paymentErrorMessage => ({
  type: types.SET_PAYMENT_ERROR_MESSAGE,
  paymentErrorMessage,
});

export const setPaymentErrorMessage = paymentErrorMessage => async dispatch => {
  dispatch(setPaymentErrorMessageAction(paymentErrorMessage));
};

const deletePaymentErrorMessageAction = () => ({
  type: types.DELETE_PAYMENT_ERROR_MESSAGE,
});

export const deletePaymentErrorMessage = () => async dispatch => {
  dispatch(deletePaymentErrorMessageAction());
};
