import React from 'react';

const AnimationLine = () => (
  <svg viewBox="0 0 4000 600">
    <path
      d="M0,300 Q400,0 800,300 T1600,300, T2400,300, T3200,300 T4000,300"
      fill="none"
      stroke="#5b9044"
      strokeWidth="20"
    />
  </svg>
);

export default AnimationLine;
