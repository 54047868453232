export default {
  'billing.address': 'Adresse',
  'billing.addressConfirmation': "Confirmation de l'adresse",
  'billing.addressConfirmationDescription':
    "Une adresse validée a été trouvée sur la base de l'adresse que vous aviez donnée initialement. Sélectionnez Confirmer pour accepter l'adresse mise à jour ou Annuler pour effectuer vous-même les modifications.",
  'billing.billing': 'Facturation',
  'billing.billingInformation': 'Informations sur la Facturation',
  'billing.cancelAddressConfirmation': 'Annuler',
  'billing.caState': 'Province',
  'billing.city': 'Ville',
  'billing.confirmAddress': 'Confirmer',
  'billing.confirmEmail': "Confirmer l'adresse Électronique",
  'billing.country': 'Pays',
  'billing.edit': 'Modifier',
  'billing.email': 'Adresse Électronique',
  'billing.errorObtainingCountries': "Une erreur s'est produite lors de l'obtention de la liste des pays disponibles",
  'billing.errorValidatingAddress': 'Adresse de facturation non valide',
  'billing.firstName': 'Prénom',
  'billing.knownState': 'État',
  'billing.lastName': 'Nom de Famille',
  'billing.loadingCountries': 'Chargement des pays... ',
  'billing.memo': 'Mémorandum/Numéro de bon de commande',
  'billing.organizationName': "Nom de l'entreprise",
  'billing.originalAddress': 'Adresse Donnée Initialement',
  'billing.postalCode': 'Code Postal',
  'billing.save': 'Sauvegarder',
  'billing.state': 'État/Province/Région',
  'billing.taxId': "Numéro d'identification fiscale",
  'billing.useBillingInformation': 'Utiliser les Données de Facturation',
  'billing.usState': 'État',
  'billing.validatedAddress': 'Adresse Validée',
  'billing.VATTaxID': "Numéro d'identification fiscale",
  'cardBrand.amex': 'American Express',
  'cardBrand.cartes_bancaires': 'Cartes Bancaires',
  'cardBrand.diners': "Diner's Club",
  'cardBrand.discover': 'Discover',
  'cardBrand.jcb': 'JCB',
  'cardBrand.mastercard': 'Mastercard',
  'cardBrand.unionpay': 'UnionPay',
  'cardBrand.visa': 'Visa',
  'field.invalidEmail': 'Veuillez saisir une adresse électronique valide',
  'field.requiredField': 'Ce champ est obligatoire',
  'field.unmatchingEmails': 'Les adresses électroniques ne correspondent pas',
  'global.AWS_Cobranding': 'Xvoucher est le revendeur autorisé de ce programme de formation Amazon Web Services.',
  'global.backToAWS': 'Retour à AWS',
  'global.backToCart': 'Retour',
  'global.change': 'Modifier',
  'global.contactSupport': 'Contacter le Service Support',
  'global.defaultSelectOption': 'Sélectionnez une option',
  'global.expiredSession': 'La session a expiré',
  'global.expiringSession': 'Votre session va bientôt expirer',
  'global.missingPaymentTransaction': 'Transaction de paiement non trouvée',
  'global.more': 'plus',
  'global.allRightsReserved': 'tous droits réservés',
  'global.pageNotFound': "La page que vous cherchiez n'a pas été trouvée",
  'global.poweredBy': 'Développé par',
  'global.noRemainingAttempts' : 'La tentative de paiement a échoué plusieurs fois. Vous serez redirigé vers larrière.',
  'global.rightsReserved': 'Tous Droits Réservés',
  'global.support': 'Support',
  'global.terms': "Conditions d'utilisation",
  'global.unauthorizedAccess': "Vous n'êtes pas autorisé(e) à accéder à cette page",
  'invoice.email': 'Adresse Électronique',
  'invoice.errorOnObtainingInvoice': "Une erreur s'est produite lors de l'affichage de la facture",
  'invoice.errorSendingEmail': "Une erreur s'est produite lors de l'envoi de la facture",
  'invoice.invoiceSent': 'Facture envoyée',
  'invoice.mailPlaceholder': 'e.g. "john@doe.com; jane@doe.com',
  'invoice.noOrder': 'Aucune commande à afficher',
  'invoice.print': 'Imprimer',
  'invoice.send': 'Envoyer',
  'invoice.sessionExpired': 'Votre session de facture a expiré',
  'invoice.wrongMailFormat':
    "Vous pouvez utiliser jusqu'à 10 adresses électroniques valides séparées par des points-virgules, par exemple « john@doe.com;jane@doe.com »",
  'order.errorGettingOrder': "Une erreur s'est produite lors du chargement de la commande",
  'order.errorUpdatingOrder': "Une erreur s'est produite lors de la mise à jour de la commande",
  'order.invalidToken': "La transaction n'est pas valide",
  'order.locationNotApproved': "Désolé, ce cours n'est pas disponible à la vente dans votre région",
  'order.moreDetailsAvailable': 'Plus de détails',
  'order.noOrder': 'Aucune information sur la commande à afficher',
  'order.noToken': "Aucune information sur la transaction n'a été trouvée",
  'order.subtotal': 'Sous-total',
  'order.title': 'Détails de la Commande',
  'orderItem.ends': 'Se termine',
  'orderItem.itemLineDiscount': "Remise sur la Ligne d'article",
  'orderItem.location': 'Emplacement',
  'orderItem.productDiscount': 'Remise sur le Produit',
  'orderItem.starts': 'Commence',
  'orderSummary.cancel': 'Annuler',
  'orderSummary.cardTypeSelectionLabel': 'Sélectionnez le type de carte de crédit',
  'orderSummary.chargedAmountLabel': 'Votre carte sera débitée',
  'orderSummary.conversionReason': 'Pourquoi dois-je payer en dollars américains ?',
  'orderSummary.currencyChangeExplanation':
    'Pour effectuer un paiement en {0}, veuillez sélectionner Visa ou MasterCard.',
  'orderSummary.discount': 'Remise',
  'orderSummary.estimatedTax': 'Taxe',
  'orderSummary.fee': 'Frais',
  'orderSummary.noOrder': 'Aucun résumé de commande à afficher',
  'orderSummary.orderDiscount': 'Remise sur la Commande',
  'orderSummary.pay': 'Payer',
  'orderSummary.productDiscount': 'Remise sur le Produit',
  'orderSummary.productSubTotal': 'Sous-total sur le Produit',
  'orderSummary.productTotal': 'Total sur le Produit',
  'orderSummary.title': 'Résumé de la Commande',
  'orderSummary.total': 'Total',
  'orderSummary.totalBeforeTax': 'Total Avant Taxes',
  'payment.cancelledPayment': 'Le paiement a été annulé',
  'payment.confirmingTransaction': 'Confirmation de transaction en cours...',
  'payment.convergeFailure': 'Il y a eu un problème en essayant de procéder au paiement.',
  'payment.errorObtainingRedirectURL': "Une erreur s'est produite lors de la tentative de redirection de l'URL.",
  'payment.errorProcessingTransaction': "Une erreur s'est produite lors du traitement de la transaction",
  'payment.fraudSuspectionError':
    'Pour votre sécurité, le paiement ne peut pas être soumis et les informations concernées ont été transmises à notre équipe de sécurité. Si vous pensez avoir reçu ce message par erreur, veuillez contacter le <a href="{0}">service support de Xvoucher</a>.',
  'payment.incompatibleCardForCurrency':
    'Les paiements dans une devise autre que le dollar américain doivent être effectués avec une carte Visa ou MasterCard.',
  'payment.initFailure': 'Il y a eu un problème en essayant de procéder au paiement.',
  'payment.postalCodeRequired': 'Un code postal est requis pour cette transaction',
  'payment.transactionApproved': 'La transaction par carte de crédit a été approuvée',
  'payment.transactionCancelled': 'La transaction par carte de crédit a été annulée',
  'payment.transactionDeclined': 'Transaction refusée',
  'payment.transactionDeclinedCardDetails': 'Les informations concernant votre carte étaient incorrectes',
  'payment.transactionError': "Une erreur s'est produite dans la transaction par carte de crédit",
  'payment.transactionProcessing': 'La transaction par carte de crédit est en cours de traitement',
  'payment.transactionRequiresAction': 'La transaction par carte de crédit nécessite une action',
  'payment.transactionRequiresCapture': "La transaction par carte de crédit nécessite la saisie d'informations",
  'payment.transactionRequiresConfirmation': 'La transaction par carte de crédit nécessite une confirmation',
  'payment.transactionRequiresPaymentMethod': 'La transaction par carte de crédit nécessite un mode de paiement',
  'payment.unsupportedCardOfferCurrencyConversion':
    'Les cartes {0} ne sont pas prises en charge pour les paiements en {1}. Veuillez utiliser un autre type de carte ou payer en {2}.',
  'pendo.initializationError': "Une erreur s'est produite pendant le lancement de Pendo : {0}",
  'pendo.updateError': "Une erreur s'est produite pendant la mise à jour de Pendo : {0}",
  'test.insertToken': "Insérez la clé d'authentification ici",
  'country.US': 'États-Unis',
  'country.CA': 'Canada',
  'country.AF': 'Afghanistan',
  'country.AL': 'Albanie',
  'country.DZ': 'Algérie',
  'country.AO': 'Angola',
  'country.AR': 'Argentine',
  'country.AT': 'Autriche',
  'country.AU': 'Australie',
  'country.BE': 'Belgique',
  'country.BH': 'Bahreïn',
  'country.BR': 'Brésil',
  'country.BG': 'Bulgarie',
  'country.CM': 'Cameroun',
  'country.CV': 'Cap Vert',
  'country.CL': 'Chili',
  'country.CN': 'Chine',
  'country.CO': 'Colombie',
  'country.CZ': 'Tchéquie',
  'country.DK': 'Danemark',
  'country.EG': 'Égypte',
  'country.ET': 'Éthiopie',
  'country.EE': 'Estonie',
  'country.FI': 'Finlande',
  'country.FR': 'France',
  'country.GA': 'Gabon',
  'country.GM': 'Gambie',
  'country.GH': 'Ghana',
  'country.DE': 'Allemagne',
  'country.GR': 'Grèce',
  'country.HK': 'Hong Kong',
  'country.HU': 'Hongrie',
  'country.IN': 'Inde',
  'country.ID': 'Indonésie',
  'country.IQ': 'Irak',
  'country.IE': 'Irlande',
  'country.IL': 'Israël',
  'country.IT': 'Italie',
  'country.JP': 'Japon',
  'country.JO': 'Jordanie',
  'country.KE': 'Kenya',
  'country.KP': 'République populaire démocratique de Corée',
  'country.KW': 'Koweït',
  'country.LV': 'Lettonie',
  'country.LB': 'Liban',
  'country.LR': 'Libéria',
  'country.LT': 'Lituanie',
  'country.LU': 'Luxembourg',
  'country.MK': 'Macédoine du Nord',
  'country.MG': 'Madagascar',
  'country.MY': 'Malaisie',
  'country.MA': 'Maroc',
  'country.MX': 'Mexique',
  'country.NL': 'Pays-Bas',
  'country.NG': 'Nigéria',
  'country.NO': 'Norvège',
  'country.OM': 'Oman',
  'country.PK': 'Pakistan',
  'country.PE': 'Pérou',
  'country.PH': 'Philippines',
  'country.PL': 'Pologne',
  'country.PT': 'Portugal',
  'country.PR': 'Porto Rico',
  'country.QA': 'Qatar',
  'country.RO': 'Roumanie',
  'country.RW': 'Rwanda',
  'country.SA': 'Arabie Saoudite',
  'country.SN': 'Sénégal',
  'country.RS': 'Serbie',
  'country.SL': 'Sierra Leone',
  'country.SG': 'Singapour',
  'country.SI': 'Slovénie',
  'country.ZA': 'Afrique du Sud',
  'country.ES': 'Espagne',
  'country.SE': 'Suède',
  'country.TH': 'Thaïlande',
  'country.TN': 'Tunisie',
  'country.TR': 'Turquie',
  'country.UG': 'Ouganda',
  'country.UA': 'Ukraine',
  'country.AE': 'Émirats arabes unis',
  'country.GB': 'Royaume-Uni',
  'country.VE': 'République bolivarienne du Venezuela',
  'country.YE': 'Yémen',
  'country.ZM': 'Zambie',
  'country.AM': 'Arménie',
  'country.CR': 'Costa Rica',
  'country.DO': 'République dominicaine',
  'country.LK': 'Sri Lanka',
  'country.CH': 'Suisse',
  'country.JM': 'Jamaïque',
  'country.KZ': 'Kazakhstan',
  'country.NZ': 'Nouvelle-Zélande',
  'country.NP': 'Népal',
  'country.ZW': 'Zimbabwe',
  'country.EC': 'Équateur',
  'country.GT': 'Guatemala',
  'country.BD': 'Bangladesh',
  'country.UY': 'Uruguay',
  'country.MN': 'Mongolie',
  'country.KH': 'Cambodge',
  'country.ME': 'Monténégro',
  'country.BW': 'Botswana',
  'country.KG': 'Kirghizistan',
  'country.TJ': 'Tadjikistan',
  'country.UZ': 'Ouzbékistan',
  'country.AZ': 'Azerbaïdjan',
  'country.TM': 'Turkménistan',
  'country.TT': 'Trinité-et-Tobago',
  'country.GN': 'Guinée',
  'country.AS': 'Samoa américaine',
  'country.AD': 'Andorre',
  'country.AQ': 'Antarctique',
  'country.AG': 'Antigua-et-Barbuda',
  'country.AW': 'Aruba',
  'country.BS': 'Bahamas',
  'country.BB': 'Barbade',
  'country.BM': 'Bermudes',
  'country.BY': 'Biélorussie',
  'country.BZ': 'Belize',
  'country.BJ': 'Bénin',
  'country.BT': 'Bhoutan',
  'country.BO': 'État plurinational de Bolivie',
  'country.BA': 'Bosnie-Herzégovine',
  'country.BV': 'Île Bouvet',
  'country.GU': 'Guam',
  'country.GY': 'Guyane',
  'country.HT': 'Haïti',
  'country.HM': 'Îles Heard-et-MacDonald',
  'country.HN': 'Honduras',
  'country.IS': 'Islande',
  'country.KI': 'Kiribati',
  'country.KR': 'République de Corée',
  'country.LA': 'République démocratique populaire Lao',
  'country.WS': 'Samoa',
  'country.SM': 'Saint-Marin',
  'country.ST': 'Sao Tomé-et-Principe',
  'country.SC': 'Seychelles',
  'country.SK': 'Slovaquie',
  'country.SB': 'Îles Salomon',
  'country.SO': 'Somalie',
  'country.GS': 'Géorgie du Sud et Îles Sandwich du Sud',
  'country.SH': 'Saint Helena, Ascension, Tristan Da Cunha',
  'country.PM': 'Saint-Pierre-et-Miquelon',
  'country.SR': 'Surinam',
  'country.SJ': 'Svalbard et Jan Mayen',
  'country.IO': "Territoire britannique de l'océan Indien",
  'country.BN': 'Brunei Darussalam',
  'country.BF': 'Burkina Faso',
  'country.BI': 'Burundi',
  'country.KY': 'Îles Caïmans',
  'country.CF': 'République centrafricaine',
  'country.TD': 'Tchad',
  'country.CX': 'Île Christmas',
  'country.CC': 'Îles Cocos',
  'country.KM': 'Comores',
  'country.CG': 'Congo',
  'country.CK': 'Îles Cook',
  'country.CI': "Côte d'Ivoire",
  'country.HR': 'Croatie',
  'country.CY': 'Chypre',
  'country.DJ': 'Djibouti',
  'country.LS': 'Lesotho',
  'country.LY': 'Libye',
  'country.LI': 'Liechtenstein',
  'country.MO': 'Macao',
  'country.MW': 'Malawi',
  'country.MV': 'Maldives',
  'country.ML': 'Mali',
  'country.MT': 'Malte',
  'country.MH': 'Îles Marshall',
  'country.MQ': 'Martinique',
  'country.MR': 'Mauritanie',
  'country.MU': 'Maurice',
  'country.YT': 'Mayotte',
  'country.FM': 'États fédérés de Micronésie',
  'country.MD': 'République de Moldavie',
  'country.MC': 'Monaco',
  'country.MS': 'Montserrat',
  'country.MZ': 'Mozambique',
  'country.MM': 'Myanmar',
  'country.NA': 'Namibie',
  'country.NR': 'Nauru',
  'country.SZ': 'Eswatini',
  'country.TW': 'Taïwan (Province de Chine)',
  'country.TZ': 'République-Unie de Tanzanie',
  'country.TG': 'Togo',
  'country.TK': 'Tokelau',
  'country.TO': 'Tonga',
  'country.TC': 'Îles Turques-et-Caïques',
  'country.TV': 'Tuvalu',
  'country.DM': 'Dominique',
  'country.TL': 'République démocratique du Timor-Leste',
  'country.SV': 'El Salvador',
  'country.GQ': 'Guinée équatoriale',
  'country.ER': 'Érythrée',
  'country.FK': 'Îles Falkland (Malvinas)',
  'country.FO': 'Îles Féroé',
  'country.FJ': 'Fidji',
  'country.GF': 'Guyane française',
  'country.PF': 'Polynésie française',
  'country.TF': 'Terres australes françaises',
  'country.GE': 'Géorgie',
  'country.GD': 'Grenade',
  'country.GL': 'Groenland',
  'country.GP': 'Guadeloupe',
  'country.NC': 'Nouvelle-Calédonie',
  'country.NI': 'Nicaragua',
  'country.NE': 'Niger',
  'country.NU': 'Nioué',
  'country.NF': 'Île Norfolk',
  'country.MP': 'Îles Mariannes du Nord',
  'country.PW': 'Palaos',
  'country.PA': 'Panama',
  'country.PG': 'Papouasie-Nouvelle-Guinée',
  'country.PY': 'Paraguay',
  'country.PN': 'Pitcairn',
  'country.RE': 'La Réunion',
  'country.RU': 'Fédération de Russie',
  'country.KN': 'Saint-Kitts-et-Nevis',
  'country.LC': 'Sainte-Lucie',
  'country.VC': 'Saint-Vincent-et-les Grenadines',
  'country.UM': 'Îles mineures éloignées des États-Unis',
  'country.VU': 'Vanuatu',
  'country.VA': 'État de la Cité du Vatican',
  'country.VN': 'Vietnam',
  'country.VG': 'Îles Vierges (britanniques)',
  'country.VI': 'Îles Vierges (américaines)',
  'country.WF': 'Wallis-et-Futuna',
  'country.EH': 'Sahara occidental',
  'country.GW': 'Guinée-Bissau',
  'country.GI': 'Gibraltar',
  'country.SS': 'Soudan du Sud',
  'country.CW': 'Curaçao',
  'country.AX': 'Åland',
  'country.AI': 'Anguilla',
  'country.CD': 'République démocratique du Congo',
  'country.CU': 'Cuba',
  'country.GG': 'Guernesey',
  'country.IR': 'République islamique d’Iran',
  'country.IM': 'Île de Man',
  'country.JE': 'Jersey',
  'country.PS': 'État de la Palestine',
  'country.MF': 'Saint Martin (partie française)',
  'country.SX': 'Sint Maarten (partie néerlandaise)',
  'country.SD': 'Soudan',
  'country.SY': 'République arabe syrienne',
  'country.BQ': 'Bonaire, Saint-Eustache et Saba',
  'country.BL': 'Saint-Barthélemy',
  'state.AL': 'Alabama',
  'state.AK': 'Alaska',
  'state.AZ': 'Arizona',
  'state.AR': 'Arkansas',
  'state.CA': 'Californie',
  'state.CO': 'Colorado',
  'state.CT': 'Connecticut',
  'state.DE': 'Delaware',
  'state.FL': 'Floride',
  'state.GA': 'Géorgie',
  'state.HI': 'Hawaii',
  'state.ID': 'Idaho',
  'state.IL': 'Illinois',
  'state.IN': 'Indiana',
  'state.IA': 'Iowa',
  'state.KS': 'Kansas',
  'state.KY': 'Kentucky',
  'state.LA': 'Louisiane',
  'state.ME': 'Maine',
  'state.MD': 'Maryland',
  'state.MA': 'Massachusetts',
  'state.MI': 'Michigan',
  'state.MN': 'Minnesota',
  'state.MS': 'Mississippi',
  'state.MO': 'Missouri',
  'state.MT': 'Montana',
  'state.NE': 'Nebraska',
  'state.NV': 'Nevada',
  'state.NH': 'New Hampshire',
  'state.NJ': 'New Jersey',
  'state.NM': 'Nouveau-Mexique',
  'state.NY': 'New York',
  'state.NC': 'Caroline du Nord',
  'state.ND': 'Dakota du Nord',
  'state.OH': 'Ohio',
  'state.OK': 'Oklahoma',
  'state.OR': 'Oregon',
  'state.PA': 'Pennsylvanie',
  'state.RI': 'Rhode Island',
  'state.SC': 'Caroline du Sud',
  'state.SD': 'Dakota du Sud',
  'state.TN': 'Tennessee',
  'state.TX': 'Texas',
  'state.UT': 'Utah',
  'state.VT': 'Vermont',
  'state.VA': 'Virginie',
  'state.WA': 'Washington',
  'state.WV': 'Virginie Occidentale',
  'state.WI': 'Wisconsin',
  'state.WY': 'Wyoming',
  'state.DC': 'District of Columbia',
  'state.AS': 'Samoa américaine',
  'state.FM': ' États fédérés de Micronésie',
  'state.GU': 'Guam',
  'state.MH': ' Îles Marshall',
  'state.MP': ' Îles Mariannes du Nord',
  'state.PW': 'Palaos',
  'state.PR': 'Puerto Rico',
  'state.VI': ' Îles Vierges',
  'state.AE': 'Forces armées en Europe, au Moyen-Orient et au Canada',
  'state.AP': 'Forces armées du Pacifique',
  'state.AA': 'Forces armées des Amériques (hors Canada)',
  'province.AB': 'Alberta',
  'province.BC': 'Colombie-Britannique',
  'province.MB': 'Manitoba',
  'province.NB': 'Nouveau-Brunswick',
  'province.NL': 'Terre-Neuve-et-Labrador',
  'province.NT': 'Territoires du Nord-Ouest',
  'province.NS': 'Nouvelle-Écosse',
  'province.NU': 'Nunavut',
  'province.ON': 'Ontario',
  'province.PE': 'Île-du-Prince-Édouard',
  'province.QC': 'Québec',
  'province.SK': 'Saskatchewan',
  'province.YT': 'Yukon',
  'state.AbuDhabi': 'Abu Dhabi',
  'state.Ajman': 'Ajman',
  'state.Dubai': 'Dubai',
  'state.Fujairah': 'Fujairah',
  'state.RasAlKhaimah': 'Ras Al Khaimah',
  'state.Sharjah': 'Sharjah',
  'state.UmmAlQuwain': 'Umm Al Quwain',
  'billing.aeState': 'Émirat',
  'instate.AN': 'Îles Andaman et Nicobar',
  'instate.AP': 'Andhra Pradesh',
  'instate.AR': 'Arunachal Pradesh',
  'instate.AS': 'Assam',
  'instate.BR': 'Bihar',
  'instate.CH': 'Chandigarh',
  'instate.CT': 'Chhattisgarh',
  'instate.DN': 'Dadra et Nagar Haveli',
  'instate.DD': 'Daman et Diu',
  'instate.DL': 'Delhi',
  'instate.GA': 'Goa',
  'instate.GJ': 'Gujarat',
  'instate.HR': 'Haryana',
  'instate.HP': 'Himachal Pradesh',
  'instate.JK': 'Jammu-et-Cachemire',
  'instate.JH': 'Jharkhand',
  'instate.KA': 'Karnataka',
  'instate.KL': 'Kérala',
  'instate.LD': 'Lakshadweep',
  'instate.MP': 'Madhya Pradesh',
  'instate.MH': 'Maharashtra',
  'instate.MN': 'Manipur',
  'instate.ML': 'Meghalaya',
  'instate.MZ': 'Mizoram',
  'instate.NL': 'Nagaland',
  'instate.OR': 'Odisha',
  'instate.PY': 'Pondichéry',
  'instate.PB': 'Pendjab',
  'instate.RJ': 'Rajasthan',
  'instate.SK': 'Sikkim',
  'instate.TN': 'Tamil Nadu',
  'instate.TG': 'Télangana',
  'instate.TR': 'Tripura',
  'instate.UP': 'Uttar Pradesh',
  'instate.UT': 'Uttarakhand',
  'instate.WB': 'Bengale-Occidental',
  'billing.inState': 'État',
};
