export default {
  'billing.address': 'Anschrift',
  'billing.addressConfirmation': 'Bestätigung der Anschrift',
  'billing.addressConfirmationDescription':
    "Ausgehend von der ursprünglich eingegebenen Anschrift wurde eine validierte Anschrift gefunden. Wählen Sie 'Bestätigen' um die aktualisierte Anschrift zu übernehmen, oder 'Abbrechen' um selbst Änderungen vorzunehmen.",
  'billing.billing': 'Abrechnung',
  'billing.billingInformation': 'Abrechnungsinformationen',
  'billing.cancelAddressConfirmation': 'Abbrechen',
  'billing.caState': 'Provinz',
  'billing.city': 'Stadt',
  'billing.confirmAddress': 'Bestätigen',
  'billing.confirmEmail': 'E-Mail-Adresse Bestätigen',
  'billing.country': 'Land',
  'billing.edit': 'Bearbeiten',
  'billing.email': 'E-Mail-Adresse',
  'billing.errorObtainingCountries': 'Beim Abruf des Länderverzeichnisses ist ein Fehler aufgetreten',
  'billing.errorValidatingAddress': 'Ungültige Rechnungsadresse',
  'billing.firstName': 'Vorname',
  'billing.knownState': 'Bundesstaat',
  'billing.lastName': 'Nachname',
  'billing.loadingCountries': 'Länder werden geladen...',
  'billing.memo': 'Memo/Bestellnummer',
  'billing.organizationName': 'Name der Organisation',
  'billing.originalAddress': 'Ursprüngliche Anschrift',
  'billing.postalCode': 'Postleitzahl',
  'billing.save': 'Speichern',
  'billing.state': 'Bundesstaat/Provinz/Region',
  'billing.taxId': 'Steuernummer',
  'billing.useBillingInformation': 'Abrechnungsinformationen nutzen',
  'billing.usState': 'Bundesstaat',
  'billing.validatedAddress': 'Validierte Anschrift',
  'billing.VATTaxID': 'Steuernummer',
  'cardBrand.amex': 'American Express',
  'cardBrand.cartes_bancaires': 'Cartes Bancaires',
  'cardBrand.diners': "Diner's Club",
  'cardBrand.discover': 'Discover',
  'cardBrand.jcb': 'JCB',
  'cardBrand.mastercard': 'Mastercard',
  'cardBrand.unionpay': 'UnionPay',
  'cardBrand.visa': 'Visa',
  'field.invalidEmail': 'Bitte eine gültige E-Mail-Adresse eingeben',
  'field.requiredField': 'Dieses Feld muss ausgefüllt werden',
  'field.unmatchingEmails': 'E-Mail-Adressen stimmen nicht überein',
  'global.AWS_Cobranding':
    'Xvoucher ist der autorisierte Vertriebspartner für dieses Amazon Web Services-Schulungsprogramm.',
  'global.backToAWS': 'Zurück zu AWS',
  'global.backToCart': 'Zurück',
  'global.change': 'Ändern',
  'global.contactSupport': 'Support kontaktieren',
  'global.defaultSelectOption': 'Eine Option auswählen',
  'global.expiredSession': 'Sitzung ist abgelaufen',
  'global.expiringSession': 'Ihre Sitzung läuft bald ab',
  'global.missingPaymentTransaction': 'Zahlungstransaktion nicht gefunden',
  'global.more': 'mehr',
  'global.allRightsReserved': 'alle rechte vorbehalten',
  'global.pageNotFound': 'Die gesuchte Seite wurde nicht gefunden',
  'global.poweredBy': 'Powered by',
  'global.noRemainingAttempts' : 'Der Zahlungsversuch schlug mehrmals fehl. Sie werden zurückgeleitet.',
  'global.rightsReserved': 'Alle Rechte Vorbehalten',
  'global.support': 'Support',
  'global.terms': 'Bedingungen',
  'global.unauthorizedAccess': 'Sie sind nicht zum Zugriff auf diese Seite berechtigt',
  'invoice.email': 'E-Mail-Adresse',
  'invoice.errorOnObtainingInvoice': 'Bei der Anzeige der Rechnung ist ein Fehler aufgetreten',
  'invoice.errorSendingEmail': 'Beim Senden der Rechnung ist ein Fehler aufgetreten',
  'invoice.invoiceSent': 'Rechnung gesendet',
  'invoice.mailPlaceholder': 'e.g. "john@doe.com; jane@doe.com',
  'invoice.noOrder': 'Kein Auftrag, der angezeigt werden kann',
  'invoice.print': 'Drucken',
  'invoice.send': 'Senden',
  'invoice.sessionExpired': 'Ihre Rechnungssitzung ist abgelaufen',
  'invoice.wrongMailFormat':
    'Es dürfen bis zu 10 gültige, durch Semikolon voneinander getrennte Adressen angegeben werden, z. B. „max@mustermann.com; lieschen@mueller.com“',
  'order.errorGettingOrder': 'Beim Abruf des Auftrags ist ein Fehler aufgetreten',
  'order.errorUpdatingOrder': 'Beim Versuch, den Auftrag zu aktualisieren, ist ein Fehler aufgetreten',
  'order.invalidToken': 'Transaktion ist ungültig',
  'order.locationNotApproved': 'Diese Klasse kann in Ihrer Region leider nicht gekauft werden',
  'order.moreDetailsAvailable': 'Weitere Einzelheiten',
  'order.noOrder': 'Es gibt keine anzuzeigenden Auftragsdetails',
  'order.noToken': 'Keine Transaktionsdaten gefunden',
  'order.subtotal': 'Zwischensumme',
  'order.title': 'Auftragsdetails',
  'orderItem.ends': 'Endet',
  'orderItem.itemLineDiscount': 'Artikelpositionsrabatt',
  'orderItem.location': 'Standort',
  'orderItem.productDiscount': 'Produktrabatt',
  'orderItem.starts': 'Beginnt',
  'orderSummary.cancel': 'Abbrechen',
  'orderSummary.cardTypeSelectionLabel': 'Wählen Sie einen Kreditkartentyp',
  'orderSummary.chargedAmountLabel': 'Ihre Karte wird belastet',
  'orderSummary.conversionReason': 'Warum muss ich in USD bezahlen',
  'orderSummary.currencyChangeExplanation':
    'Um eine Zahlung in {0} vorzunehmen, wählen Sie bitte Visa oder MasterCard.',
  'orderSummary.discount': 'Rabatt',
  'orderSummary.estimatedTax': 'Steuern',
  'orderSummary.fee': 'Gebühr',
  'orderSummary.noOrder': 'Es gibt keine anzuzeigende Auftragsübersicht',
  'orderSummary.orderDiscount': 'Auftragsrabatt',
  'orderSummary.pay': 'Zahlen',
  'orderSummary.productDiscount': 'Produktrabatt',
  'orderSummary.productSubTotal': 'Zwischensumme Produkt',
  'orderSummary.productTotal': 'Produkt Gesamt',
  'orderSummary.title': 'Auftragsübersicht',
  'orderSummary.total': 'Summe',
  'orderSummary.totalBeforeTax': 'Summe vor Steuern',
  'payment.cancelledPayment': 'Zahlung wurde storniert',
  'payment.confirmingTransaction': 'Transaktion wird bestätigt...',
  'payment.convergeFailure': 'Bei dem Versuch, die Zahlung einzuleiten, ist ein Fehler aufgetreten',
  'payment.errorObtainingRedirectURL': 'Bei dem Versuch, eine Umleitungs-URL abzurufen, ist ein Fehler aufgetreten',
  'payment.errorProcessingTransaction': 'Bei der Verarbeitung der Transaktion ist ein Fehler aufgetreten',
  'payment.fraudSuspectionError':
    'Zu Ihrer Sicherheit kann die versuchte Zahlung nicht übermittelt werden; Einzelheiten wurden an unser Sicherheitsteam weitergeleitet. Wenn Sie glauben, diese Nachricht irrtümlich erhalten zu haben, wenden Sie sich bitte an <a href="{0}">Xvoucher support</a>.',
  'payment.incompatibleCardForCurrency':
    'Zahlungen in anderen Währungen als USD müssen mit Visa oder MasterCard erfolgen',
  'payment.initFailure': 'Bei dem Versuch, die Zahlung einzuleiten, ist ein Fehler aufgetreten',
  'payment.postalCodeRequired': 'Für diese Transaktion ist eine Postleitzahl erforderlich',
  'payment.transactionApproved': 'Zahlung wurde genehmigt',
  'payment.transactionCancelled': 'Zahlung wurde storniert',
  'payment.transactionDeclined': 'Die Transaktion wurde abgelehnt',
  'payment.transactionDeclinedCardDetails': 'Die Angaben zu Ihrer Karte waren nicht korrekt',
  'payment.transactionError': 'Bei der Ausführung der Transaktion ist ein Fehler aufgetreten',
  'payment.transactionProcessing': 'Kreditkartentransaktion wird bearbeitet',
  'payment.transactionRequiresAction': 'Kreditkartentransaktion erfordert Handeln',
  'payment.transactionRequiresCapture': 'Kreditkartentransaktion erfordert die Erfassung der Zahlung',
  'payment.transactionRequiresConfirmation': 'Kreditkartentransaktion erfordert eine Bestätigung',
  'payment.transactionRequiresPaymentMethod': 'Kreditkartentransaktion erfordert eine Zahlungsart',
  'payment.unsupportedCardOfferCurrencyConversion':
    '{0}-Karten werden für Zahlungen in {1} nicht unterstützt. Verwenden Sie bitte eine Karte einer anderen Marke oder zahlen Sie in {2}.',
  'pendo.initializationError': 'Beim Versuch, Pendo zu initialisieren, ist ein Fehler aufgetreten: {0}',
  'pendo.updateError': 'Beim Versuch, Pendo zu aktualisieren, ist ein Fehler aufgetreten: {0}',
  'test.insertToken': 'Test-Token hier einfügen',
  'country.US': 'Vereinigte Staaten',
  'country.CA': 'Kanada',
  'country.AF': 'Afghanistan',
  'country.AL': 'Albanien',
  'country.DZ': 'Algerien',
  'country.AO': 'Angola',
  'country.AR': 'Argentinien',
  'country.AT': 'Österreich',
  'country.AU': 'Australien',
  'country.BE': 'Belgien',
  'country.BH': 'Bahrain',
  'country.BR': 'Brasilien',
  'country.BG': 'Bulgarien',
  'country.CM': 'Kamerun',
  'country.CV': 'Kap Verde',
  'country.CL': 'Chile',
  'country.CN': 'China',
  'country.CO': 'Kolumbien',
  'country.CZ': 'Tschechische Republik',
  'country.DK': 'Dänemark',
  'country.EG': 'Ägypten',
  'country.ET': 'Äthiopien',
  'country.EE': 'Estland',
  'country.FI': 'Finnland',
  'country.FR': 'Frankreich',
  'country.GA': 'Gabun',
  'country.GM': 'Gambia',
  'country.GH': 'Ghana',
  'country.DE': 'Deutschland',
  'country.GR': 'Griechenland',
  'country.HK': 'Hongkong',
  'country.HU': 'Ungarn',
  'country.IN': 'Indien',
  'country.ID': 'Indonesien',
  'country.IQ': 'Irak',
  'country.IE': 'Irland',
  'country.IL': 'Israel',
  'country.IT': 'Italien',
  'country.JP': 'Japan',
  'country.JO': 'Jordanien',
  'country.KE': 'Kenia',
  'country.KP': 'Demokratische Volksrepublik Korea',
  'country.KW': 'Kuwait',
  'country.LV': 'Lettland',
  'country.LB': 'Libanon',
  'country.LR': 'Libéria',
  'country.LT': 'Litauen',
  'country.LU': 'Luxemburg',
  'country.MK': 'Nordmakedonien',
  'country.MG': 'Madagaskar',
  'country.MY': 'Malaysia',
  'country.MA': 'Marokko',
  'country.MX': 'Mexiko',
  'country.NL': 'Niederlande',
  'country.NG': 'Nigeria',
  'country.NO': 'Norwegen',
  'country.OM': 'Oman',
  'country.PK': 'Pakistan',
  'country.PE': 'Peru',
  'country.PH': 'Philippinen',
  'country.PL': 'Polen',
  'country.PT': 'Portugal',
  'country.PR': 'Puerto Rico',
  'country.QA': 'Katar',
  'country.RO': 'Rumänien',
  'country.RW': 'Ruanda',
  'country.SA': 'Saudi-Arabien',
  'country.SN': 'Senegal',
  'country.RS': 'Serbien',
  'country.SL': 'Sierra Leone',
  'country.SG': 'Singapur',
  'country.SI': 'Slowenien',
  'country.ZA': 'Südafrika',
  'country.ES': 'Spanien',
  'country.SE': 'Schweden',
  'country.TH': 'Thailand',
  'country.TN': 'Tunesien',
  'country.TR': 'Türkei',
  'country.UG': 'Uganda',
  'country.UA': 'Ukraine',
  'country.AE': 'Vereinigte Arabische Emirate',
  'country.GB': 'Vereinigtes Königreich',
  'country.VE': 'Venezuela',
  'country.YE': 'Jemen',
  'country.ZM': 'Sambia',
  'country.AM': 'Armenien',
  'country.CR': 'Costa Rica',
  'country.DO': 'Dominikanische Republik',
  'country.LK': 'Sri Lanka',
  'country.CH': 'Schweiz',
  'country.JM': 'Jamaika',
  'country.KZ': 'Kasachstan',
  'country.NZ': 'Neuseeland',
  'country.NP': 'Nepal',
  'country.ZW': 'Simbabwe',
  'country.EC': 'Ecuador',
  'country.GT': 'Guatemala',
  'country.BD': 'Bangladesch',
  'country.UY': 'Uruguay',
  'country.MN': 'Mongolei',
  'country.KH': 'Kambodscha',
  'country.ME': 'Montenegro',
  'country.BW': 'Botswana',
  'country.KG': 'Kirgisistan',
  'country.TJ': 'Tadschikistan',
  'country.UZ': 'Usbekistan',
  'country.AZ': 'Aserbaidschan',
  'country.TM': 'Turkmenistan',
  'country.TT': 'Trinidad und Tobago',
  'country.GN': 'Guinea',
  'country.AS': 'Amerikanisch-Samoa',
  'country.AD': 'Andorra',
  'country.AQ': 'Antarktis',
  'country.AG': 'Antigua und Barbuda',
  'country.AW': 'Aruba',
  'country.BS': 'Bahamas',
  'country.BB': 'Barbados',
  'country.BM': 'die Bermudas',
  'country.BY': 'Belarus',
  'country.BZ': 'Belize',
  'country.BJ': 'Benin',
  'country.BT': 'Bhutan',
  'country.BO': 'Bolivien',
  'country.BA': 'Bosnien und Herzegowina',
  'country.BV': 'Bouvetinsel',
  'country.GU': 'Guam',
  'country.GY': 'Guyana',
  'country.HT': 'Haiti',
  'country.HM': 'Heard und McDonaldinseln',
  'country.HN': 'Honduras',
  'country.IS': 'Island',
  'country.KI': 'Kiribati',
  'country.KR': 'Republik Korea',
  'country.LA': 'Laos',
  'country.WS': 'Samoa',
  'country.SM': 'San Marino',
  'country.ST': 'São Tomé und Príncipe',
  'country.SC': 'Seychellen',
  'country.SK': 'Slowakei',
  'country.SB': 'Salomonen',
  'country.SO': 'Somalia',
  'country.GS': 'Südgeorgien und Südliche Sandwichinseln',
  'country.SH': 'St. Helena',
  'country.PM': 'St. Pierre und Miquelon',
  'country.SR': 'Suriname',
  'country.SJ': 'Svalbard und Jan Mayen',
  'country.IO': 'Britisches Territorium im Indischen Ozean',
  'country.BN': 'Brunei',
  'country.BF': 'Burkina Faso',
  'country.BI': 'Burundi',
  'country.KY': 'Kaimaninseln',
  'country.CF': 'Zentralafrikanische Republik',
  'country.TD': 'Tschad',
  'country.CX': 'Weihnachtsinsel',
  'country.CC': 'Kokosinseln',
  'country.KM': 'Komoren',
  'country.CG': 'Kongo',
  'country.CK': 'Cookinseln',
  'country.CI': "Côte d'Ivoire",
  'country.HR': 'Kroatien',
  'country.CY': 'Zypern',
  'country.DJ': 'Dschibuti',
  'country.LS': 'Lesotho',
  'country.LY': 'Libyen',
  'country.LI': 'Liechtenstein',
  'country.MO': 'Macau',
  'country.MW': 'Malawi',
  'country.MV': 'Malediven',
  'country.ML': 'Mali',
  'country.MT': 'Malta',
  'country.MH': 'Marshallinseln',
  'country.MQ': 'Martinique',
  'country.MR': 'Mauretanien',
  'country.MU': 'Mauritius',
  'country.YT': 'Mayotte',
  'country.FM': 'Mikronesien',
  'country.MD': 'Republik Moldau',
  'country.MC': 'Monaco',
  'country.MS': 'Montserrat',
  'country.MZ': 'Mosambik',
  'country.MM': 'Myanmar',
  'country.NA': 'Namibia',
  'country.NR': 'Nauru',
  'country.SZ': 'Eswatini',
  'country.TW': 'Taiwan',
  'country.TZ': 'Tansania',
  'country.TG': 'Togo',
  'country.TK': 'Tokelau',
  'country.TO': 'Tonga',
  'country.TC': 'Turks- und Caicosinseln',
  'country.TV': 'Tuvalu',
  'country.DM': 'Dominica',
  'country.TL': 'Timor-Leste',
  'country.SV': 'El Salvador',
  'country.GQ': 'Äquatorialguinea',
  'country.ER': 'Eritrea',
  'country.FK': 'Falklandinseln',
  'country.FO': 'Färöer',
  'country.FJ': 'Fidschi',
  'country.GF': 'Französisch-Guayana',
  'country.PF': 'Französisch-Polynesien',
  'country.TF': 'Französische Gebiete im südlichen Indischen Ozean',
  'country.GE': 'Georgien',
  'country.GD': 'Grenada',
  'country.GL': 'Grönland',
  'country.GP': 'Guadeloupe',
  'country.NC': 'Neukaledonien',
  'country.NI': 'Nicaragua',
  'country.NE': 'Niger',
  'country.NU': 'Niue',
  'country.NF': 'Norfolkinsel',
  'country.MP': 'Nördliche Marianen',
  'country.PW': 'Palau',
  'country.PA': 'Panama',
  'country.PG': 'Papua-Neuguinea',
  'country.PY': 'Paraguay',
  'country.PN': 'Pitcairninseln',
  'country.RE': 'Réunion',
  'country.RU': 'Russische Föderation',
  'country.KN': 'St. Kitts und Nevis',
  'country.LC': 'St. Lucia',
  'country.VC': 'St. Vincent und die Grenadinen',
  'country.UM': 'Kleinere amerikanische Überseeinseln',
  'country.VU': 'Vanuatu',
  'country.VA': 'Vatikanstadt',
  'country.VN': 'Vietnam',
  'country.VG': 'Virgin Inseln, Britisch',
  'country.VI': 'Jungferninseln (USA)',
  'country.WF': 'Wallis und Futuna',
  'country.EH': 'Westsahara',
  'country.GW': 'Guinea-Bissau',
  'country.GI': 'Gibraltar',
  'country.SS': 'Südsudan',
  'country.CW': 'Curacao',
  'country.AX': 'Åland-Inseln',
  'country.AI': 'Anguilla',
  'country.CD': 'Demokratische Republik Kongo',
  'country.CU': 'Kuba',
  'country.GG': 'Guernsey',
  'country.IR': 'Iran',
  'country.IM': 'Isle of Man',
  'country.JE': 'Jersey',
  'country.PS': 'Palästina',
  'country.MF': 'Saint Martin (französischer Teil)',
  'country.SX': 'Sint Maarten (niederländischer Teil)',
  'country.SD': 'Sudan',
  'country.SY': 'Syrien',
  'country.BQ': 'Bonaire, Sint Eustatius und Saba',
  'country.BL': 'Heiliger Barthelemy',
  'state.AL': 'Alabama',
  'state.AK': 'Alaska',
  'state.AZ': 'Arizona',
  'state.AR': 'Arkansas',
  'state.CA': 'Kalifornien',
  'state.CO': 'Colorado',
  'state.CT': 'Connecticut',
  'state.DE': 'Delaware',
  'state.FL': 'Florida',
  'state.GA': 'Georgia',
  'state.HI': 'Hawaii',
  'state.ID': 'Idaho',
  'state.IL': 'Illinois',
  'state.IN': 'Indiana',
  'state.IA': 'Iowa',
  'state.KS': 'Kansas',
  'state.KY': 'Kentucky',
  'state.LA': 'Louisiana',
  'state.ME': 'Maine',
  'state.MD': 'Maryland',
  'state.MA': 'Massachusetts',
  'state.MI': 'Michigan',
  'state.MN': 'Minnesota',
  'state.MS': 'Mississippi',
  'state.MO': 'Missouri',
  'state.MT': 'Montana',
  'state.NE': 'Nebraska',
  'state.NV': 'Nevada',
  'state.NH': 'New Hampshire',
  'state.NJ': 'New Jersey',
  'state.NM': 'New-Mexiko',
  'state.NY': 'New York',
  'state.NC': 'North Carolina',
  'state.ND': 'Norddakota',
  'state.OH': 'Ohio',
  'state.OK': 'Oklahoma',
  'state.OR': 'Oregon',
  'state.PA': 'Pennsylvania',
  'state.RI': 'Rhode Island',
  'state.SC': 'South Carolina',
  'state.SD': 'Süddakota',
  'state.TN': 'Tennessee',
  'state.TX': 'Texas',
  'state.UT': 'Utah',
  'state.VT': 'Vermont',
  'state.VA': 'Virginia',
  'state.WA': 'Washington',
  'state.WV': 'West Virginia',
  'state.WI': 'Wisconsin',
  'state.WY': 'Wyoming',
  'state.DC': 'District of Columbia',
  'state.AS': 'Amerikanischen Samoa-Inseln',
  'state.FM': 'Die Verbündeten Staaten von Micronesia',
  'state.GU': 'Guam',
  'state.MH': 'Marshallinseln',
  'state.MP': 'Nördliche Marianneninseln',
  'state.PW': 'Palau',
  'state.PR': 'Puerto Rico',
  'state.VI': 'Jungferninseln',
  'state.AE': 'Streitkräfte Europa, Naher Osten und Kanada',
  'state.AP': 'Streitkräfte Pazifik',
  'state.AA': 'Streitkräfte Amerikas (außer Kanada)',
  'province.AB': 'Alberta',
  'province.BC': 'Britisch-Kolumbien',
  'province.MB': 'Manitoba',
  'province.NB': 'New Brunswick',
  'province.NL': 'Neufundland und Labrador',
  'province.NT': 'Nordwestliche Territorien',
  'province.NS': 'Neuschottland',
  'province.NU': 'Nunavut',
  'province.ON': 'Ontario',
  'province.PE': 'Prinz Edward Insel',
  'province.QC': 'Quebec',
  'province.SK': 'Saskatchewan',
  'province.YT': 'Yukon',
  'state.AbuDhabi': 'Abu Dhabi',
  'state.Ajman': 'Ajman',
  'state.Dubai': 'Dubai',
  'state.Fujairah': 'Fujairah',
  'state.RasAlKhaimah': 'Ras Al Khaimah',
  'state.Sharjah': 'Sharjah',
  'state.UmmAlQuwain': 'Umm Al Quwain',
  'billing.aeState': 'Emirat',
  'instate.AN': 'Andamanen und Nikobareninseln',
  'instate.AP': 'Andhra Pradesh',
  'instate.AR': 'Arunachal Pradesh',
  'instate.AS': 'Assam',
  'instate.BR': 'Bihar',
  'instate.CH': 'Chandigarh',
  'instate.CT': 'Chhattisgarh',
  'instate.DN': 'Dadra und Nagar Haveli',
  'instate.DD': 'Daman und Diu',
  'instate.DL': 'Delhi',
  'instate.GA': 'Goa',
  'instate.GJ': 'Gujarat',
  'instate.HR': 'Haryana',
  'instate.HP': 'Himachal Pradesh',
  'instate.JK': 'Jammu und Kaschmir',
  'instate.JH': 'Jharkhand',
  'instate.KA': 'Karnataka',
  'instate.KL': 'Kerala',
  'instate.LD': 'Lakshadweep',
  'instate.MP': 'Madhya Pradesh',
  'instate.MH': 'Maharashtra',
  'instate.MN': 'Manipur',
  'instate.ML': 'Meghalaya',
  'instate.MZ': 'Mizoram',
  'instate.NL': 'Nagaland',
  'instate.OR': 'Odisha',
  'instate.PY': 'Puducherry',
  'instate.PB': 'Punjab',
  'instate.RJ': 'Rajasthan',
  'instate.SK': 'Sikkim',
  'instate.TN': 'Tamil Nadu',
  'instate.TG': 'Telangana',
  'instate.TR': 'Tripura',
  'instate.UP': 'Uttar Pradesh',
  'instate.UT': 'Uttarakhand',
  'instate.WB': 'West Bengal',
  'billing.inState': 'Bundesstaat',
};
