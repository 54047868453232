import React from 'react';
import cn from 'classnames';
import { object, func, string } from 'prop-types';
import { useIntl } from 'react-intl';
import BillingInformationDisplay from './BillingInformationDisplay';

const BillingInformationConfirmation = ({
  originalAddressInfo,
  validatedAddressInfo,
  onClick,
  clickMsg,
  onClose,
  closeMsg,
}) => {
  const {
    address1: originalAddress1,
    address2: originalAddress2,
    address3: originalAddress3,
    address4: originalAddress4,
    city: originalCity,
    state: originalState,
    postalCode: originalPostalCode,
    country: originalCountry,
  } = originalAddressInfo;

  const {
    address1: validatedAddress1,
    address2: validatedAddress2,
    address3: validatedAddress3,
    address4: validatedAddress4,
    city: validatedCity,
    state: validatedState,
    postalCode: validatedPostalCode,
    country: validatedCountry,
  } = validatedAddressInfo;

  const originalAddress = [originalAddress1, originalAddress2, originalAddress3, originalAddress4]
    .filter(elem => elem)
    .join(', ');
  const validatedAddress = [validatedAddress1, validatedAddress2, validatedAddress3, validatedAddress4]
    .filter(elem => elem)
    .join(', ');

  const intl = useIntl();

  return (
    <div className="billing-confirmation">
      <h3 className="bold">{intl.formatMessage({ id: 'billing.billing' })}</h3>
      <h4 className="no-padding-top">{intl.formatMessage({ id: 'billing.addressConfirmationDescription' })}</h4>
      <div className="billing-confirmation-addresses">
        <div className="billing-confirmation-address">
          <BillingInformationDisplay
            title={intl.formatMessage({ id: 'billing.originalAddress' })}
            address={originalAddress}
            city={originalCity}
            state={originalState}
            postalCode={originalPostalCode}
            country={originalCountry}
          />
        </div>
        <div className="billing-confirmation-address">
          <BillingInformationDisplay
            title={intl.formatMessage({ id: 'billing.validatedAddress' })}
            address={validatedAddress}
            city={validatedCity}
            state={validatedState}
            postalCode={validatedPostalCode}
            country={validatedCountry}
          />
        </div>
      </div>
      <div className="modal-buttons">
        <button type="button" id="AcceptAddressSuggestion" className={cn('primary-button', 'ml-1r')} onClick={onClick}>
          {clickMsg}
        </button>
        <button
          type="button"
          id="CancelAddressSuggestion"
          className={cn('primary-button cancel', 'ml-1r')}
          onClick={onClose}
        >
          {closeMsg}
        </button>
      </div>
    </div>
  );
};

BillingInformationConfirmation.propTypes = {
  originalAddressInfo: object,
  validatedAddressInfo: object,
  onClick: func,
  clickMsg: string,
  onClose: func,
  closeMsg: string,
};

export default BillingInformationConfirmation;
