import React from 'react';
import { connect } from 'react-redux';
import { string } from 'prop-types';
import cn from 'classnames';
import logoImgGeneric from '../../assets/Xvoucher_logo_Horizontal_white.svg';
import '../../styles/components/header.scss';
import Cobranding from './Cobranding';

const Header = ({ supplierTag, error }) => (
  <div
    className={cn(
      'header',
      { 'no-background': !supplierTag && !error },
      { 'color-white': supplierTag?.includes('microfocus') }
    )}
  >
    {(supplierTag || error) && (
      <>
        <div className="header-logo__container">
          <div className="header-logo">
            <img src={logoImgGeneric} className="header-logo-img" alt="logo" />
          </div>
        </div>
        {supplierTag?.includes('kiku') && <Cobranding />}
      </>
    )}
  </div>
);

Header.propTypes = {
  supplierTag: string,
  error: string,
};

const mapState = ({ order }) => ({
  supplierTag: order.supplierTag,
  error: order.globalError,
});

export default connect(mapState)(Header);
