export default {
  // global
  'global.change': 'Change',
  'global.expiredSession': 'Session has expired',
  'global.expiringSession': 'Your session will expire soon',
  'global.pageNotFound': 'The page you were looking for was not found',
  'global.backToCart': 'Back',
  'global.backToAWS': 'Back to AWS',
  'global.unauthorizedAccess': 'You are not authorized to see this page',
  'global.missingPaymentTransaction': 'Payment transaction not found',
  'global.defaultSelectOption': 'Select an option',
  'global.contactSupport': 'Contact Support',
  'global.noRemainingAttempts' : 'The payment attempt failed multiple times. You will be redirected back.',
  'global.rightsReserved': 'All Rights Reserved',
  'global.AWS_Cobranding': 'Xvoucher is the authorized reseller of this Amazon Web Services training program.',
  'global.support': 'Support',
  'global.terms': 'Terms',
  'global.poweredBy': 'Powered by',
  'global.more': 'more',
  'global.allRightsReserved': 'all rights reserved',

  // fields
  'field.requiredField': 'This field is required',
  'field.invalidEmail': 'Please enter a valid email',
  'field.unmatchingEmails': 'Emails do not match',

  // order summary
  'orderSummary.title': 'Summary',
  'orderSummary.totalBeforeTax': 'Total Before Tax',
  'orderSummary.productDiscount': 'Product Discount',
  'orderSummary.productTotal': 'Product Total',
  'orderSummary.chargedAmountLabel': 'Your card will be charged',
  'orderSummary.currencyChangeExplanation': 'To make a payment in {0}, please select Mastercard or Visa.',
  'orderSummary.productSubTotal': 'Product Subtotal',
  'orderSummary.orderDiscount': 'Order Discount',
  'orderSummary.discount': 'Discount',
  'orderSummary.estimatedTax': 'Tax',
  'orderSummary.fee': 'Fee',
  'orderSummary.total': 'Total',
  'orderSummary.cardTypeSelectionLabel': 'Select a Credit Card Type',
  'orderSummary.pay': 'Pay',
  'orderSummary.cancel': 'Cancel',
  'orderSummary.noOrder': 'No order summary to display',
  'orderSummary.conversionReason': 'Why do I need to pay in USD',

  // order item
  'orderItem.starts': 'Starts',
  'orderItem.ends': 'Ends',
  'orderItem.location': 'Location',
  'orderItem.productDiscount': 'Product Discount',
  'orderItem.itemLineDiscount': 'Item Line Discount',

  // order
  'order.title': 'Order',
  'order.noOrder': 'No order details to display',
  'order.invalidToken': 'Transaction is invalid',
  'order.noToken': 'No transaction information found',
  'order.locationNotApproved': "Sorry, this class isn't available for purchase in your area",
  'order.errorGettingOrder': 'An error ocurred when trying to get the order',
  'order.errorUpdatingOrder': 'An error ocurred when trying to update the order',
  'order.subtotal': 'Product Total',
  'order.moreDetailsAvailable': 'More details',

  // billing information
  'billing.billing': 'Billing',
  'billing.billingInformation': 'Billing Information',
  'billing.useBillingInformation': 'Use Billing Information',
  'billing.firstName': 'First Name',
  'billing.lastName': 'Last Name',
  'billing.email': 'Email',
  'billing.confirmEmail': 'Confirm Email',
  'billing.address': 'Address',
  'billing.city': 'City',
  'billing.country': 'Country',
  'billing.state': 'State/Province/Region',
  'billing.usState': 'State',
  'billing.caState': 'Province',
  'billing.postalCode': 'Postal Code',
  'billing.errorObtainingCountries': 'There was an error while obtaining available country list',
  'billing.addressConfirmation': 'Address Confirmation',
  'billing.addressConfirmationDescription':
    'A validated address was found based on the original address entered. Select Confirm to accept the updated address or Cancel to make any modifications yourself.',
  'billing.originalAddress': 'Original Address',
  'billing.validatedAddress': 'Validated Address',
  'billing.confirmAddress': 'Confirm',
  'billing.cancelAddressConfirmation': 'Cancel',
  'billing.errorValidatingAddress': 'Invalid billing address',
  'billing.loadingCountries': 'Loading countries...',
  'billing.organizationName': 'Organization Name',
  'billing.taxId': 'Tax ID',
  'billing.save': 'Save',
  'billing.edit': 'Edit',
  'billing.memo': 'Memo / PO Number',

  // Payment
  'payment.cancelledPayment': 'Payment was cancelled',
  'payment.initFailure': 'There was a problem trying to initialize the payment',
  'payment.errorProcessingTransaction': 'There was an error while processing the transaction',
  'payment.errorObtainingRedirectURL': 'An error ocurred when trying to obtain redirect URL',
  'payment.confirmingTransaction': 'Confirming transaction...',
  'payment.transactionApproved': 'Credit card transaction was approved',
  'payment.transactionCancelled': 'Credit card transaction was cancelled',
  'payment.transactionDeclined': 'Your card was declined',
  'payment.transactionError': 'Credit card transaction had an error',
  'payment.transactionProcessing': 'Credit card transaction is processing',
  'payment.transactionRequiresAction': 'Credit card transaction requires action',
  'payment.transactionRequiresCapture': 'Credit card transaction requires capture',
  'payment.transactionRequiresConfirmation': 'Credit card transaction requires confirmation',
  'payment.transactionRequiresPaymentMethod': 'Credit card transaction requires payment method',
  'payment.transactionDeclinedCardDetails': 'Your card details were incorrect',
  'payment.unsupportedCardOfferCurrencyConversion':
    "{0} cards aren't supported for payments in {1}. Please use a different card type or pay in {2}.",
  'payment.fraudSuspectionError':
    'For your security, the attempted payment cannot be submitted and details have been forwarded to our security team. If you believe you have received this message in error, please contact <a href="{0}">Xvoucher support</a>',
  'payment.incompatibleCardForCurrency': 'Payments in currency other than USD must be made using Visa or MasterCard',
  'payment.postalCodeRequired': 'A postal code is required for this transaction',
  // Invoice
  'invoice.wrongMailFormat': 'Up to 10 valid semicolon-separated mails may be used. e.g. "john@doe.com; jane@doe.com"',
  'invoice.mailPlaceholder': 'e.g. "john@doe.com; jane@doe.com"',
  'invoice.invoiceSent': 'Invoice sent',
  'invoice.email': 'Email',
  'invoice.errorSendingEmail': 'There was an error while sending the invoice',
  'invoice.noOrder': 'No order to be displayed',
  'invoice.errorOnObtainingInvoice': 'There was an error while displaying the invoice',
  'invoice.send': 'Send',
  'invoice.print': 'Print',
  'invoice.sessionExpired': 'Your invoice session has expired',

  // Pendo
  'pendo.initializationError': 'An error ocurred when trying to initialize Pendo: {0}',
  'pendo.updateError': 'An error ocurred when trying to update Pendo: {0}',

  // Card Brands
  'cardBrand.amex': 'American Express',
  'cardBrand.cartes_bancaires': 'Cartes Bancaires',
  'cardBrand.diners': "Diner's Club",
  'cardBrand.discover': 'Discover',
  'cardBrand.jcb': 'JCB',
  'cardBrand.mastercard': 'Mastercard',
  'cardBrand.visa': 'Visa',
  'cardBrand.unionpay': 'UnionPay',

  // Test
  'test.insertToken': 'Insert test token here',
  'country.US': 'United States',
  'country.CA': 'Canada',
  'country.AF': 'Afghanistan',
  'country.AL': 'Albania',
  'country.DZ': 'Algeria',
  'country.AO': 'Angola',
  'country.AR': 'Argentina',
  'country.AT': 'Austria',
  'country.AU': 'Australia',
  'country.BE': 'Belgium',
  'country.BH': 'Bahrain',
  'country.BR': 'Brazil',
  'country.BG': 'Bulgaria',
  'country.CM': 'Cameroon',
  'country.CV': 'Cabo Verde',
  'country.CL': 'Chile',
  'country.CN': 'China',
  'country.CO': 'Colombia',
  'country.CZ': 'Czechia',
  'country.DK': 'Denmark',
  'country.EG': 'Egypt',
  'country.ET': 'Ethiopia',
  'country.EE': 'Estonia',
  'country.FI': 'Finland',
  'country.FR': 'France',
  'country.GA': 'Gabon',
  'country.GM': 'Gambia',
  'country.GH': 'Ghana',
  'country.DE': 'Germany',
  'country.GR': 'Greece',
  'country.HK': 'Hong Kong',
  'country.HU': 'Hungary',
  'country.IN': 'India',
  'country.ID': 'Indonesia',
  'country.IQ': 'Iraq',
  'country.IE': 'Ireland',
  'country.IL': 'Israel',
  'country.IT': 'Italy',
  'country.JP': 'Japan',
  'country.JO': 'Jordan',
  'country.KE': 'Kenya',
  'country.KW': 'Kuwait',
  'country.LV': 'Latvia',
  'country.LB': 'Lebanon',
  'country.LR': 'Liberia',
  'country.LT': 'Lithuania',
  'country.LU': 'Luxembourg',
  'country.MK': 'North Macedonia',
  'country.MG': 'Madagascar',
  'country.MY': 'Malaysia',
  'country.MA': 'Morocco',
  'country.MX': 'Mexico',
  'country.NL': 'Netherlands',
  'country.NG': 'Nigeria',
  'country.NO': 'Norway',
  'country.OM': 'Oman',
  'country.PK': 'Pakistan',
  'country.PE': 'Peru',
  'country.PH': 'Philippines',
  'country.PL': 'Poland',
  'country.PT': 'Portugal',
  'country.PR': 'Puerto Rico',
  'country.QA': 'Qatar',
  'country.RO': 'Romania',
  'country.RW': 'Rwanda',
  'country.SA': 'Saudi Arabia',
  'country.SN': 'Senegal',
  'country.RS': 'Serbia',
  'country.SL': 'Sierra Leone',
  'country.SG': 'Singapore',
  'country.SI': 'Slovenia',
  'country.ZA': 'South Africa',
  'country.ES': 'Spain',
  'country.SE': 'Sweden',
  'country.TH': 'Thailand',
  'country.TN': 'Tunisia',
  'country.TR': 'Turkey',
  'country.UG': 'Uganda',
  'country.UA': 'Ukraine',
  'country.AE': 'United Arab Emirates',
  'country.GB': 'United Kingdom',
  'country.VE': 'Venezuela (Bolivarian Republic of)',
  'country.YE': 'Yemen',
  'country.ZM': 'Zambia',
  'country.AM': 'Armenia',
  'country.CR': 'Costa Rica',
  'country.DO': 'Dominican Republic',
  'country.LK': 'Sri Lanka',
  'country.CH': 'Switzerland',
  'country.JM': 'Jamaica',
  'country.KZ': 'Kazakhstan',
  'country.NZ': 'New Zealand',
  'country.NP': 'Nepal',
  'country.ZW': 'Zimbabwe',
  'country.EC': 'Ecuador',
  'country.GT': 'Guatemala',
  'country.BD': 'Bangladesh',
  'country.UY': 'Uruguay',
  'country.MN': 'Mongolia',
  'country.KH': 'Cambodia',
  'country.ME': 'Montenegro',
  'country.BW': 'Botswana',
  'country.KG': 'Kyrgyzstan',
  'country.TJ': 'Tajikistan',
  'country.UZ': 'Uzbekistan',
  'country.AZ': 'Azerbaijan',
  'country.TM': 'Turkmenistan',
  'country.TT': 'Trinidad and Tobago',
  'country.GN': 'Guinea',
  'country.AS': 'American Samoa',
  'country.AD': 'Andorra',
  'country.AQ': 'Antarctica',
  'country.AG': 'Antigua And Barbuda',
  'country.AW': 'Aruba',
  'country.BS': 'Bahamas',
  'country.BB': 'Barbados',
  'country.BM': 'Bermuda',
  'country.BY': 'Belarus',
  'country.BZ': 'Belize',
  'country.BJ': 'Benin',
  'country.BT': 'Bhutan',
  'country.BO': 'Bolivia (Plurinational State of)',
  'country.BA': 'Bosnia And Herzegovina',
  'country.BV': 'Bouvet Island',
  'country.GU': 'Guam',
  'country.GY': 'Guyana',
  'country.HT': 'Haiti',
  'country.HM': 'Heard Island And McDonald Islands',
  'country.HN': 'Honduras',
  'country.IS': 'Iceland',
  'country.KI': 'Kiribati',
  'country.KR': 'Korea (Republic of)',
  'country.LA': "Lao People's Democratic Republic",
  'country.WS': 'Samoa',
  'country.SM': 'San Marino',
  'country.ST': 'Sao Tome And Principe',
  'country.SC': 'Seychelles',
  'country.SK': 'Slovakia',
  'country.SB': 'Solomon Islands',
  'country.SO': 'Somalia',
  'country.GS': 'South Georgia And South Sandwich Islands',
  'country.SH': 'Saint Helena, Ascension, Tristan da Cunha',
  'country.PM': 'Saint Pierre and Miquelon',
  'country.SR': 'Suriname',
  'country.SJ': 'Svalbard And Jan Mayen',
  'country.IO': 'British Indian Ocean Territory',
  'country.BN': 'Brunei Darussalam',
  'country.BF': 'Burkina Faso',
  'country.BI': 'Burundi',
  'country.KY': 'Cayman Islands',
  'country.CF': 'Central African Republic',
  'country.TD': 'Chad',
  'country.CX': 'Christmas Island',
  'country.CC': 'Cocos (Keeling) Islands',
  'country.KM': 'Comoros',
  'country.CG': 'Congo',
  'country.CK': 'Cook Islands',
  'country.CI': "Côte d'Ivoire",
  'country.HR': 'Croatia',
  'country.CY': 'Cyprus',
  'country.DJ': 'Djibouti',
  'country.LS': 'Lesotho',
  'country.LY': 'Libya',
  'country.LI': 'Liechtenstein',
  'country.MO': 'Macao',
  'country.MW': 'Malawi',
  'country.MV': 'Maldives',
  'country.ML': 'Mali',
  'country.MT': 'Malta',
  'country.MH': 'Marshall Islands',
  'country.MQ': 'Martinique',
  'country.MR': 'Mauritania',
  'country.MU': 'Mauritius',
  'country.YT': 'Mayotte',
  'country.FM': 'Micronesia (Federated States of)',
  'country.MD': 'Moldova (Republic of)',
  'country.MC': 'Monaco',
  'country.MS': 'Montserrat',
  'country.MZ': 'Mozambique',
  'country.MM': 'Myanmar',
  'country.NA': 'Namibia',
  'country.NR': 'Nauru',
  'country.SZ': 'Eswatini',
  'country.TW': 'Taiwan (Province of China)',
  'country.TZ': 'Tanzania, United Republic of',
  'country.TG': 'Togo',
  'country.TK': 'Tokelau',
  'country.TO': 'Tonga',
  'country.TC': 'Turks And Caicos Islands',
  'country.TV': 'Tuvalu',
  'country.DM': 'Dominica',
  'country.TL': 'Timor-Leste',
  'country.SV': 'El Salvador',
  'country.GQ': 'Equatorial Guinea',
  'country.ER': 'Eritrea',
  'country.FK': 'Falkland Islands (Malvinas)',
  'country.FO': 'Faroe Islands',
  'country.FJ': 'Fiji',
  'country.GF': 'French Guiana',
  'country.PF': 'French Polynesia',
  'country.TF': 'French Southern Territories',
  'country.GE': 'Georgia',
  'country.GD': 'Grenada',
  'country.GL': 'Greenland',
  'country.GP': 'Guadeloupe',
  'country.NC': 'New Caledonia',
  'country.NI': 'Nicaragua',
  'country.NE': 'Niger',
  'country.NU': 'Niue',
  'country.NF': 'Norfolk Island',
  'country.MP': 'Northern Mariana Islands',
  'country.PW': 'Palau',
  'country.PA': 'Panama',
  'country.PG': 'Papua New Guinea',
  'country.PY': 'Paraguay',
  'country.PN': 'Pitcairn',
  'country.RE': 'Reunion',
  'country.RU': 'Russian Federation',
  'country.KN': 'Saint Kitts And Nevis',
  'country.LC': 'Saint Lucia',
  'country.VC': 'Saint Vincent And The Grenadines',
  'country.UM': 'United States Minor Outlying Islands',
  'country.VU': 'Vanuatu',
  'country.VA': 'Holy See',
  'country.VN': 'Viet Nam',
  'country.VG': 'Virgin Islands (British)',
  'country.VI': 'Virgin Islands (U.S.)',
  'country.WF': 'Wallis And Futuna',
  'country.EH': 'Western Sahara',
  'country.GW': 'Guinea-Bissau',
  'country.GI': 'Gibraltar',
  'country.SS': 'South Sudan',
  'country.CW': 'Curacao',
  'country.AX': 'Åland Islands',
  'country.AI': 'Anguilla',
  'country.CD': 'Congo (Democratic Republic of the)',
  'country.GG': 'Guernsey',
  'country.IM': 'Isle of Man',
  'country.JE': 'Jersey',
  'country.PS': 'Palestine, State of',
  'country.MF': 'Saint Martin (French part)',
  'country.SX': 'Sint Maarten (Dutch part)',
  'country.SD': 'Sudan',
  'country.BQ': 'Bonaire, Sint Eustatius, and Saba',
  'country.BL': 'Saint Barthelemy',
  // Removed for Embargo
  // 'country.CU': 'Cuba',
  // 'country.IR': 'Iran (Islamic Republic of)',
  // 'country.KP': 'Korea (Democratic People's Republic of)',
  // 'country.SY': 'Syrian Arab Republic',
  // Removed for Embargo
  'state.AL': 'Alabama',
  'state.AK': 'Alaska',
  'state.AZ': 'Arizona',
  'state.AR': 'Arkansas',
  'state.CA': 'California',
  'state.CO': 'Colorado',
  'state.CT': 'Connecticut',
  'state.DE': 'Delaware',
  'state.FL': 'Florida',
  'state.GA': 'Georgia',
  'state.HI': 'Hawaii',
  'state.ID': 'Idaho',
  'state.IL': 'Illinois',
  'state.IN': 'Indiana',
  'state.IA': 'Iowa',
  'state.KS': 'Kansas',
  'state.KY': 'Kentucky',
  'state.LA': 'Louisiana',
  'state.ME': 'Maine',
  'state.MD': 'Maryland',
  'state.MA': 'Massachusetts',
  'state.MI': 'Michigan',
  'state.MN': 'Minnesota',
  'state.MS': 'Mississippi',
  'state.MO': 'Missouri',
  'state.MT': 'Montana',
  'state.NE': 'Nebraska',
  'state.NV': 'Nevada',
  'state.NH': 'New Hampshire',
  'state.NJ': 'New Jersey',
  'state.NM': 'New Mexico',
  'state.NY': 'New York',
  'state.NC': 'North Carolina',
  'state.ND': 'North Dakota',
  'state.OH': 'Ohio',
  'state.OK': 'Oklahoma',
  'state.OR': 'Oregon',
  'state.PA': 'Pennsylvania',
  'state.RI': 'Rhode Island',
  'state.SC': 'South Carolina',
  'state.SD': 'South Dakota',
  'state.TN': 'Tennessee',
  'state.TX': 'Texas',
  'state.UT': 'Utah',
  'state.VT': 'Vermont',
  'state.VA': 'Virginia',
  'state.WA': 'Washington',
  'state.WV': 'West Virginia',
  'state.WI': 'Wisconsin',
  'state.WY': 'Wyoming',
  'state.DC': 'District of Columbia',
  'state.AS': 'American Samoa',
  'state.FM': 'Federated States of Micronesia',
  'state.GU': 'Guam',
  'state.MH': 'Marshall Islands',
  'state.MP': 'Northern Mariana Islands',
  'state.PW': 'Palau',
  'state.PR': 'Puerto Rico',
  'state.VI': 'Virgin Islands',
  'state.AE': 'Armed Forces Europe, Middle East, and Canada',
  'state.AP': 'Armed Forces Pacific',
  'state.AA': 'Armed Forces Americas (except Canada)',
  'province.AB': 'Alberta',
  'province.BC': 'British Columbia',
  'province.MB': 'Manitoba',
  'province.NB': 'New Brunswick',
  'province.NL': 'Newfoundland and Labrador',
  'province.NT': 'Northwest Territories',
  'province.NS': 'Nova Scotia',
  'province.NU': 'Nunavut',
  'province.ON': 'Ontario',
  'province.PE': 'Prince Edward Island',
  'province.QC': 'Quebec',
  'province.SK': 'Saskatchewan',
  'province.YT': 'Yukon',
  'state.AbuDhabi': 'Abu Dhabi',
  'state.Ajman': 'Ajman',
  'state.Dubai': 'Dubai',
  'state.Fujairah': 'Fujairah',
  'state.RasAlKhaimah': 'Ras Al Khaimah',
  'state.Sharjah': 'Sharjah',
  'state.UmmAlQuwain': 'Umm Al Quwain',
  'billing.aeState': 'Emirate',
  'instate.AN': 'Andaman and Nicobar Islands',
  'instate.AP': 'Andhra Pradesh',
  'instate.AR': 'Arunachal Pradesh',
  'instate.AS': 'Assam',
  'instate.BR': 'Bihar',
  'instate.CH': 'Chandigarh',
  'instate.CT': 'Chhattisgarh',
  'instate.DN': 'Dadra and Nagar Haveli',
  'instate.DD': 'Daman and Diu',
  'instate.DL': 'Delhi',
  'instate.GA': 'Goa',
  'instate.GJ': 'Gujarat',
  'instate.HR': 'Haryana',
  'instate.HP': 'Himachal Pradesh',
  'instate.JK': 'Jammu and Kashmir',
  'instate.JH': 'Jharkhand',
  'instate.KA': 'Karnataka',
  'instate.KL': 'Kerala',
  'instate.LD': 'Lakshadweep',
  'instate.MP': 'Madhya Pradesh',
  'instate.MH': 'Maharashtra',
  'instate.MN': 'Manipur',
  'instate.ML': 'Meghalaya',
  'instate.MZ': 'Mizoram',
  'instate.NL': 'Nagaland',
  'instate.OR': 'Odisha',
  'instate.PY': 'Puducherry',
  'instate.PB': 'Punjab',
  'instate.RJ': 'Rajasthan',
  'instate.SK': 'Sikkim',
  'instate.TN': 'Tamil Nadu',
  'instate.TG': 'Telangana',
  'instate.TR': 'Tripura',
  'instate.UP': 'Uttar Pradesh',
  'instate.UT': 'Uttarakhand',
  'instate.WB': 'West Bengal',
  'billing.inState': 'State',
};
