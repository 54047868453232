import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';
import { useIntl } from 'react-intl';

const PostTestPage = ({ history: { push } }) => {
  const intl = useIntl();

  const [token, setToken] = useState('');

  const inputHandler = ({ target: { value } }) => {
    setToken(value);
  };

  const handlePost = () => {
    push(`/?token=${token}`);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', padding: '1rem' }}>
      <input
        placeholder={intl.formatMessage({ id: 'test.insertToken' })}
        style={{ maxWidth: '25rem', marginRight: '1rem' }}
        onChange={inputHandler}
        value={token}
      />
      <button className="primary-button" onClick={handlePost}>
        POST!
      </button>
    </div>
  );
};

PostTestPage.propTypes = {
  history: object.isRequired,
};

export default withRouter(PostTestPage);
