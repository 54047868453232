import React, { useEffect } from 'react';
import { bool, object, func, string, number } from 'prop-types';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import qs from 'query-string';
import * as Yup from 'yup';
import Tooltip from '@material-ui/core/Tooltip';
import { initializePendo } from '../redux/actions/pendoActions';
import { getInvoice, sendInvoice, getClaims, getClaimsFailure } from '../redux/actions/ordersActions';
import '../styles/pages/invoice-page.scss';
import LoadingWrapper from '../components/common/LoadingWrapper';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

const InvoicePage = ({
  error,
  getClaims,
  getClaimsFailure,
  transactionId,
  locale,
  supplierTag,
  taxInvoice,
  loadingInvoice,
  loadingClaims,
  getInvoice,
  sendInvoice,
  transactionToken,
  history: { push },
  location: { search },
  initializePendo,
  sessionTimeout,
  sessionStart,
}) => {
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .matches(
        /^([a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?;?[ ]?){1,10}$/,
        intl.formatMessage({ id: 'invoice.wrongMailFormat' })
      ),
  });

  const onResend = email => {
    sendInvoice(transactionId, supplierTag, locale, email);
    toast(intl.formatMessage({ id: 'invoice.invoiceSent' }), { type: toast.TYPE.SUCCESS, autoClose: 5000 });
  };

  useEffect(() => {
    initializePendo && supplierTag && intl && initializePendo(intl, supplierTag);
  }, [initializePendo, supplierTag, intl]);

  useEffect(() => {
    const { token } = qs.parse(search);
    if (error && transactionToken === token) {
      push('/notfound');
    } else if (!token || token !== transactionToken) {
      getClaims(token);
    }
  }, [error, getClaims, push, search, transactionToken]);

  useEffect(() => {
    if (!sessionTimeout) return;

    const allowedSpan = 1000 * 60 * sessionTimeout;
    const elapsedTime = (new Date().getTime() - sessionStart) / allowedSpan; // 0.0 equals start. 1.0 means timeout.
    const toastTime = Math.min(Math.max(sessionTimeout * 0.1, 1), 5) / sessionTimeout;
    const timers = [];

    timers.push(
      setTimeout(() => {
        getClaimsFailure(intl.formatMessage({ id: 'invoice.sessionExpired' }));
        push('/notfound');
      }, Math.max(0, (1.0 - elapsedTime) * allowedSpan))
    );

    elapsedTime < 1 &&
      timers.push(
        setTimeout(() => {
          toast(intl.formatMessage({ id: 'global.expiringSession' }), {
            type: toast.TYPE.WARNING,
            autoClose: Math.min(toastTime, 1.0 - elapsedTime) * allowedSpan,
            pauseOnHover: false,
          });
        }, Math.max(0, 1.0 - toastTime - elapsedTime) * allowedSpan)
      );

    return () => timers.forEach(timer => clearTimeout(timer));
  }, [sessionTimeout, sessionStart, intl, locale, getClaimsFailure, push]);

  useEffect(() => {
    const { token } = qs.parse(search);
    transactionId && token === transactionToken && getInvoice(transactionId, supplierTag, locale);
  }, [search, getInvoice, transactionId, supplierTag, locale, transactionToken]);

  const { handleSubmit, handleChange, values, errors, touched, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      email: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: () => {
      values.email && !errors.email && onResend(values.email);
      setFieldValue('email', '');
    },
  });

  const onPrint = () => {
    const content = document.getElementById('divcontents');
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);

    const css = '@page { size: landscape; }';
    const head = pri.document.head || pri.document.getElementsByTagName('head')[0];
    const style = pri.document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(pri.document.createTextNode(css));
    }
    head.appendChild(style);

    pri.document.close();

    setTimeout(() => {
      pri.focus();
      pri.print();
    }, 100);
  };

  return (
    <>
      <Header />
      <div className="container invoice-flex-centered">
        <div className="invoice-container">
          <iframe
            title="ifmcontentstoprint"
            id="ifmcontentstoprint"
            style={{ height: '0px', width: '0px', position: 'absolute' }}
          />
          <LoadingWrapper loading={loadingInvoice || loadingClaims}>
            <>
              {taxInvoice ? (
                <>
                  <div className="card margin-bottom invoice-header">
                    <form onSubmit={handleSubmit}>
                      <label htmlFor="email">{intl.formatMessage({ id: 'invoice.email' })}:</label>
                      <Tooltip
                        title={errors.email || ''}
                        placement="top"
                        open={touched.email !== undefined && errors.email !== undefined}
                        onOpen={() => { }}
                        onClose={() => { }}
                      >
                        <input
                          type="text"
                          name="email"
                          className="invoice-email"
                          placeholder={intl.formatMessage({ id: 'invoice.mailPlaceholder' })}
                          value={values.email}
                          maxLength="500"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Tooltip>
                      <input
                        type="submit"
                        className="primary-button bg-green invoice"
                        disabled={!values.email || errors.email}
                        value={intl.formatMessage({ id: 'invoice.send' })}
                      />
                    </form>

                    <input
                      type="button"
                      className="primary-button bg-green invoice"
                      onClick={onPrint}
                      value={intl.formatMessage({ id: 'invoice.print' })}
                    />
                  </div>
                  <div className="card">
                    <div id="divcontents" dangerouslySetInnerHTML={{ __html: taxInvoice }} />
                  </div>
                </>
              ) : (
                <div className="card">
                  <h2>{intl.formatMessage({ id: 'invoice.noOrder' })}</h2>
                </div>
              )}
            </>
          </LoadingWrapper>
        </div>
      </div>
      <Footer onlyLinks />
    </>
  );
};

InvoicePage.propTypes = {
  error: string,
  loadingInvoice: bool,
  loadingClaims: bool,
  getClaims: func,
  getClaimsFailure: func,
  taxInvoice: string,
  getInvoice: func.isRequired,
  sendInvoice: func.isRequired,
  location: object.isRequired,
  transactionId: string,
  transactionToken: string,
  locale: string,
  supplierTag: string,
  history: object,
  initializePendo: func,
  sessionTimeout: number,
  sessionStart: number,
};

const mapState = ({ order }) => ({
  error: order.globalError,
  loadingInvoice: order.loadingInvoice,
  loadingClaims: order.loadingClaims,
  taxInvoice: order.taxInvoice,
  transactionId: order.transactionId,
  locale: order.locale,
  supplierTag: order.supplierTag,
  transactionToken: order.transactionToken,
  sessionTimeout: order.sessionTimeout,
  sessionStart: order.sessionStart,
});

const mapDispatch = { getInvoice, getClaims, sendInvoice, initializePendo, getClaimsFailure };

export default connect(mapState, mapDispatch)(InvoicePage);
