export default {
  'billing.address': 'Endereço',
  'billing.addressConfirmation': 'Confirmação de Endereço',
  'billing.addressConfirmationDescription':
    'Encontramos um endereço valido com base no endereço inserido. Selecione Confirmar para aceitar ou Cancelar para fazer modificações.',
  'billing.billing': 'Faturamento',
  'billing.billingInformation': 'Dados de Faturamento',
  'billing.cancelAddressConfirmation': 'Cancelar',
  'billing.caState': 'Província',
  'billing.city': 'Cidade',
  'billing.confirmAddress': 'Confirmar',
  'billing.confirmEmail': 'Confirmar E-Mail',
  'billing.country': 'País',
  'billing.edit': 'Editar',
  'billing.email': 'E-Mail',
  'billing.errorObtainingCountries': 'Ocorreu um erro ao obter a lista de países disponíveis',
  'billing.errorValidatingAddress': 'Endereço inválido para cobrança',
  'billing.firstName': 'Nome',
  'billing.knownState': 'Estado',
  'billing.lastName': 'Sobrenome',
  'billing.loadingCountries': 'Carregando lista de países…',
  'billing.memo': 'Nota/Número da Ordem de Compra',
  'billing.organizationName': 'Nome da Organização',
  'billing.originalAddress': 'Endereço Inserido',
  'billing.postalCode': 'Código Postal',
  'billing.save': 'Salvar',
  'billing.state': 'Estado/Província/Região',
  'billing.taxId': 'ID Fiscal',
  'billing.useBillingInformation': 'Usar dados de faturamento',
  'billing.usState': 'Estado',
  'billing.validatedAddress': 'Endereço Validado',
  'billing.VATTaxID': 'ID Fiscal',
  'cardBrand.amex': 'American Express',
  'cardBrand.cartes_bancaires': 'Cartes Bancaires',
  'cardBrand.diners': "Diner's Club",
  'cardBrand.discover': 'Discover',
  'cardBrand.jcb': 'JCB',
  'cardBrand.mastercard': 'Mastercard',
  'cardBrand.unionpay': 'UnionPay',
  'cardBrand.visa': 'Visa',
  'field.invalidEmail': 'Digite um e-mail válido',
  'field.requiredField': 'Este campo é obrigatório',
  'field.unmatchingEmails': 'Os e-mails não correspondem',
  'global.AWS_Cobranding':
    'A Xvoucher é a revendedora autorizada deste programa de treinamento da Amazon Web Services.',
  'global.backToAWS': 'Voltar à AWS',
  'global.backToCart': 'Voltar',
  'global.change': 'Alterar',
  'global.contactSupport': 'Contate o Suporte',
  'global.defaultSelectOption': 'Selecione uma opção',
  'global.expiredSession': 'Sessão expirada',
  'global.expiringSession': 'Sua sessão expirará em breve',
  'global.missingPaymentTransaction': 'Transação de pagamento não encontrada',
  'global.more': 'mais',
  'global.allRightsReserved': 'todos os direitos reservados',
  'global.pageNotFound': 'A página buscada não foi encontrada',
  'global.poweredBy': 'Fornecido por',
  'global.noRemainingAttempts' : 'A tentativa de pagamento falhou várias vezes. Você será redirecionado de volta.',
  'global.rightsReserved': 'Todos os Direitos Reservados',
  'global.support': 'Suporte',
  'global.terms': 'Termos',
  'global.unauthorizedAccess': 'Usuário não autorizado para acessar esta página',
  'invoice.email': 'E-Mail',
  'invoice.errorOnObtainingInvoice': 'Ocorreu um erro ao exibir a fatura',
  'invoice.errorSendingEmail': 'Ocorreu um erro ao enviar a fatura',
  'invoice.invoiceSent': 'Fatura enviada',
  'invoice.mailPlaceholder': 'e.g. "john@doe.com; jane@doe.com',
  'invoice.noOrder': 'Não há ordem a exibir',
  'invoice.print': 'Imprimir',
  'invoice.send': 'Enviar',
  'invoice.sessionExpired': 'Sua sessão expirada',
  'invoice.wrongMailFormat':
    'Podem ser usados até 10 e-mails válidos separados por ponto e vírgula. Ex: "john@doe.com; jane@doe.com"',
  'order.errorGettingOrder': 'Ocorreu um erro ao obter o pedido',
  'order.errorUpdatingOrder': 'Ocorreu um erro ao atualizar o pedido',
  'order.invalidToken': 'Transação inválida',
  'order.locationNotApproved': 'Esta aula não está disponível para compra em sua localização',
  'order.moreDetailsAvailable': 'Mais detalhes',
  'order.noOrder': 'Não há detalhes do pedido a exibir',
  'order.noToken': 'Não foram encontradas informações da transação',
  'order.subtotal': 'Subtotal',
  'order.title': 'Dados do Pedido',
  'orderItem.ends': 'Término',
  'orderItem.itemLineDiscount': 'Desconto do Item',
  'orderItem.location': 'Local',
  'orderItem.productDiscount': 'Desconto do produto',
  'orderItem.starts': 'Início',
  'orderSummary.cancel': 'Cancelar',
  'orderSummary.cardTypeSelectionLabel': 'Selecione um Tipo de Cartão de Crédito',
  'orderSummary.chargedAmountLabel': 'Seu cartão será cobrado',
  'orderSummary.conversionReason': 'Por que preciso pagar em dólares americanos (USD)',
  'orderSummary.currencyChangeExplanation': 'Para fazer um pagamento em {0}, selecione Visa ou MasterCard.',
  'orderSummary.discount': 'Desconto',
  'orderSummary.estimatedTax': 'Impostos',
  'orderSummary.fee': 'Taxa',
  'orderSummary.noOrder': 'Resumo de pedido inexistente',
  'orderSummary.orderDiscount': 'Desconto do Pedido',
  'orderSummary.pay': 'Pagar',
  'orderSummary.productDiscount': 'Desconto do Produto',
  'orderSummary.productSubTotal': 'Subtotal dos Produtos',
  'orderSummary.productTotal': 'Total dos Produtos',
  'orderSummary.title': 'Resumo do Pedido',
  'orderSummary.total': 'Total',
  'orderSummary.totalBeforeTax': 'Total sem Impostos',
  'payment.cancelledPayment': 'Pagamento cancelado',
  'payment.confirmingTransaction': 'Confirmando transação...',
  'payment.convergeFailure': 'Ocorreu um erro ao iniciar o pagamento',
  'payment.errorObtainingRedirectURL': 'Ocorreu um erro ao obter a URL de redirecionamento',
  'payment.errorProcessingTransaction': 'Ocorreu um erro ao processar a transação',
  'payment.fraudSuspectionError':
    'Para sua segurança, a tentativa de pagamento não pode ser completada e os detalhes foram encaminhados à nossa equipe de segurança. Se você acredita que recebeu esta mensagem por engano, entre em contato com o <a href="{0}"> suporte do Xvoucher </a>.',
  'payment.incompatibleCardForCurrency': 'Pagamentos em moeda diferente de USD devem ser feitos com Visa ou MasterCard',
  'payment.initFailure': 'Ocorreu um erro ao iniciar o pagamento',
  'payment.postalCodeRequired': 'Código Postal obrigatório para essa transação',
  'payment.transactionApproved': 'O pagamento foi aprovado',
  'payment.transactionCancelled': 'Pagamento cancelado',
  'payment.transactionDeclined': 'Transação recusada',
  'payment.transactionDeclinedCardDetails': 'Os dados de seu cartão estavam incorretos',
  'payment.transactionError': 'Ocorreu um erro ao realizar a transação',
  'payment.transactionProcessing': 'A transação com cartão de crédito está sendo processada',
  'payment.transactionRequiresAction': 'A transação com cartão de crédito requer uma ação',
  'payment.transactionRequiresCapture': 'A transação com cartão de crédito requer captura',
  'payment.transactionRequiresConfirmation': 'A transação com cartão de crédito requer confirmação',
  'payment.transactionRequiresPaymentMethod': 'A transação com cartão de crédito requer forma de pagamento',
  'payment.unsupportedCardOfferCurrencyConversion':
    'Os cartões {0} não são aceitos para pagamentos em {1}. Use outra marca de cartão ou pague em {2}.',
  'pendo.initializationError': 'Ocorreu um erro ao tentar inicializar o Pendo: {0}',
  'pendo.updateError': 'Ocorreu um erro ao tentar atualizar o Pendo: {0}',
  'test.insertToken': 'Insira o token de teste aqui',
  'country.US': 'Estados Unidos',
  'country.CA': 'Canadá',
  'country.AF': 'Afeganistão',
  'country.AL': 'Albânia',
  'country.DZ': 'Argélia',
  'country.AO': 'Angola',
  'country.AR': 'Argentina',
  'country.AT': 'Áustria',
  'country.AU': 'Austrália',
  'country.BE': 'Bélgica',
  'country.BH': 'Barém',
  'country.BR': 'Brasil',
  'country.BG': 'Bulgária',
  'country.CM': 'Camarões',
  'country.CV': 'Cabo Verde',
  'country.CL': 'Chile',
  'country.CN': 'China',
  'country.CO': 'Colômbia',
  'country.CZ': 'República Checa',
  'country.DK': 'Dinamarca',
  'country.EG': 'Egipto',
  'country.ET': 'Etiópia',
  'country.EE': 'Estónia',
  'country.FI': 'Finlândia',
  'country.FR': 'França',
  'country.GA': 'Gabão',
  'country.GM': 'Gâmbia',
  'country.GH': 'Gana',
  'country.DE': 'Alemanha',
  'country.GR': 'Grécia',
  'country.HK': 'Hong Kong',
  'country.HU': 'Hungria',
  'country.IN': 'Índia',
  'country.ID': 'Indonésia',
  'country.IQ': 'Iraque',
  'country.IE': 'Irlanda',
  'country.IL': 'Israel',
  'country.IT': 'Itália',
  'country.JP': 'Japão',
  'country.JO': 'Jordânia',
  'country.KE': 'Quénia',
  'country.KP': 'Coreia do Norte',
  'country.KW': 'Kuwait',
  'country.LV': 'Letónia',
  'country.LB': 'Líbano',
  'country.LR': 'Libéria',
  'country.LT': 'Lituânia',
  'country.LU': 'Luxemburgo',
  'country.MK': 'Macedônia do Norte',
  'country.MG': 'Madagáscar',
  'country.MY': 'Malásia',
  'country.MA': 'Marrocos',
  'country.MX': 'México',
  'country.NL': 'Países Baixos',
  'country.NG': 'Nigéria',
  'country.NO': 'Noruega',
  'country.OM': 'Omã',
  'country.PK': 'Paquistão',
  'country.PE': 'Peru',
  'country.PH': 'Filipinas',
  'country.PL': 'Polónia',
  'country.PT': 'Portugal',
  'country.PR': 'Puerto Rico',
  'country.QA': 'Catar',
  'country.RO': 'Roménia',
  'country.RW': 'Ruanda',
  'country.SA': 'Arábia Saudita',
  'country.SN': 'Senegal',
  'country.RS': 'Sérvia',
  'country.SL': 'Sierra Leona',
  'country.SG': 'Singapura',
  'country.SI': 'Eslovénia',
  'country.ZA': 'África do Sul',
  'country.ES': 'Espanha',
  'country.SE': 'Suécia',
  'country.TH': 'Tailândia',
  'country.TN': 'Tunísia',
  'country.TR': 'Turquia',
  'country.UG': 'Uganda',
  'country.UA': 'Ucrânia',
  'country.AE': 'Emiratos Árabes Unidos',
  'country.GB': 'Reino Unido',
  'country.VE': 'Venezuela',
  'country.YE': 'Iémen',
  'country.ZM': 'Zâmbia',
  'country.AM': 'Arménia',
  'country.CR': 'Costa Rica',
  'country.DO': 'República Dominicana',
  'country.LK': 'Sri Lanca',
  'country.CH': 'Suíça',
  'country.JM': 'Jamaica',
  'country.KZ': 'Cazaquistão',
  'country.NZ': 'Nova Zelândia',
  'country.NP': 'Nepal',
  'country.ZW': 'Zimbabué',
  'country.EC': 'Equador',
  'country.GT': 'Guatemala',
  'country.BD': 'Bangladesh',
  'country.UY': 'Uruguai',
  'country.MN': 'Mongólia',
  'country.KH': 'Camboja',
  'country.ME': 'Montenegro',
  'country.BW': 'Botswana',
  'country.KG': 'Quirguizistão',
  'country.TJ': 'Tajiquistão',
  'country.UZ': 'Usbequistão',
  'country.AZ': 'Azerbaijão',
  'country.TM': 'Turquemenistão',
  'country.TT': 'Trindade e Tobago',
  'country.GN': 'Guiné',
  'country.AS': 'Samoa Americana',
  'country.AD': 'Andorra',
  'country.AQ': 'Antárctida',
  'country.AG': 'Antígua e Barbuda',
  'country.AW': 'Aruba',
  'country.BS': 'Bahamas',
  'country.BB': 'Barbados',
  'country.BM': 'Bermudas',
  'country.BY': 'Bielorrússia',
  'country.BZ': 'Belize',
  'country.BJ': 'Benim',
  'country.BT': 'Butão',
  'country.BO': 'Bolívia',
  'country.BA': 'Bósnia e Herzegovina',
  'country.BV': 'Ilha Bouvet',
  'country.GU': 'Guame',
  'country.GY': 'Guiana',
  'country.HT': 'Haiti',
  'country.HM': 'Ilhas Heard e McDonald',
  'country.HN': 'Honduras',
  'country.IS': 'Islândia',
  'country.KI': 'Quiribáti',
  'country.KR': 'Coreia do Sul',
  'country.LA': 'Laos',
  'country.WS': 'Samoa',
  'country.SM': 'São Marinho',
  'country.ST': 'São Tomé e Príncipe',
  'country.SC': 'Seicheles',
  'country.SK': 'Eslováquia',
  'country.SB': 'Ilhas Salomão',
  'country.SO': 'Somália',
  'country.GS': 'Geórgia do Sul e Sandwich do Sul',
  'country.SH': 'Santa Helena',
  'country.PM': 'São Pedro e Miquelon',
  'country.SR': 'Suriname',
  'country.SJ': 'Svalbard e Jan Mayen',
  'country.IO': 'Território Britânico do Oceano Índico',
  'country.BN': 'Brunei',
  'country.BF': 'Burquina Faso',
  'country.BI': 'Burundi',
  'country.KY': 'Ilhas Caimão',
  'country.CF': 'República Centro-Africana',
  'country.TD': 'Chade',
  'country.CX': 'Ilha do Natal',
  'country.CC': 'Ilhas dos Cocos',
  'country.KM': 'Comores',
  'country.CG': 'Congo',
  'country.CK': 'Ilhas Cook',
  'country.CI': 'Costa do Marfim',
  'country.HR': 'Croácia',
  'country.CY': 'Chipre',
  'country.DJ': 'Jibuti',
  'country.LS': 'Lesoto',
  'country.LY': 'Líbia',
  'country.LI': 'Listenstaine',
  'country.MO': 'Macau',
  'country.MW': 'Malávi',
  'country.MV': 'Maldivas',
  'country.ML': 'Mali',
  'country.MT': 'Malta',
  'country.MH': 'Ilhas Marshall',
  'country.MQ': 'Martinica',
  'country.MR': 'Mauritânia',
  'country.MU': 'Maurícia',
  'country.YT': 'Mayotte',
  'country.FM': 'Micronésia',
  'country.MD': 'Moldávia',
  'country.MC': 'Mónaco',
  'country.MS': 'Monserrate',
  'country.MZ': 'Moçambique',
  'country.MM': 'Birmânia',
  'country.NA': 'Namíbia',
  'country.NR': 'Nauru',
  'country.SZ': 'Eswatini',
  'country.TW': 'Taiwan',
  'country.TZ': 'Tanzânia',
  'country.TG': 'Togo',
  'country.TK': 'Tokelau',
  'country.TO': 'Tonga',
  'country.TC': 'Ilhas Turcas e Caicos',
  'country.TV': 'Tuvalu',
  'country.DM': 'Domínica',
  'country.TL': 'Timor-Leste',
  'country.SV': 'Salvador',
  'country.GQ': 'Guiné Equatorial',
  'country.ER': 'Eritreia',
  'country.FK': 'Ilhas Falkland',
  'country.FO': 'Faroé',
  'country.FJ': 'Fiji',
  'country.GF': 'Guiana Francesa',
  'country.PF': 'Polinésia Francesa',
  'country.TF': 'Territórios Austrais Franceses',
  'country.GE': 'Geórgia',
  'country.GD': 'Granada',
  'country.GL': 'Gronelândia',
  'country.GP': 'Guadalupe',
  'country.NC': 'Nova Caledónia',
  'country.NI': 'Nicarágua',
  'country.NE': 'Níger',
  'country.NU': 'Niue',
  'country.NF': 'Ilha Norfolk',
  'country.MP': 'Marianas do Norte',
  'country.PW': 'Palau',
  'country.PA': 'Panamá',
  'country.PG': 'Papua-Nova Guiné',
  'country.PY': 'Paraguai',
  'country.PN': 'Pitcairn',
  'country.RE': 'Reunião',
  'country.RU': 'Rússia',
  'country.KN': 'São Cristóvão e Neves',
  'country.LC': 'Santa Lúcia',
  'country.VC': 'São Vicente e Granadinas',
  'country.UM': 'Ilhas Menores Distantes dos Estados Unidos',
  'country.VU': 'Vanuatu',
  'country.VA': 'Cidade do Vaticano',
  'country.VN': 'Vietname',
  'country.VG': 'Ilhas Virgens Britânicas',
  'country.VI': 'Ilhas Virgens (EUA)',
  'country.WF': 'Wallis e Futuna',
  'country.EH': 'Sara Ocidental',
  'country.GW': 'Guiné-Bissau',
  'country.GI': 'Gibraltar',
  'country.SS': 'Sudão do Sul',
  'country.CW': 'Curaçao',
  'country.AX': 'Alanda',
  'country.AI': 'Anguila',
  'country.CD': 'Congo-Kinshasa',
  'country.CU': 'Cuba',
  'country.GG': 'Guernsey',
  'country.IR': 'Irão',
  'country.IM': 'Ilha de Man',
  'country.JE': 'Jersey',
  'country.PS': 'Palestina',
  'country.MF': 'São Martinho (parte francesa)',
  'country.SX': 'São Martinho (parte holandesa)',
  'country.SD': 'Sudão',
  'country.SY': 'Siria',
  'country.BQ': 'Bonaire, Santo Eustáquio e Saba',
  'country.BL': 'São Bartolomeu',
  'state.AL': 'Alabama',
  'state.AK': 'Alasca',
  'state.AZ': 'Arizona',
  'state.AR': 'Arkansas',
  'state.CA': 'Califórnia',
  'state.CO': 'Colorado',
  'state.CT': 'Connecticut',
  'state.DE': 'Delaware',
  'state.FL': 'Flórida',
  'state.GA': 'Geórgia',
  'state.HI': 'Havaí',
  'state.ID': 'Idaho',
  'state.IL': 'Illinois',
  'state.IN': 'Indiana',
  'state.IA': 'Iowa',
  'state.KS': 'Kansas',
  'state.KY': 'Kentucky',
  'state.LA': 'Louisiana',
  'state.ME': 'Maine',
  'state.MD': 'Maryland',
  'state.MA': 'Massachusetts',
  'state.MI': 'Michigan',
  'state.MN': 'Minnesota',
  'state.MS': 'Mississippi',
  'state.MO': 'Missouri',
  'state.MT': 'Montana',
  'state.NE': 'Nebraska',
  'state.NV': 'Nevada',
  'state.NH': 'Nova Hampshire',
  'state.NJ': 'Nova Jersey',
  'state.NM': 'Novo México',
  'state.NY': 'Nova York',
  'state.NC': 'Carolina do Norte',
  'state.ND': 'Dakota do Norte',
  'state.OH': 'Ohio',
  'state.OK': 'Oklahoma',
  'state.OR': 'Oregon',
  'state.PA': 'Pensilvânia',
  'state.RI': 'Rhode Island',
  'state.SC': 'Carolina do Sul',
  'state.SD': 'Dakota do Sul',
  'state.TN': 'Tennessee',
  'state.TX': 'Texas',
  'state.UT': 'Utah',
  'state.VT': 'Vermont',
  'state.VA': 'Virgínia',
  'state.WA': 'Washington',
  'state.WV': 'West Virginia',
  'state.WI': 'Wisconsin',
  'state.WY': 'Wyoming',
  'state.DC': 'Distrito da Colombia',
  'state.AS': 'Samoa Americana',
  'state.FM': 'Estados Federados da Micronésia',
  'state.GU': 'Guam',
  'state.MH': 'Ilhas Marshall',
  'state.MP': 'Ilhas Marianas do Norte',
  'state.PW': 'Palau',
  'state.PR': 'Porto Rico',
  'state.VI': 'Ilhas Virgens',
  'state.AE': 'Forças Armadas Europa, Oriente Médio e Canadá',
  'state.AP': 'Forças Armadas do Pacífico',
  'state.AA': 'Forças Armadas das Américas (exceto Canadá)',
  'province.AB': 'Alberta',
  'province.BC': 'Columbia Britânica',
  'province.MB': 'Manitoba',
  'province.NB': 'New Brunswick',
  'province.NL': 'Terra Nova e Labrador',
  'province.NT': 'Territórios do Noroeste',
  'province.NS': 'nova Escócia',
  'province.NU': 'Nunavut',
  'province.ON': 'Ontário',
  'province.PE': 'Ilha Principe Edward',
  'province.QC': 'Quebec',
  'province.SK': 'Saskatchewan',
  'province.YT': 'Yukon',
  'state.AbuDhabi': 'Abu Dhabi',
  'state.Ajman': 'Ajman',
  'state.Dubai': 'Dubai',
  'state.Fujairah': 'Fujairah',
  'state.RasAlKhaimah': 'Ras Al Khaimah',
  'state.Sharjah': 'Sharjah',
  'state.UmmAlQuwain': 'Umm Al Quwain',
  'billing.aeState': 'Emirado',
  'instate.AN': 'Ilhas Andaman e Nicobar',
  'instate.AP': 'Andhra Pradesh',
  'instate.AR': 'Arunachal Pradesh',
  'instate.AS': 'Assam',
  'instate.BR': 'Bihar',
  'instate.CH': 'Chandigarh',
  'instate.CT': 'Chhattisgarh',
  'instate.DN': 'Dadra e Nagar Haveli',
  'instate.DD': 'Damão e Diu',
  'instate.DL': 'Délhi',
  'instate.GA': 'goa',
  'instate.GJ': 'Gujarat',
  'instate.HR': 'haryana',
  'instate.HP': 'Himachal Pradesh',
  'instate.JK': 'Jammu e Caxemira',
  'instate.JH': 'Jharkhand',
  'instate.KA': 'Karnataka',
  'instate.KL': 'Querala',
  'instate.LD': 'Lakshadweep',
  'instate.MP': 'Madhya Pradesh',
  'instate.MH': 'Maharashtra',
  'instate.MN': 'Manipur',
  'instate.ML': 'Meghalaya',
  'instate.MZ': 'Mizoram',
  'instate.NL': 'Nagaland',
  'instate.OR': 'Orissa',
  'instate.PY': 'Pondicherry',
  'instate.PB': 'Punjab',
  'instate.RJ': 'Rajastão',
  'instate.SK': 'siquim',
  'instate.TN': 'Tamil Nadu',
  'instate.TG': 'telangana',
  'instate.TR': 'Tripura',
  'instate.UP': 'Uttar Pradesh',
  'instate.UT': 'Uttarakhand',
  'instate.WB': 'Bengala Ocidental',
  'billing.inState': 'Estado',
};
