import React from 'react';
import { connect } from 'react-redux';
import { bool, string, func, object } from 'prop-types';
import { Switch, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IntlProvider } from 'react-intl';

import locales from './locales';
import { DEFAULT_LANGUAGE } from './constants/constants';

import routes from './routes';
import RouteFromPath from './pages/routes/RouteFromPath';

import 'react-toastify/dist/ReactToastify.css';
import './styles/components/common/toast.scss';

import { getClaims } from './redux/actions/ordersActions';

toast.configure({
  autoClose: 30000,
});

const App = ({ locale, authenticated }) => {
  const initialLocale = locale || DEFAULT_LANGUAGE;
  const localePriorities = [initialLocale, initialLocale.split('-')[0], DEFAULT_LANGUAGE];
  const localizedPriorities = localePriorities.map(locale => locales[locale]);
  // Find non-null element
  const localePos = localizedPriorities.findIndex(elem => elem);

  return (
    <IntlProvider
      locale={localePriorities[localePos]}
      messages={localizedPriorities[localePos]}
      defaultLocale={DEFAULT_LANGUAGE}
    >
      <Switch>
        {routes.map((route, index) => (
          <RouteFromPath key={`route${index}`} {...route} authenticated={authenticated} />
        ))}
      </Switch>
    </IntlProvider>
  );
};

App.propTypes = {
  locale: string,
  location: object,
  authenticated: bool,
  getClaims: func.isRequired,
  error: bool,
  push: object,
  transactionToken: string,
  history: object,
  globalError: string,
};

const mapState = ({ order }) => ({
  locale: order.locale,
  authenticated: order.authenticated,
  transactionToken: order.transactionToken,
  error: order.error,
});

const mapDispatch = {
  getClaims,
};

export default connect(mapState, mapDispatch)(withRouter(App));
