import React from 'react';
import { string, bool } from 'prop-types';
// import { useIntl } from 'react-intl';

const BillingInformationDisplay = ({
  title,
  firstName,
  lastName,
  email,
  address,
  city,
  state,
  postalCode,
  country,
  organizationName,
  simplified,
}) => (
  <div className="billing-information-display">
    {simplified ? (
      <>
        <h4>
          {firstName} {lastName} {`(${email})`}
        </h4>
        <h4>{[address, city, state, postalCode, country].filter(elem => elem).join(', ')}</h4>
      </>
    ) : (
      <>
        {title && <h3>{title}</h3>}
        {email && (
          <>
            {organizationName && <h3>{organizationName}</h3>}
            <h3 className="billing-information-name">
              {firstName} {lastName}
            </h3>
            <h4>{email}</h4>
          </>
        )}
        <div>
          <h4>{address}</h4>
          <h4>{[city, state, postalCode].filter(elem => elem).join(', ')}</h4>
          <h4>{country}</h4>
          {/* {taxId && <h4 className="margin-top-half">{`${intl.formatMessage({ id: 'billing.taxId' })}: ${taxId}`}</h4>} */}
        </div>
      </>
    )}
  </div>
);

BillingInformationDisplay.propTypes = {
  title: string,
  firstName: string,
  lastName: string,
  email: string,
  address: string,
  city: string,
  state: string,
  postalCode: string,
  country: string,
  organizationName: string,
  simplified: bool,
};

export default BillingInformationDisplay;
