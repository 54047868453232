import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { string, bool } from 'prop-types';
import cn from 'classnames';
import logoImg from '../../assets/Xvoucher_logo_Horizontal_white.svg';
import '../../styles/components/footer.scss';

const Footer = ({ supportUrl, termsUrl, supplierTag, error, onlyLinks }) => {
  const intl = useIntl();

  return (
    <div className={cn('footer', { onlyLinks })}>
      {(supplierTag || error) && (
        <>
          <div className="footer-message-wrapper">
            <p className={cn('transparent', { dark: onlyLinks })}>
              <a href={supportUrl} className="footer-text" target="_blank" rel="noopener noreferrer">
                {intl.formatMessage({ id: 'global.support' })}
              </a>{' '}
              |{' '}
              <a href={termsUrl} className="footer-text" target="_blank" rel="noopener noreferrer">
                {intl.formatMessage({ id: 'global.terms' })}{' '}
              </a>{' '}
              | <span className="footer-text">© 2020 - {new Date().getFullYear()} Xvoucher, {intl.formatMessage({ id: 'global.allRightsReserved' })}.</span>
            </p>
            {!onlyLinks && (
              <p className="transparent">
                {`${intl.formatMessage({ id: 'global.poweredBy' })} `} <img src={logoImg} alt="" />
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

Footer.propTypes = {
  supportUrl: string,
  termsUrl: string,
  supplierTag: string,
  error: string,
  onlyLinks: bool,
};

const mapState = ({ order }) => ({
  supportUrl: order.supportUrl,
  termsUrl: order.termsUrl,
  supplierTag: order.supplierTag,
  error: order.globalError,
});

export default connect(mapState)(Footer);
