export const buildReusableTranslationsByID = (ids, formatMessage) =>
  ids.map(id => ({ [id]: formatMessage({ id }) })).reduce((total, curr) => ({ ...total, ...curr }));

/* eslint-disable indent */
// eslint-disable-next-line no-confusing-arrow
export const cleanCurrencyNumber = (number, currency, formatNumberToParts) =>
  (number || number === 0) &&
  formatNumberToParts(number, { style: 'currency', currency })
    .map(({ type, value }) => {
      switch (type) {
        case 'currency':
        case 'literal':
          return '';
        default:
          return value;
      }
    })
    ?.reduce((string, part) => string + part, '');
