import React from 'react';
import ContentLoader from 'react-content-loader';

const OrderSummaryLoader = () => (
  <ContentLoader height={230} width="100%" backgroundColor="#eaeaea" foregroundColor="#f4f4f4">
    <rect x="0" y="0" rx="3" ry="3" width="200" height="15" />
    <rect x="5%" y="40" rx="3" ry="3" width="40%" height="12" />
    <rect x="75%" y="40" rx="3" ry="3" width="20%" height="12" />
    <rect x="5%" y="70" rx="3" ry="3" width="40%" height="12" />
    <rect x="75%" y="70" rx="3" ry="3" width="20%" height="12" />
    <rect x="0" y="90" rx="3" ry="3" width="100%" height="3" />
    <rect x="5%" y="120" rx="3" ry="3" width="40%" height="12" />
    <rect x="75%" y="120" rx="3" ry="3" width="20%" height="12" />
    <rect x="0" y="150" rx="3" ry="3" width="100%" height="20" />
    <rect x="0" y="190" rx="3" ry="3" width="100%" height="20" />
  </ContentLoader>
);

export default OrderSummaryLoader;
