import generateActions from './utils/generateActions';

export const DENY_ORDER_WITHOUT_TOKEN = 'DENY_ORDER_WITHOUT_TOKEN';
export const ENABLE_ACTIONS = 'ENABLE_ACTIONS';
export const RESTART_REDUCER = 'RESTART_REDUCER';
export const SET_SELECTED_CARD_AND_LABEL = 'SET_SELECTED_CARD_AND_LABEL';
export const GET_CLAIMS = generateActions('GET_CLAIMS');
export const GET_ORDER = generateActions('GET_ORDER');
export const GET_FULL_ORDER = generateActions('GET_FULL_ORDER');
export const UPDATE_ORDER = generateActions('UPDATE_ORDER');
export const GET_AUTH_TOKEN = generateActions('GET_AUTH_TOKEN');
export const CONFIRM_TRANSACTION = generateActions('CONFIRM_TRANSACTION');
export const GET_COUNTRIES = generateActions('GET_COUNTRIES');
export const GET_INVOICE = generateActions('GET_INVOICE');
export const SEND_INVOICE = generateActions('SEND_INVOICE');
export const GET_REDIRECT = generateActions('GET_REDIRECT');
export const SET_ORDER_ERROR_MESSAGE = 'SET_ORDER_ERROR_MESSAGE';
export const DELETE_ORDER_ERROR_MESSAGE = 'DELETE_ORDER_ERROR_MESSAGE';
export const SET_BILLING_ERROR_MESSAGE = 'SET_BILLING_ERROR_MESSAGE';
export const DELETE_BILLING_ERROR_MESSAGE = 'DELETE_BILLING_ERROR_MESSAGE';
export const SET_PAYMENT_ERROR_MESSAGE = 'SET_PAYMENT_ERROR_MESSAGE';
export const DELETE_PAYMENT_ERROR_MESSAGE = 'DELETE_PAYMENT_ERROR_MESSAGE';
export const UPDATE_ADDRESS_REQUIREMENTS_LIST = 'UPDATE_ADDRESS_REQUIREMENTS_LIST';
export const PUMP_UP_EXPIRATION_TIMERS = 'PUMP_UP_EXPIRATION_TIMERS';
