import React, { Fragment } from 'react';
import ContentLoader from 'react-content-loader';

const OrderContentLoader = () => {
  const [lineCount, lineSeparation, lineHeight] = [4, 32, 13];

  return (
    <ContentLoader height={210} width="100%" backgroundColor="#eaeaea" foregroundColor="#f4f4f4">
      <rect x="0" y="0" rx="3" ry="3" width="200" height="16" />
      <rect x="0" y="32" rx="4" ry="3" width="250" height="16" />
      <rect x="90%" y="32" rx="3" ry="3" width="10%" height="16" />
      {[...Array(lineCount)].map((_, index) => (
        <Fragment key={index}>
          <rect x="0" y={32 + (index + 1) * lineSeparation} rx="3" ry="3" width="50" height={lineHeight} />
          <rect x="60" y={32 + (index + 1) * lineSeparation} rx="3" ry="3" width="50" height={lineHeight} />
        </Fragment>
      ))}
      <rect x="80%" y="185" rx="3" ry="3" width="20%" height="18" />
    </ContentLoader>
  );
};

export default OrderContentLoader;
