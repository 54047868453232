import React, { useMemo } from 'react';
import { string, func, bool } from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import CheckoutForm from './CheckoutForm';

const StripeContainer = ({
  locale = 'en-US',
  handleStripeChange,
  paymentFunction,
  isButtonDisabled,
  isButtonLoading,
  buttonMessage,
  stripePublicKey,
  shouldConvertCurrency,
  buttonMessageConvertedCurrency,
}) => {
  const stripePromise = useMemo(() => loadStripe(stripePublicKey), [stripePublicKey]);

  const stripeOptions = {
    locale,
  };

  return (
    <Elements options={stripeOptions} stripe={stripePromise}>
      <CheckoutForm
        handleStripeChange={handleStripeChange}
        paymentFunction={paymentFunction}
        isButtonDisabled={isButtonDisabled}
        isButtonLoading={isButtonLoading}
        buttonMessage={buttonMessage}
        shouldConvertCurrency={shouldConvertCurrency}
        buttonMessageConvertedCurrency={buttonMessageConvertedCurrency}
      />
    </Elements>
  );
};

StripeContainer.propTypes = {
  locale: string,
  handleStripeChange: func.isRequired,
  paymentFunction: func,
  isButtonDisabled: bool,
  isButtonLoading: bool,
  buttonMessage: string,
  stripePublicKey: string,
  shouldConvertCurrency: bool,
  buttonMessageConvertedCurrency: string,
};

export default StripeContainer;
