import React from 'react';
import { bool, string, oneOfType, node, element } from 'prop-types';
import '../../styles/components/common/empty-wrapper.scss';

const EmptyWrapper = ({ empty, children, message = 'There is no data to display' }) =>
  (empty ? (
    <div className="empty-wrapper-container">
      <h3>{message}</h3>
    </div>
  ) : (
    children
  ));

EmptyWrapper.propTypes = {
  empty: bool,
  message: string,
  children: oneOfType([node, element]).isRequired,
};

export default EmptyWrapper;
