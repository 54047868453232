const addressValidationFlags = [
  { key: 'None', value: 0 },
  { key: 'Street', value: 1 },
  { key: 'City', value: 2 },
  { key: 'State', value: 4 },
  { key: 'Country', value: 8 },
  { key: 'PostalCode', value: 16 },
  { key: 'Adressee', value: 32 },
  { key: 'StreetNumber', value: 64 },
  { key: 'StreetName', value: 128 },
];

export default addressValidationFlags;
