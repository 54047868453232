import React from 'react';
import { string, number } from 'prop-types';
import Loader from 'react-loaders';

import primaryColorBlue from '../../styles/_constants.scss';

const Loading = ({ color = primaryColorBlue, className = '', type = 'ball-scale-multiple', scale = 1.0 }) => (
  <div className={`loading-wrapper ${className}`}>
    <Loader type={type} color={color} style={{ transform: `scale(${scale})` }} active />
  </div>
);

Loading.propTypes = {
  color: string,
  className: string,
  type: string,
  scale: number,
};

export default Loading;
