import React from 'react';
import { string } from 'prop-types';

const FormattedHTMLMessage = ({ message }) => <p dangerouslySetInnerHTML={{ __html: message }} />;

FormattedHTMLMessage.propTypes = {
  message: string,
};

export default FormattedHTMLMessage;
