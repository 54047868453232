import React from 'react';
import { connect } from 'react-redux';
import { bool, number, string, func } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import Grow from '@material-ui/core/Grow';

import OrderSummaryLoader from './OrderSummaryLoader';
import EmptyWrapper from '../common/EmptyWrapper';
import { cleanCurrencyNumber } from '../../helpers/localeHelpers';
import FormDropdown from '../common/inputs/FormDropdown';

import { setSelectedCardAndLabel } from '../../redux/actions/ordersActions';

import '../../styles/components/order/order-summary.scss';

const OrderSummary = ({
  loading,
  editMode,
  subtotal = 0,
  totalTax = 0,
  totalDiscount = 0,
  totalFee = 0,
  currency,
  empty,
  payEnabled,
  paymentFunction,
  useStripe,
  currencyConversion,
  setSelectedCardAndLabel,
  selectedCard,
  payMessage,
}) => {
  const intl = useIntl();

  const { baseCurrency, convertedCurrency, convertedOrderTotal: convertedAmount } = currencyConversion || {};

  const cardOptions = [
    { label: 'Visa', value: 'Visa', secondLabel: baseCurrency },
    { label: 'MasterCard', value: 'MasterCard', secondLabel: baseCurrency },
    { label: 'American Express', value: 'AmEx', secondLabel: 'USD' },
    { label: 'Discover', value: 'Discover', secondLabel: 'USD' },
    { label: 'JCB', value: 'JCB', secondLabel: 'USD' },
    { label: 'UnionPay', value: 'UnionPay', secondLabel: 'USD' },
  ];

  // eslint-disable-next-line no-confusing-arrow
  /* eslint-disable indent */

  const handleSelectedCardChange = ({ target: { value: selectedCard } }, { secondLabel } = {}) => {
    setSelectedCardAndLabel(selectedCard, secondLabel);
  };

  const isCurrencyBeingConverted =
    convertedCurrency && cardOptions.find(({ value }) => value === selectedCard)?.secondLabel === convertedCurrency;

  return (
    <div className="order-summary-container">
      {loading ? (
        <OrderSummaryLoader />
      ) : (
        <>
          <h3 className="bold">
            <FormattedMessage id="orderSummary.title" />
          </h3>
          <EmptyWrapper empty={empty} message={intl.formatMessage({ id: 'orderSummary.noOrder' })}>
            <div className="order-summary-subtotals-container">
              <div className="summary-grid">
                {totalDiscount > 0 && (
                  <>
                    <h4 className="summary-grid-item">
                      <FormattedMessage id="orderSummary.productSubTotal" />:
                    </h4>
                    <h4 className="summary-grid-item" id="ProductSubTotal">
                      {`${cleanCurrencyNumber(
                        subtotal + totalDiscount,
                        currency,
                        intl.formatNumberToParts
                      )} ${currency}`}
                    </h4>
                  </>
                )}
                {totalDiscount > 0 && (
                  <>
                    <h4 className="summary-grid-item">
                      <FormattedMessage id="orderSummary.discount" />:
                    </h4>
                    <h4 id="Discount" className="summary-grid-item">
                      {`(${cleanCurrencyNumber(totalDiscount, currency, intl.formatNumberToParts)}) ${currency}`}
                    </h4>
                  </>
                )}
                <h4 className="summary-grid-item">
                  <FormattedMessage id="orderSummary.productTotal" />:
                </h4>
                <h4 className="summary-grid-item" id="ProductTotal">
                  {`${cleanCurrencyNumber(subtotal, currency, intl.formatNumberToParts)} ${currency}`}
                </h4>
                <h4 className="summary-grid-item">
                  <FormattedMessage id="orderSummary.estimatedTax" />:
                </h4>
                <h4 className="summary-grid-item" id="EstimatedTax">
                  {editMode ? '--' : cleanCurrencyNumber(totalTax, currency, intl.formatNumberToParts)}
                  {` ${currency}`}
                </h4>
                {totalFee > 0 && (
                  <>
                    <h4 className="summary-grid-item">
                      <FormattedMessage id="orderSummary.fee" />:
                    </h4>
                    <h4 className="summary-grid-item" id="Fee">
                      {`${cleanCurrencyNumber(totalFee, currency, intl.formatNumberToParts)} ${currency}`}
                    </h4>
                  </>
                )}
                <h4 className="order-summary-total summary-grid-item">
                  <FormattedMessage id="orderSummary.total" />:
                </h4>
                <h4 id="Total" className="order-summary-total summary-grid-item">
                  {`${cleanCurrencyNumber(
                    subtotal + totalTax + totalFee,
                    currency,
                    intl.formatNumberToParts
                  )} ${currency}`}
                </h4>
              </div>
              {!useStripe && isCurrencyBeingConverted && (
                <>
                  <div className="order-summary-subtotal-container highlighted">
                    <h4>
                      <FormattedMessage id="orderSummary.chargedAmountLabel" />:
                    </h4>
                    <Tooltip
                      title={intl.formatMessage({ id: 'orderSummary.currencyChangeExplanation' }, { 0: baseCurrency })}
                      placement="bottom-end"
                      arrow
                      TransitionComponent={Grow}
                      TransitionProps={{ timeout: 300 }}
                    >
                      <h4 id="ConvertedProductTotal">
                        {`${cleanCurrencyNumber(
                          convertedAmount,
                          convertedCurrency,
                          intl.formatNumberToParts
                        )} ${convertedCurrency}`}
                      </h4>
                    </Tooltip>
                  </div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="xvoucher-decoration"
                    href={process.env.REACT_APP_PAYMENT_CURRENCIES_SUPPORT_LINK || 'https://help.xvoucher.com/'}
                  >
                    <h5 className="description">
                      {`${intl.formatMessage({
                        id: 'orderSummary.conversionReason',
                      })}?`}
                    </h5>
                  </a>
                </>
              )}
            </div>
            {!useStripe && convertedCurrency && (
              <FormDropdown
                elementId="SelectedCard"
                fullWidth
                name="selectedCard"
                value={selectedCard || ''}
                label={intl.formatMessage({ id: 'orderSummary.cardTypeSelectionLabel' })}
                margin="margin-top"
                dropdownOptions={cardOptions}
                onChange={handleSelectedCardChange}
                disabled={!payEnabled}
              />
            )}
            {!useStripe && (
              <>
                <hr />
                <button
                  id="PaymentButton"
                  className="primary-button full-size margin-top success"
                  onClick={() => paymentFunction(isCurrencyBeingConverted)}
                  disabled={!payEnabled || (convertedCurrency && !selectedCard)}
                >
                  {`${intl.formatMessage({ id: 'orderSummary.pay' })} ${payMessage}`}
                </button>
              </>
            )}
          </EmptyWrapper>
        </>
      )}
    </div>
  );
};

OrderSummary.propTypes = {
  loading: bool,
  editMode: bool,
  totalTax: number,
  totalDiscount: number,
  totalFee: number,
  subtotal: number,
  currency: string.isRequired,
  empty: bool,
  payEnabled: bool,
  paymentFunction: func.isRequired,
  useStripe: bool,
  currencyConversion: bool,
  setSelectedCardAndLabel: func.isRequired,
  selectedCard: string,
  payMessage: string,
};

const mapDispatch = {
  setSelectedCardAndLabel,
};

export default connect(undefined, mapDispatch)(OrderSummary);
