import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { bool, string, object } from 'prop-types';
import '../../../styles/components/common/inputs/slide-button.scss';
import Loading from '../Loading';

const SlideButton = ({ leftText, rightText, showLeft, className, loading, ...buttonProps }) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setShouldAnimate(true);
  }, [showLeft]);

  return (
    <button {...buttonProps} className={className}>
      {loading ? (
        <Loading color="white" type="ball-pulse" scale={0.75} />
      ) : (
        <div className={cn('slider', showLeft ? 'left' : 'right', { animated: shouldAnimate })}>
          <div className={cn('content-wrapper')}>{leftText}</div>
          <div className={cn('content-wrapper')}>{rightText}</div>
        </div>
      )}
    </button>
  );
};

SlideButton.propTypes = {
  leftText: string,
  rightText: string.isRequired,
  showLeft: bool,
  className: string,
  loading: bool,
  buttonProps: object,
};

export default SlideButton;
